import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useGlobalContext } from "./context";
import {
  Avatar,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  FormControl,
  Checkbox,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import CircleNotificationsRoundedIcon from "@mui/icons-material/CircleNotificationsRounded";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import Alert from "@mui/material/Alert";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import LogoutIcon from "@mui/icons-material/Logout";
import InventoryIcon from "@mui/icons-material/Inventory";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import PaymentsIcon from "@mui/icons-material/Payments";
import BarChartIcon from "@mui/icons-material/BarChart";
import ApartmentIcon from "@mui/icons-material/Apartment";
import InboxIcon from "@mui/icons-material/Inbox";
import LoginIcon from "@mui/icons-material/Login";
import FlagIcon from "@mui/icons-material/Flag";
import sync from "./static/Capture.png";
import "./css/infodashboard.css";
import axios from "./axiosBase";
import "./axiosInterceptors";
import { clearData, getRefreshToken } from "./handleToken";
import { CircularProgress } from "@mui/material";

function BusinessCentral() {
  // const { authenticate } = useGlobalContext();

  // const history = useHistory();
  // if (authenticate === false) {
  //   history("/login");
  // }
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [loadingDetails, setLoadingDetails] = useState("Fetching Data");
  const [totalPurchases, setTotalPurchases] = useState(0);
  const [totalSale, setTotalSale] = useState(0);
  const [totalPurchasesFy, setTotalPurchasesFy] = useState(0);
  const [totalSaleFy, setTotalSaleFy] = useState(0);
  const [payableInfo, setPayableInfo] = useState([]);
  const [payableTotal, setPayableTotal] = useState(0);
  const [payable90, setPayable90] = useState(0);
  const [payable90_180, setPayable90_180] = useState(0);
  const [payable180, setPayable180] = useState(0);
  const [recievableTotal, setRecievableTotal] = useState(0);
  const [recievable90, setRecievable90] = useState(0);
  const [recievable90_180, setRecievable90_180] = useState(0);
  const [recievable180, setRecievable180] = useState(0);
  const [organization, setOrganization] = useState([]);
  const [organization_id, setorganization_id] = useState();
  const [items, setItems] = useState([]);
  const [inventory, setInventory] = useState([]);
  const [inventory90, setInventory90] = useState(0);
  const [inventory90_180, setInventory90_180] = useState(0);
  const [payExtraInfo, setpayExtraInfo] = useState([]);
  const [inventory180, setInventory180] = useState(0);
  const [invExtraInfo, setInvExtraInfo] = useState([]);
  const [displayinventory, setDisplayinventory] = useState(false);
  const [displayRecievable, setDisplayRecievable] = useState(false);
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isShare, setIsShare] = useState(false);
  const [pan_no, setPan_no] = useState();
  const [isready, setIsready] = useState({
    val1: false,
    val2: false,
    val3: false,
    val4: false,
    val5: false,
    val6: false,
    val7: false,
  });
  const [isErrorDetails, setisErrorDetails] = useState(
    "An error occured while loading data, Reload page to update"
  );
  const history = useNavigate();
  const { setAuthenticate, authenticate, isAdmin } = useGlobalContext();
  if (authenticate === false) {
    history("/login");
    // history.location.search()
  }
  if (isAdmin === true) {
    history("/admin/dashboard");
  }
  const date = new Date();
  const monthArray = [];
  for (let i = 0; i < date.getMonth(); i++) {
    monthArray.push(i);
  }
  const [details, setDetails] = useState({
    month: "",
    year: "",
    organization: "",
  });
  const getInfo = () => {
    setLoading(true);
    setLoadingDetails("Uploading data");
    axios
      .post("post-info", {
        date: new Date(),
        panNo: pan_no,
        totalPurchases: totalPurchases,
        totalSale: totalSale,
        totalPurchasesFy: totalPurchasesFy,
        totalSalesFy: totalSaleFy,
        payables: {
          payableTotal: payableTotal,
          payable180: payable180,
          payable90: payable90,
          payable90_180: payable90_180,
        },
        inventory: {
          inventoryTotal: inventory90 + inventory180 + inventory90_180,
          inventory180: inventory180,
          inventory90: inventory90,
          inventory90_180: inventory90_180,
        },
        recievables: {
          recievableTotal: recievableTotal,
          recievable180: recievable180,
          recievable90: recievable90,
          recievable90_180: recievable90_180,
        },
        payableInfo: payExtraInfo,
        recievableinfo: invExtraInfo,
        month: details.month,
        year: details.year,
        day: date.getDate(),
      })
      .then((response) => response.data)
      .then((data) => {
        // console.log(data);
        if (data.success) {
          setLoading(false);
          alert("Data upload Successful");
          history("/dashboard");
        } else {
          setIsError(true);
          setisErrorDetails("Data for this month has been submitted");
        }
      })
      .catch((err) => {
        console.log(err);
        setIsError(true);
        setisErrorDetails("Error in uploading data");
      });
  };
  const logout = (e) => {
    e.preventDefault();
    const token = getRefreshToken();
    axios.post("api/token/blacklist/", { refresh: token }).then((response) => {
      clearData();
      setAuthenticate(false);
      history("/login");
    });
  };
  const getOrganization = (cb) => {
    axios
      .get("microsoft/companies")
      .then((response) => response.data)
      .then((data) => {
        console.log(data.data.value);
        setOrganization(data.data.value);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.message === "Network Error") {
          setLoading(false);
          setIsError(true);
          setisErrorDetails("Internet Connection error when loading data");
        } else {
          setIsError(true);
          setisErrorDetails(
            "An error occured while loading Organization data, Reload page to update"
          );
        }
      });
    axios
      .get("user-data")
      .then((response) => response.data)
      .then((data) => {
        // console.log(data);
        console.log(data.data.pan_no);
        setPan_no(data.data.pan_no);
      })
      .catch((err) => console.log(err));
  };
  const getData = () => {
    setLoading(true);
    axios
      .get("microsoft/payables", {
        params: {
          organization_id: organization_id,
          year: details.year,
          month: details.month,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        console.log(data.data.value);
        const details_array = [];
        let total_90 = 0;
        let total_90_180 = 0;
        let total_180 = 0;
        let id = 0;
        for (let i = 1; i < data.data.value.length; i++) {
          total_90 = total_90 + data.data.value[i]["period1Amount"] * -1;
          total_90_180 =
            total_90_180 + data.data.value[i]["period2Amount"] * -1;
          total_180 = total_180 + data.data.value[i]["period3Amount"];
          const details_object_90 = {
            id: id++,
            name: data.data.value[i]["name"],
            value: data.data.value[i]["period1Amount"] * -1,
            age: "within 90 days",
          };
          const details_object_90_180 = {
            id: id++,
            name: data.data.value[i]["name"],
            value: data.data.value[i]["period2Amount"] * -1,
            age: "between 90 and 180 days",
          };
          const details_object_180 = {
            id: id++,
            name: data.data.value[i]["name"],
            value: data.data.value[i]["period3Amount"] * -1,
            age: "above 180 days",
          };
          details_array.push(details_object_90);
          details_array.push(details_object_90_180);
          details_array.push(details_object_180);
          setpayExtraInfo(details_array);
        }
        console.log(total_90, total_180, total_90_180)
        setPayableTotal(total_90 + total_180 + total_90_180);
        setPayable90(total_90);
        setPayable90_180(total_90_180);
        setPayable180(total_180);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get("microsoft/receivables", {
        params: {
          organization_id: organization_id,
          year: details.year,
          month: details.month,
        },
      })
      .then((result) => result.data)
      .then((data) => {
        console.log(data.data.value);
        const details_array = [];
        let total = 0;
        let total_90 = 0;
        let total_90_180 = 0;
        let total_180 = 0;
        let id = 0;
        for (let i = 1; i < data.data.value.length; i++) {
          total = total + data.data.value[i]["currentAmount"];
          total_90 = total_90 + data.data.value[i]["period1Amount"] * -1;
          total_90_180 =
            total_90_180 + data.data.value[i]["period2Amount"] * -1;
          total_180 = total_180 + data.data.value[i]["period3Amount"] * -1;
          const details_object_90 = {
            id: id++,
            name: data.data.value[i]["name"],
            value: data.data.value[i]["period1Amount"] * -1,
            age: "within 90 days",
          };
          const details_object_90_180 = {
            id: id++,
            name: data.data.value[i]["name"],
            value: data.data.value[i]["period2Amount"] * -1,
            age: "between 90 and 180 days",
          };
          const details_object_180 = {
            id: id++,
            name: data.data.value[i]["name"],
            value: data.data.value[i]["period3Amount"] * -1,
            age: "above 180 days",
          };
          details_array.push(details_object_90);
          details_array.push(details_object_90_180);
          details_array.push(details_object_180);
          setInvExtraInfo(details_array);
        }
        setRecievableTotal(total_90 + total_180 + total_90_180);
        setRecievable90(total_90);
        setRecievable90_180(total_90_180);
        setRecievable180(total_180);
      })
      .catch((err) => {
        console.log(err);
        // setIsError(true);
      });

    axios
      .get("microsoft/monthsales", {
        params: {
          organization_id: organization_id,
          year: details.year,
          prevMonth:
            parseInt(details.month) === 1 ? 12 : parseInt(details.month) - 1,
          nextMonth:
            parseInt(details.month) === 12 ? 1 : parseInt(details.month) + 1,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        let sum = 0;
        for (let i = 0; i < data.data.value.length; i++) {
          sum = sum + data.data.value[i]["totalAmountExcludingTax"];
        }
        console.log(sum);
        setTotalSale(sum);
      })
      .catch((err) => {
        console.log(err);
        // setIsError(true);
      });
    axios
      .get("microsoft/monthpurchases", {
        params: {
          organization_id: organization_id,
          year: details.year,
          prevMonth:
            parseInt(details.month) === 1 ? 12 : parseInt(details.month) - 1,
          nextMonth:
            parseInt(details.month) === 12 ? 1 : parseInt(details.month) + 1,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        let sum = 0;
        for (let i = 0; i < data.data.value.length; i++) {
          sum = sum + data.data.value[i]["totalAmountExcludingTax"];
        }
        console.log(sum);
        setTotalPurchases(sum);
      })
      .catch((err) => {
        console.log(err);
        // setIsError(true);
      });

    axios
      .get("microsoft/yearsales", {
        params: {
          organization_id: organization_id,
          year:
            parseInt(details.month) < 4
              ? parseInt(details.year) - 1
              : details.year,
          month: details.month,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        let sum = 0;
        for (let i = 0; i < data.data.value.length; i++) {
          sum = sum + data.data.value[i]["totalAmountExcludingTax"];
        }
        console.log(sum);
        setTotalSaleFy(sum);
      })
      .catch((err) => {
        console.log(err);
        setIsError(true);
      });
    axios
      .get("microsoft/yearpurchases", {
        params: {
          organization_id: organization_id,
          year:
            parseInt(details.month) < 4
              ? parseInt(details.year) - 1
              : details.year,
          month: details.month,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        let sum = 0;
        for (let i = 0; i < data.data.value.length; i++) {
          sum = sum + data.data.value[i]["totalAmountExcludingTax"];
        }
        console.log(sum);
        setTotalPurchasesFy(sum);
      })
      .catch((err) => {
        console.log(err);
        // setIsError(true);
      });
  };
  // if (
  //   isready.val1 &&
  //   isready.val2 &&
  //   isready.val3 &&
  //   isready.val4 &&
  //   isready.val5 &&
  //   isready.val6 &&
  //   isready.val7
  // ) {
  //   setLoading(false);
  // }
  useEffect(() => {
    getOrganization();
  }, []);

  useEffect(() => {
    if (details.month && details.year && details.organization) {
      setPayable180(0);
      setPayable90(0);
      setInventory180(0);
      setPayable90_180(0);
      setInventory90(0);
      setInventory90_180(0);
      setRecievable180(0);
      setRecievable90(0);
      setRecievable90_180(0);
      setRecievableTotal(0);
      setTotalPurchases(0);
      setTotalPurchasesFy(0);
      setTotalSale(0);
      setTotalSaleFy(0);
      setInvExtraInfo([]);
      setpayExtraInfo([]);
      setPayableTotal(0);
      setRecievableTotal(0);
      setIsError(false);
      getData();
    }
    // eslint-disable-next-line
  }, [details]);
  const formValue = (event) => {
    if (event.target.name === "organization") {
      setorganization_id(event.target.value);
    }
    if (event.target.name === "year" && details.month) {
      if (
        !parseInt(details.month) < 4 &&
        parseInt(details.month) > monthArray.slice(-1)[0] + 1
      ) {
        setDetails((oldValue) => {
          return {
            ...oldValue,
            [event.target.name]: event.target.value,
            month: "",
          };
        });
      } else {
        setDetails((oldValue) => {
          return { ...oldValue, [event.target.name]: event.target.value };
        });
      }
    } else {
      setDetails((oldValue) => {
        return { ...oldValue, [event.target.name]: event.target.value };
      });
    }
  };
  const handleCheck = (event) => {
    // console.log(event.target.checked);
    setIsShare(event.target.checked);
  };
  console.log(details);
  return (
    <div className="share__dashboard">
      <div className="share__dashboard__sidenav">
        <div className="share__dashboard__sidenav__img">
          <img src={sync} alt="" />
        </div>
        <ul>
          <li className="active">
            <Link to="/dashboard">
              {/* eslint-disable-next-line */}
              <a href="#">
                <span className="icon">
                  <GridViewRoundedIcon />
                </span>
                Dashboard
              </a>
            </Link>
          </li>
          <li>
            <Link to="/my-organization">
              {/* eslint-disable-next-line */}
              <a href="#">
                <span className="icon">
                  <HomeRoundedIcon />
                </span>
                My Organization
              </a>
            </Link>
          </li>
          <li>
            <Link to="/settings">
              {/* eslint-disable-next-line */}
              <a href="#">
                <span className="icon">
                  <LoginIcon />
                </span>
                Login Settings
              </a>
            </Link>
          </li>
          <li>
            <Link to="/erp-systems">
              {/* eslint-disable-next-line */}
              <a href="#">
                <span className="icon">
                  <SettingsIcon />
                </span>
                Erp Settings
              </a>
            </Link>
          </li>
          <li>
            <a href="/logout" onClick={(e) => logout(e)}>
              <span className="icon">
                <LogoutIcon />
              </span>
              Logout
            </a>
          </li>
        </ul>
      </div>
      <div className="share__dashboard__topnav">
        <div className="share__dashboard__topnav__header">
          <h1>Dashboard</h1>
        </div>
        <div className="share__dashboard__topnav__item">
          <a href="/">
            <CircleNotificationsRoundedIcon className="notification_icon" />
          </a>
          <Avatar className="image__avatar" />
        </div>
      </div>
      <div className="share__dashboard__main__maindivision">
        <div className="share__dashboard__main__subdivision_fresh">
          <div className="share__dashboard__main__head">
            <div className="share__dashboard__main__head__div">
              <Grid container spacing={1}>
                <Grid item>
                  <FormLabel className="form-label">Year</FormLabel>
                  <FormControl fullWidth>
                    <Select
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={details.year}
                      name="year"
                      size="small"
                      onChange={formValue}
                      className="form__textfield__select"
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                    >
                      <MenuItem value={date.getFullYear()}>
                        {date.getFullYear()}
                      </MenuItem>
                      <MenuItem value={date.getFullYear() - 1}>
                        {date.getFullYear() - 1}
                      </MenuItem>
                      <MenuItem value={date.getFullYear() - 2}>
                        {date.getFullYear() - 2}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormLabel className="form-label">Month</FormLabel>
                  <FormControl fullWidth>
                    {!details.year && (
                      <Select
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        size="small"
                        name="month"
                        sx={{
                          "& fieldset": { border: "none" },
                        }}
                        className="form__textfield__select"
                      >
                        <MenuItem disabled>Select Year</MenuItem>
                      </Select>
                    )}
                    {details.year === date.getFullYear() && (
                      <Select
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={details.month}
                        size="small"
                        name="month"
                        onChange={formValue}
                        className="form__textfield__select"
                        sx={{
                          "& fieldset": { border: "none" },
                        }}
                      >
                        {monthArray.map((index) => {
                          return (
                            <MenuItem
                              key={index}
                              value={index < 9 ? `${0}${index + 1}` : index + 1}
                            >
                              {monthNames[index]}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    )}
                    {details.year && details.year < date.getFullYear() && (
                      <Select
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={details.month}
                        size="small"
                        name="month"
                        onChange={formValue}
                        className="form__textfield__select"
                        sx={{
                          "& fieldset": { border: "none" },
                        }}
                      >
                        <MenuItem value="01">January</MenuItem>
                        <MenuItem value="02">February</MenuItem>
                        <MenuItem value="03">March</MenuItem>
                        <MenuItem value="04">April</MenuItem>
                        <MenuItem value="05">May</MenuItem>
                        <MenuItem value="06">June</MenuItem>
                        <MenuItem value="07">July</MenuItem>
                        <MenuItem value="08">August</MenuItem>
                        <MenuItem value="09">September</MenuItem>
                        <MenuItem value="10">October</MenuItem>
                        <MenuItem value="11">November</MenuItem>
                        <MenuItem value="12">December</MenuItem>
                      </Select>
                    )}
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormLabel className="form-label">Organization</FormLabel>
                  <FormControl fullWidth>
                    <Select
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={details.organization}
                      name="organization"
                      size="small"
                      onChange={formValue}
                      className="form__textfield__select"
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                    >
                      {organization.map((org) => {
                        return (
                          <MenuItem key={org.id} value={org.id}>
                            {" "}
                            {org.name}{" "}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                {loading && (
                  <>
                    <div className="progress">
                      <CircularProgress size={20} /> {loadingDetails}
                    </div>
                  </>
                )}
              </Grid>
            </div>
            <div>
              <p>(Value in INR)</p>
            </div>
          </div>
          {isError && (
            <div className="alert">
              <Alert variant="filled" severity="error">
                {isErrorDetails}
              </Alert>
            </div>
          )}
          <div className="share__table">
            <table>
              <tr>
                <th>Particulars</th>
                <th>Total</th>
                <th>0-90 Days</th>
                <th>90-180 Days</th>
                <th>Greater than 180</th>
              </tr>
              <tr>
                <td className="share__table__data">
                  <InventoryIcon className="inventory__color" />
                  <span className="table__data__text__special">Inventory</span>
                </td>
                <td>
                  {parseInt(
                    inventory90 + inventory90_180 + inventory180
                  )?.toLocaleString("hi")}
                </td>
                <td>{parseInt(inventory90)?.toLocaleString("hi")}</td>
                <td>{parseInt(inventory90_180)?.toLocaleString("hi")}</td>
                <td>{parseInt(inventory180).toLocaleString("hi")}</td>
              </tr>
              <tr>
                <td className="share__table__data">
                  <PaymentsIcon className="payable__color" />
                  <span className="share__table__data__text">Payable</span>
                  <AddCircleOutlineIcon
                    onClick={() => {
                      setDisplayinventory(!displayinventory);
                    }}
                    className="payable__add__icon"
                  />
                </td>
                <td>{parseInt(payableTotal).toLocaleString("hi")}</td>
                <td>{parseInt(payable90).toLocaleString("hi")}</td>
                <td>{parseInt(payable90_180).toLocaleString("hi")}</td>
                <td>{parseInt(payable180).toLocaleString("hi")}</td>
              </tr>
              {displayinventory && (
                <>
                  <tr>
                    <th>Name</th>
                    <th>Age</th>
                    <th>Value</th>
                    <th></th>
                    <th></th>
                  </tr>
                  {payExtraInfo?.map((data) => {
                    return (
                      <tr key={data.id}>
                        <td>{data.name}</td>
                        <td>{data.age ? data.age : "Not Available"}</td>
                        <td>{parseInt(data.value).toLocaleString("hi")}</td>
                        <td></td>
                        <td></td>
                      </tr>
                    );
                  })}
                </>
              )}
              <tr>
                <td className="share__table__data">
                  <InboxIcon className="recievable__color" />
                  <span className="share__table__data__text">Recievable</span>
                  <AddCircleOutlineIcon
                    onClick={() => setDisplayRecievable(!displayRecievable)}
                  />
                </td>
                <td>{parseInt(recievableTotal)?.toLocaleString("hi")}</td>
                <td>{parseInt(recievable90)?.toLocaleString("hi")}</td>
                <td>{parseInt(recievable90_180)?.toLocaleString("hi")}</td>
                <td>{parseInt(recievable180)?.toLocaleString("hi")}</td>
              </tr>
              {displayRecievable && (
                <>
                  <tr>
                    <th>Name</th>
                    <th>Age</th>
                    <th>Value</th>
                    <th></th>
                    <th></th>
                  </tr>
                  {invExtraInfo?.map((data) => {
                    return (
                      <tr key={data.id}>
                        <td>{data.name}</td>
                        <td>{data.age}</td>
                        <td>{parseInt(data.value).toLocaleString("hi")}</td>
                        <td></td>
                        <td></td>
                      </tr>
                    );
                  })}
                </>
              )}
            </table>
            {/* <CircularProgress /> */}
            <div className="share__table__2">
              <table>
                <tr>
                  <th></th>
                  <th>Current Month</th>
                  <th>Current FY</th>
                </tr>
                <tr>
                  <td className="share__table__data">
                    <BarChartIcon className="sales__color" />
                    <span className="table__data__text__special">Sales</span>
                  </td>
                  <td>{parseInt(totalSale)?.toLocaleString("hi")}</td>
                  <td>{parseInt(totalSaleFy)?.toLocaleString("hi")}</td>
                </tr>
                <tr>
                  <td className="share__table__data">
                    <ApartmentIcon className="purchase__color" />
                    <span className="share__table__data__text">Purchases</span>
                  </td>
                  <td>{parseInt(totalPurchases)?.toLocaleString("hi")}</td>
                  <td>{parseInt(totalPurchasesFy)?.toLocaleString("hi")}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div className="declaration__div__info_fresh">
          <p>
            <FlagIcon /> Declaration
          </p>
          <div className="declaration__div__info__form">
            <Checkbox
              onClick={handleCheck}
              className="declaration__div__form__checkbox"
            />
            <p>
              We Certify that the Data represented in the report is correct and
              is in accordance with the entries in our records.
            </p>
          </div>
          <div className="declaration__div__form__button">
            <button disabled={!isShare} onClick={getInfo}>
              Share
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BusinessCentral;
