import React, { useState } from "react";
import "./css/SyncLogin.css";
import Capture from "./static/Capture.png";
import {
  Button,
  CircularProgress,
  FormLabel,
  Grid,
  TextField,
} from "@mui/material";
import { useParams } from "react-router";
import Alert from "@mui/material/Alert";
import axios from "./axiosBase";
import "./axiosInterceptors";
import "./css/login.css";
import "./css/reset-password.css";
import { Link } from "react-router-dom";

function ChangePassword() {
  const [details, setdetails] = useState({
    password1: "",
    password2: "",
  });
  const [isSuccess, setIsSuccess] = useState(false);
  const [appLoading, setappLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isErrorDetails, setisErrorDetails] = useState("");
  const { uid, token } = useParams();
  console.log(token, uid);
  const handleForm = (e) => {
    e.preventDefault();
    if (!details.password1 && !details.password2) {
      setIsSuccess(false);
      setIsError(true);
      setisErrorDetails("Password cannot be empty");
    }
    if (
      details.password1 &&
      details.password2 &&
      details.password1 !== details.password2
    ) {
      setIsSuccess(false);
      setIsError(true);
      setisErrorDetails("Password must match");
    } else {
      axios
        .post("change-password-auth", {
          uid: uid,
          token: token,
          password: details.password1,
        })
        .then((result) => result.data)
        .then((data) => {
          console.log(data);
          if (data.message === "success") {
            setIsError(false);
            setIsSuccess(true);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsSuccess(false);
          setIsError(true);
          setisErrorDetails("An error occured");
        });
    }
  };
  return (
    <div className="SyncBankerLogin_SyncBankerLogin_1">
      {/* <img className="Vector" src={ImgAsset.SyncBankerLogin_Vector} /> */}
      <div className="AddDocumentPopup1">
        <div className="cardheader"></div>
        <div className="Frame420"></div>
        <span className="Login-Reset">Reset Password</span>
        {isSuccess && (
          <div className="alert-login">
            <Alert
              variant="filled"
              severity="success"
              className="alert-message"
            >
              Password Change Successful
            </Alert>
          </div>
        )}
        {isError && (
          <div className="alert-login">
            <Alert variant="filled" severity="error" className="alert-message">
              {isErrorDetails}
            </Alert>
          </div>
        )}
        <form action="" className="form">
          <Grid container item spacing={2}>
            <Grid item sm={12}>
              <FormLabel className="form-label">Password</FormLabel>
              <TextField
                fullWidth
                id="oulined-basic"
                type="password"
                variant="outlined"
                placeholder="Enter Your Password"
                name="password"
                value={details.password1}
                onChange={(e) =>
                  setdetails((prevData) => {
                    return { ...prevData, password1: e.target.value };
                  })
                }
                sx={{
                  "& fieldset": { border: "none" },
                }}
                size="small"
                className="form__textfield"
              />
            </Grid>
            <Grid item sm={12}>
              <FormLabel className="form-label">Confirm Password</FormLabel>
              <TextField
                fullWidth
                id="oulined-basic"
                type="password"
                variant="outlined"
                placeholder="Enter Your Password"
                name="password"
                value={details.password2}
                onChange={(e) =>
                  setdetails((prevData) => {
                    return { ...prevData, password2: e.target.value };
                  })
                }
                sx={{
                  "& fieldset": { border: "none" },
                }}
                size="small"
                className="form__textfield"
              />
            </Grid>
            <Grid item xs={12}>
              {appLoading === false ? (
                <Button
                  className="button"
                  type="submit"
                  onClick={(e) => handleForm(e)}
                  fullWidth
                  variant="contained"
                >
                  Reset Password
                </Button>
              ) : (
                <Button
                  className="button"
                  type="submit"
                  onClick={(e) => handleForm(e)}
                  fullWidth
                  variant="contained"
                >
                  Reset Password <CircularProgress size={20} color="inherit" />
                </Button>
              )}
            </Grid>
            <div className="login_link">
              <Link to="/login" className="login_link_link">
                Login
              </Link>
            </div>
          </Grid>
        </form>
      </div>
      <div className="AddDocumentPopup_1_1">
        <img className="Capture1" src={Capture} alt="Capture" />
      </div>
    </div>
  );
}

export default ChangePassword;
