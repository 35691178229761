import React from "react";

const ConfirmMail = () => {
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
      <p>
        {" "}
        Your email has been successfully verifiedPlease click here to login
      </p>{" "}
      <a href="/login">Login</a>
    </div>
  );
};

export default ConfirmMail;
