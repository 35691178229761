import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useGlobalContext } from "./context";
import { Avatar, Alert } from "@mui/material";
import axios from "./axiosBase";
import "./axiosPrivate";
import Splash from "./Splash";
import SettingsIcon from "@mui/icons-material/Settings";
import DateRangeIcon from "@mui/icons-material/DateRange";
import CircleNotificationsRoundedIcon from "@mui/icons-material/CircleNotificationsRounded";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import AddIcon from "@mui/icons-material/Add";
import zoho from "./static/Zoho.png";
import TallyPrime from "./static/TallyPrime.png";
import TallyErp from "./static/TallyERP.png";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import PeopleOutlinedOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import sync from "./static/Capture.png";
import "./css/dashboard.css";
import { clearData, getRefreshToken } from "./handleToken";
import FreshBooks from "./static/Freshbooks.png";
import Dynamics from "./static/dynamics.png";
import moment from "moment/moment";

function Dashboard() {
  const { authenticate, setAuthenticate, isAdmin, networkError } =
    useGlobalContext();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [isSubmitted, setIsSubmitted] = useState();
  const [erpName, setErpName] = useState();
  const [year, setYear] = useState();
  const [month, setMonth] = useState();
  const [loading, setLoading] = useState(true);
  const [isError, setisError] = useState(false);
  const [isErrorDetails, setIsErrorDetails] = useState("Error in Data");
  const styles = {
    backgroundColor: isSubmitted ? "green" : "red",
    border: isSubmitted ? "2px solid green" : "2px solid red",
  };
  console.log(authenticate);
  const date = new Date();

  console.log(month);
  console.log(year);
  const history = useNavigate();
  const logout = (e) => {
    e.preventDefault();
    const token = getRefreshToken();
    axios.post("api/token/blacklist/", { refresh: token }).then((response) => {
      clearData();
      setAuthenticate(false);
      history("/login");
    });
  };

  if (authenticate === false) {
    history("/login");
  }
  if (isAdmin === true) {
    clearData();
    history("/admin/login");
  }
  useEffect(() => {
    if (
      (erpName || erpName === null) &&
      (isSubmitted === true || isSubmitted === false)
    ) {
      setLoading(false);
    }
  }, [erpName, isSubmitted]);
  console.log(networkError, "network");
  useEffect(() => {
    if (networkError) {
      setLoading(false);
      setisError(true);
      setIsErrorDetails("Internet Connection Error");
    }
  }, [networkError]);
  const getData = () => {
    console.log(year, month, "from data");
    axios
      .get("stock-statement", {
        params: {
          year:
            date.getMonth() === 0 ? date.getFullYear() - 1 : date.getFullYear(),
          month: date.getMonth() === 0 ? 12 : date.getMonth(),
        },
      })
      .then((response) => {
        if (response) {
          return response.data;
        }
      })
      .then((data) => {
        console.log(data);
        if (data?.message === "sucess") {
          console.log(data.data.data.submitted_status);
          setIsSubmitted(data.data.data.submitted_status);
        } else {
          setIsSubmitted(false);
        }
      });
    axios
      .get("erp-settings", {
        params: {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        console.log(data);
        if (data?.message === "success") {
          setErpName(data.data.erp_name);
        } else {
          setErpName(null);
        }
      });
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);
  if (loading === true) {
    return <Splash />;
  }
  return (
    <div className="dashboard">
      <div className="dashboard__sidenav">
        <div className="dashboard__sidenav__img_main">
          <img src={sync} alt="sync" />
        </div>
        <ul>
          <li className="active-dash">
            {/* eslint-disable-next-line */}
            <a href="#">
              <span className="icon">
                <GridViewRoundedIcon />
              </span>
              Dashboard
            </a>
          </li>
          <li>
            <Link to="/my-organization">
              {/* eslint-disable-next-line */}
              <a href="#">
                <span className="icon">
                  <HomeRoundedIcon />
                </span>
                My Organization
              </a>
            </Link>
          </li>
          <li>
            <Link to="/settings">
              {/* eslint-disable-next-line */}
              <a href="#">
                <span className="icon">
                  <LoginIcon />
                </span>
                Login Settings
              </a>
            </Link>
          </li>
          <li>
            <Link to="/erp-systems">
              {/* eslint-disable-next-line */}
              <a href="#">
                <span className="icon">
                  <SettingsIcon />
                </span>
                Erp Settings
              </a>
            </Link>
          </li>
          <li>
            <a href="/logout" onClick={(e) => logout(e)}>
              <span className="icon">
                <LogoutIcon />
              </span>
              Logout
            </a>
          </li>
        </ul>
      </div>
      <div className="dashboard__topnav">
        <div className="dashboard__topnav__header">
          <h1>Dashboard</h1>
        </div>
        <div className="dashboard__topnav__item">
          <a href="/">
            <CircleNotificationsRoundedIcon className="notification_icon" />
          </a>
          <Avatar className="image__avatar" />
        </div>
      </div>
      <div className="dashboard__main">
        {/* <div className="new">This is a text</div> */}
        <div className="dashboard__main__div_main">
          <p>
            <span className="newicon">
              <PersonAddAltIcon />
            </span>
            ERP/Accounting System
          </p>
          {erpName === "zoho-books" && (
            <>
              <img
                src={zoho}
                alt="zoho books"
                height="90px"
                style={{ marginLeft: 90 }}
              />
            </>
          )}
          {erpName === "tally-prime" && (
            <>
              <img
                src={TallyPrime}
                alt="tally prime"
                height="40px"
                style={{ marginLeft: 70, marginTop: 20 }}
              />
            </>
          )}
          {erpName === "fresh-books" && (
            <>
              <img
                src={FreshBooks}
                alt="fresh-books"
                height="80px"
                style={{ marginLeft: 100, marginTop: 20 }}
              />
            </>
          )}
          {erpName === "microsoft-business" && (
            <>
              <img
                src={Dynamics}
                alt="dynamics"
                height="80px"
                style={{ marginLeft: 30, marginTop: 20 }}
              />
            </>
          )}
          {erpName === "tally-erp9" && (
            <>
              <img
                src={TallyErp}
                alt="tally erp"
                height="90px"
                style={{ marginLeft: 80 }}
              />
            </>
          )}

          {!erpName && (
            <>
              <div className="center">
                <p>{erpName ? "Connected" : "Not Connected"}</p>
              </div>
              <Link to="/erp-systems" className="a">
                <div className="link">
                  {/* eslint-disable-next-line */}
                  <a className="dashboard_link">Connect</a>
                  <span>
                    <ArrowRightAltIcon className="arrow" color="#30BFC3" />
                  </span>
                </div>
              </Link>
            </>
          )}
        </div>
        <div className="dashboard__main__div_main">
          <p>
            <span className="newicon">
              <DateRangeIcon />
            </span>
            Monthly Stock Statement
          </p>
          <div className="center">
            <p>
              {moment(date).format("MMMM")} {date.getFullYear()}
            </p>
          </div>
          <div className="alt-link">
            <a href="/" style={styles}>
              {isSubmitted ? "Submitted" : "Not Submitted"}
            </a>
          </div>
        </div>
        <div className="dashboard__main__div_main">
          <p className="action">
            <span className="newicon">
              <ElectricBoltIcon />
            </span>
            Quick Actions
          </p>
          <div
            className={
              erpName === "tally-prime" || erpName === "tally-erp9"
                ? "action__item__special"
                : "action__item_"
            }
          >
            {!erpName && (
              <Link to="/share" className="a">
                <div className="action__item__1_main">
                  <AddIcon /> Share Stock <br /> Statement
                </div>
              </Link>
            )}
            {erpName === "zoho-books" && (
              <Link to="/share" className="a">
                <div className="action__item__1_main">
                  <AddIcon /> Share Stock <br /> Statement
                </div>
              </Link>
            )}
            {erpName === "microsoft-business" && (
              <Link to="/microsoft-dashboard" className="a">
                <div className="action__item__1_main">
                  <AddIcon /> Share Stock <br /> Statement
                </div>
              </Link>
            )}
            {erpName === "fresh-books" && (
              <Link to="/freshbooks-dashboard" className="a">
                <div className="action__item__1_main">
                  <AddIcon /> Share Stock <br /> Statement
                </div>
              </Link>
            )}

            {erpName === "fresh-books" ? (
              <Link to="/past-freshbooks" className="a">
                <div className="action__item__2_main">
                  <PeopleOutlinedOutlinedIcon /> View Past <br /> Stock
                  Statement
                </div>
              </Link>
            ) : (
              <Link to="/past" className="a">
                <div className="action__item__2_main">
                  <PeopleOutlinedOutlinedIcon /> View Past <br /> Stock
                  Statement
                </div>
              </Link>
            )}
          </div>
        </div>
      </div>
      {isError && isErrorDetails === "Internet Connection Error" && (
        <div className="alert-dashboard">
          <Alert variant="filled" severity="error">
            {isErrorDetails}
          </Alert>
        </div>
      )}
    </div>
  );
}

export default Dashboard;
