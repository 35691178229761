import React from "react";
import { LinearProgress } from "@mui/material";
import "./css/splash.css";
import image from "./static/Capture.png";

function Splash() {
  return (
    <div>
      <div>
        <LinearProgress color="success" />
      </div>
      <div>
        <p></p>
      </div>
      <div className="splash__image__container">
        <img className="splash__image" src={image} alt="splash" />
      </div>
    </div>
  );
}

export default Splash;
