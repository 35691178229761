import Dashboard from "./Dashboard";
import ZohoDashbaord from "./ZohoDashboard";
import Login from "./Login";
import { useGlobalContext } from "./context";
import { BrowserRouter, Routes, Navigate, Route } from "react-router-dom";
import Register from "./Register";
import PastStockStatement from "./PastStockSatetement";
import ErpSystems from "./ErpSystems";
import UserOrganizationData from "./OrganizationData";
import TallyConnect from "./TallyConnect";
import Settings from "./Settings";
import AccountingSettings from "./AccountingSettings";
import Settings1 from "./bankerComponents/Settings";
import StockStatement from "./bankerComponents/StockStatement";
import SyncBankerLogin from "./bankerComponents/SyncBankerLogin";
import OrganizationData from "./bankerComponents/OrganizationData";
import Dashboard1 from "./bankerComponents/Dashboard";
import FreshbooksDashboard from "./FreshbooksDashboard";
import ViewFreshbooksStock from "./ViewFreshbooksStock";
import BusinessCentral from "./BusinessCentral";
import FreshbookStatement from "./bankerComponents/FreshbookStockStatement";
import ResetPassword from "./ResetPassword";
import ChangePassword from "./ChangePassword";
import ConfirmMail from "./ConfirmMail";

function App() {
  const { authenticate } = useGlobalContext();
  console.log(authenticate);
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path="/"
            render={() =>
              authenticate === true ? <Navigate to={"/dashboard"} /> : <Login />
            }
            element={<Login />}
          />
          <Route path="/login" element={<Login />} />

          <Route path="/register" element={<Register />} />

          <Route path="/dashboard" element={<Dashboard />} />

          <Route path="/share" element={<ZohoDashbaord />} />

          <Route path="/past" element={<PastStockStatement />} />

          <Route path="/past-freshbooks" element={<ViewFreshbooksStock />} />

          <Route path="/erp-systems/" element={<ErpSystems />} />
          <Route path="/TallyERP/:erpName" element={<TallyConnect />} />
          <Route path="/my-organization/" element={<UserOrganizationData />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/accounting-settings" element={<AccountingSettings />} />
          <Route
            path="/freshbooks-dashboard"
            element={<FreshbooksDashboard />}
          />
          <Route path="/microsoft-dashboard" element={<BusinessCentral />} />
          <Route path="/confirm-mail" element={<ConfirmMail />} />
          <Route exact path="/admin/settings" element={<Settings1 />} />
          <Route
            exact
            path="/admin/statement/:pan"
            element={<StockStatement />}
          />
          <Route
            exact
            path="/admin/fresh-books/statement/:pan"
            element={<FreshbookStatement />}
          />
          <Route path="/admin/login" element={<SyncBankerLogin />} />

          <Route path="/admin/dashboard" element={<Dashboard1 />} />
          <Route
            path="/admin/organization/:pan"
            element={<OrganizationData />}
          />
          <Route
            path="/admin/organization/:pan"
            element={<OrganizationData />}
          />
          {/* <Route path="/splash">
            <Splash />
          </Route> */}
          <Route path="/reset-password" element={<ResetPassword />} />

          <Route
            path="/change-password/:uid/:token"
            element={<ChangePassword />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
