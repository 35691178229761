import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useGlobalContext } from "./context";
import {
  Avatar,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  FormControl,
  Checkbox,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import CircleNotificationsRoundedIcon from "@mui/icons-material/CircleNotificationsRounded";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import Alert from "@mui/material/Alert";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import LogoutIcon from "@mui/icons-material/Logout";
import InventoryIcon from "@mui/icons-material/Inventory";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import PaymentsIcon from "@mui/icons-material/Payments";
import BarChartIcon from "@mui/icons-material/BarChart";
import ApartmentIcon from "@mui/icons-material/Apartment";
import InboxIcon from "@mui/icons-material/Inbox";
import LoginIcon from "@mui/icons-material/Login";
import FlagIcon from "@mui/icons-material/Flag";
import sync from "./static/Capture.png";
import "./css/infodashboard.css";
import axios from "./axiosBase";
import "./axiosInterceptors";
import { clearData, getRefreshToken } from "./handleToken";
import { CircularProgress } from "@mui/material";

function InfoDashboard() {
  // const { authenticate } = useGlobalContext();

  // const history = useHistory();
  // if (authenticate === false) {
  //   history("/login");
  // }
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [totalPurchases, setTotalPurchases] = useState(0);
  const [totalSale, setTotalSale] = useState(0);
  const [totalPurchasesFy, setTotalPurchasesFy] = useState(0);
  const [totalSaleFy, setTotalSaleFy] = useState(0);
  const [payableInfo, setPayableInfo] = useState([]);
  const [payableTotal, setPayableTotal] = useState(0);
  const [payable90, setPayable90] = useState(0);
  const [payable90_180, setPayable90_180] = useState(0);
  const [payable180, setPayable180] = useState(0);
  const [recievableTotal, setRecievableTotal] = useState(0);
  const [recievable90, setRecievable90] = useState(0);
  const [recievable90_180, setRecievable90_180] = useState(0);
  const [recievable180, setRecievable180] = useState(0);
  const [organization, setOrganization] = useState([]);
  const [organization_id, setorganization_id] = useState();
  const [items, setItems] = useState([]);
  const [inventory, setInventory] = useState([]);
  const [inventory90, setInventory90] = useState(0);
  const [inventory90_180, setInventory90_180] = useState(0);
  const [payExtraInfo, setpayExtraInfo] = useState([]);
  const [inventory180, setInventory180] = useState(0);
  const [invExtraInfo, setInvExtraInfo] = useState([]);
  const [displayinventory, setDisplayinventory] = useState(false);
  const [displayRecievable, setDisplayRecievable] = useState(false);
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isShare, setIsShare] = useState(false);
  const [pan_no, setPan_no] = useState();
  const [isready, setIsready] = useState({
    val1: false,
    val2: false,
    val3: false,
    val4: false,
    val5: false,
    val6: false,
    val7: false,
  });
  const [isErrorDetails, setisErrorDetails] = useState(
    "An error occured while loading data, Reload page to update"
  );
  const history = useNavigate();
  const { setAuthenticate, authenticate, isAdmin } = useGlobalContext();
  if (authenticate === false) {
    history("/login");
    // history.location.search()
  }
  if (isAdmin === true) {
    history("/admin/dashboard");
  }
  const date = new Date();
  const monthArray = [];
  for (let i = 0; i < date.getMonth(); i++) {
    monthArray.push(i);
  }
  const [details, setDetails] = useState({
    month: "",
    year: "",
    organization: "",
  });
  const getInfo = () => {
    axios
      .post("post-info", {
        date: new Date(),
        panNo: pan_no,
        totalPurchases: totalPurchases,
        totalSale: totalSale,
        totalPurchasesFy: totalPurchasesFy,
        totalSalesFy: totalSaleFy,
        payables: {
          payableTotal: payableTotal,
          payable180: payable180,
          payable90: payable90,
          payable90_180: payable90_180,
        },
        inventory: {
          inventoryTotal: inventory90 + inventory180 + inventory90_180,
          inventory180: inventory180,
          inventory90: inventory90,
          inventory90_180: inventory90_180,
        },
        recievables: {
          recievableTotal: recievableTotal,
          recievable180: recievable180,
          recievable90: recievable90,
          recievable90_180: recievable90_180,
        },
        payableInfo: payExtraInfo,
        recievableinfo: invExtraInfo,
        month: details.month,
        year: details.year,
        day: date.getDate(),
      })
      .then((response) => response.data)
      .then((data) => {
        // console.log(data);
        if (data.success) {
          alert("Data upload Successful");
          history("/dashboard");
        } else {
          setIsError(true);
          setisErrorDetails("Data for this month has been submitted");
        }
      });
  };
  const logout = (e) => {
    e.preventDefault();
    const token = getRefreshToken();
    axios.post("api/token/blacklist/", { refresh: token }).then((response) => {
      clearData();
      setAuthenticate(false);
      history("/login");
    });
  };
  const getOrganization = (cb) => {
    axios
      .get("get-zohoinfo/organizations")
      .then((response) => response.data)
      .then((data) => {
        console.log(data.organizations[0].organization_id);
        setOrganization(data.organizations);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.message === "Network Error") {
          setLoading(false);
          setIsError(true);
          setisErrorDetails("Internet Connection error when loading data");
        } else {
          setIsError(true);
          setisErrorDetails(
            "An error occured while loading Organization data, Reload page to update"
          );
        }
      });
    axios
      .get("user-data")
      .then((response) => response.data)
      .then((data) => {
        // console.log(data);
        console.log(data.data.pan_no);
        setPan_no(data.data.pan_no);
      })
      .catch((err) => console.log(err));
  };
  const getData = () => {
    setLoading(true);
    axios
      .get("get-zohoinfo/invoice", {
        params: {
          organization_id: organization_id,
          year: details.year,
          month: details.month,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        // console.log(data);
        if (!data["invoiceaging"]["group_list"][0]["balance"]) {
          return setRecievableTotal(
            data["invoiceaging"]["group_list"][0]["balance"]
          );
        }
        setRecievableTotal(data["invoiceaging"]["group_list"][0]["balance"]);
        const recievableData =
          data["invoiceaging"]["group_list"][0]["group_list"];
        if (recievableData.length <= 2) {
          for (let i = 0; i < recievableData.length; i++) {
            if (recievableData[i]["name"] === "> 180 Days") {
              setRecievable180(recievableData[i]["balance"]);
            } else if (recievableData[i]["name"] === "1 - 90 Days") {
              setRecievable90(recievableData[i]["balance"]);
            } else {
              setRecievable90_180(recievableData[i]["balance"]);
            }
          }
        } else {
          setRecievable180(
            data["invoiceaging"]["group_list"][0]["group_list"][0]["balance"]
          );
          setRecievable90_180(
            data["invoiceaging"]["group_list"][0]["group_list"][1]["balance"]
          );
          setRecievable90(
            data["invoiceaging"]["group_list"][0]["group_list"][2]["balance"]
          );
        }
        return data["invoiceaging"]["group_list"][0]["group_list"];
      })
      .then((result) => {
        console.log(result);
        const invoiceArray = [];
        if (result.length <= 2) {
          for (let i = 0; i < result.length; i++) {
            console.log(result[i]);
            for (
              let index = 0;
              index < result[i]["group_list"].length;
              index++
            ) {
              const data = {
                name: result[i]["group_list"][index]["customer_name"],
                age: result[i]["group_list"][index]["age"],
                value: result[i]["group_list"][index]["balance"],
              };
              invoiceArray.push(data);
            }
          }
        } else {
          for (let i = 0; i < 3; i++) {
            console.log(result[i]);
            for (
              let index = 0;
              index < result[i]["group_list"].length;
              index++
            ) {
              const data = {
                name: result[i]["group_list"][index]["customer_name"],
                age: result[i]["group_list"][index]["age"],
                value: result[i]["group_list"][index]["balance"],
              };
              invoiceArray.push(data);
            }
          }
        }
        console.log(invoiceArray);
        setInvExtraInfo(invoiceArray);
        setIsready((prevData) => {
          return { ...prevData, val1: true };
        });
      })
      .catch((err) => {
        console.log(err);
        // setIsError(true);
      });
    axios
      .get("get-zohoinfo/bill", {
        params: {
          organization_id: organization_id,
          year: details.year,
          month: details.month,
        },
      })
      .then((result) => result.data)
      .then((data) => {
        setPayableTotal(data["billsaging"]["group_list"][0]["balance"]);
        const payableData = data["billsaging"]["group_list"][0]["group_list"];
        if (payableData.length <= 2) {
          for (let i = 0; i < payableData.length; i++) {
            if (payableData[i]["name"] === "> 180 Days") {
              setPayable180(payableData[i]["balance"]);
            } else if (payableData[i]["name"] === "1 - 90 Days") {
              setPayable90(payableData[i]["balance"]);
            } else {
              setPayable90_180(payableData[i]["balance"]);
            }
          }
        } else {
          setPayable180(
            data["billsaging"]["group_list"][0]["group_list"][0]["balance"]
          );
          setPayable90_180(
            data["billsaging"]["group_list"][0]["group_list"][1]["balance"]
          );
          setPayable90(
            data["billsaging"]["group_list"][0]["group_list"][2]["balance"]
          );
        }
        return data["billsaging"]["group_list"][0]["group_list"];
      })
      .then((result) => {
        console.log(result);
        const payableArray = [];
        for (let i = 0; i < result.length; i++) {
          console.log(result[i]);
          for (let index = 0; index < result[i]["group_list"].length; index++) {
            const data = {
              name: result[i]["group_list"][index]["vendor_name"],
              age: result[i]["group_list"][index]["age"],
              value: result[i]["group_list"][index]["balance"],
            };
            payableArray.push(data);
          }
        }
        console.log(payableArray);
        setpayExtraInfo(payableArray);
        setIsready((prevData) => {
          return { ...prevData, val2: true };
        });
      })
      .catch((err) => {
        console.log(err);
        // setIsError(true);
      });

    axios
      .get("get-zohoinfo/sales", {
        params: {
          organization_id: organization_id,
          year: details.year,
          month: details.month,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        console.log(data);
        if (data["sales"].length === 0) {
          console.log("It is zero");
        }
        setTotalSale(data["page_context"]["sum_columns"]["sales"]);
        setIsready((prevData) => {
          return { ...prevData, val3: true };
        });
      })
      .catch((err) => {
        console.log(err);
        // setIsError(true);
      });
    axios
      .get("get-zohoinfo/purchases", {
        params: {
          organization_id: organization_id,
          year: details.year,
          month: details.month,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        console.log(data);
        setTotalPurchases(data["page_context"]["sum_columns"]["amount"]);
        setIsready((prevData) => {
          return { ...prevData, val4: true };
        });
      })
      .catch((err) => {
        console.log(err);
        // setIsError(true);
      });
    axios
      .get("get-zohoinfo/inventory", {
        params: {
          organization_id: organization_id,
          year: details.year,
          month: details.month,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        console.log(data["inventory_aging_summary"]);
        setInventory(data["inventory_aging_summary"]);
        return data["inventory_aging_summary"];
      })
      .then((res) => {
        axios
          .get("get-zohoinfo/items", {
            params: {
              organization_id: organization_id,
              year: details.year,
              month: details.month,
            },
          })
          .then((response) => response.data)
          .then((data) => data)
          .then((arr) => {
            // setItems(arr.items);
            console.log(arr["inventory_valuation"][0]["item_details"]);
            return arr["inventory_valuation"][0]["item_details"];
          })
          .then((data) => {
            if (
              inventory90 === 0 &&
              inventory90_180 === 0 &&
              inventory180 === 0
            ) {
              for (let i = 0; i < res.length; i++) {
                for (let index = 0; index < data.length; index++) {
                  if (res[i]["item_name"] === data[index]["item_name"]) {
                    const purchase_rate =
                      data[index]["asset_value"] /
                      data[index]["quantity_available"];
                    console.log(
                      res[i]["intervals"][0]["stock_on_hand"] * purchase_rate
                    );
                    setInventory90((prevData) => {
                      return (
                        prevData +
                        res[i]["intervals"][0]["stock_on_hand"] * purchase_rate
                      );
                    });
                  }
                }
              }
              for (let i = 0; i < res.length; i++) {
                for (let index = 0; index < data.length; index++) {
                  if (res[i]["item_name"] === data[index]["item_name"]) {
                    let purchase_rate =
                      data[index]["asset_value"] /
                      data[index]["quantity_available"];
                    setInventory90_180((prevData) => {
                      return (
                        prevData +
                        res[i]["intervals"][1]["stock_on_hand"] * purchase_rate
                      );
                    });
                  }
                }
              }
              for (let i = 0; i < res.length; i++) {
                for (let index = 0; index < data.length; index++) {
                  if (res[i]["item_name"] === data[index]["item_name"]) {
                    let purchase_rate =
                      data[index]["asset_value"] /
                      data[index]["quantity_available"];
                    setInventory180((prevData) => {
                      return (
                        prevData +
                        res[i]["intervals"][2]["stock_on_hand"] * purchase_rate
                      );
                    });
                  }
                }
              }
            } else {
              setInventory90(0);
              setInventory180(0);
              setInventory90_180(0);
              for (let i = 0; i < res.length; i++) {
                for (let index = 0; index < data.length; index++) {
                  if (res[i]["item_name"] === data[index]["item_name"]) {
                    // console.log(
                    //   res[i]["intervals"][0]["stock_on_hand"] *
                    //     data[index]["purchase_rate"]
                    // );
                    let purchase_rate =
                      data[index]["asset_value"] /
                      data[index]["quantity_available"];
                    setInventory90((prevData) => {
                      return (
                        prevData +
                        res[i]["intervals"][0]["stock_on_hand"] * purchase_rate
                      );
                    });
                  }
                }
              }
              for (let i = 0; i < res.length; i++) {
                for (let index = 0; index < data.length; index++) {
                  if (res[i]["item_name"] === data[index]["item_name"]) {
                    let purchase_rate =
                      data[index]["asset_value"] /
                      data[index]["quantity_available"];
                    setInventory90_180((prevData) => {
                      return (
                        prevData +
                        res[i]["intervals"][1]["stock_on_hand"] * purchase_rate
                      );
                    });
                  }
                }
              }
              for (let i = 0; i < res.length; i++) {
                for (let index = 0; index < data.length; index++) {
                  if (res[i]["item_name"] === data[index]["item_name"]) {
                    let purchase_rate =
                      data[index]["asset_value"] /
                      data[index]["quantity_available"];
                    setInventory180((prevData) => {
                      return (
                        prevData +
                        res[i]["intervals"][2]["stock_on_hand"] * purchase_rate
                      );
                    });
                  }
                }
              }
            }
            setIsready((prevData) => {
              return { ...prevData, val5: true };
            });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get("get-zohoinfo/totalsale", {
        params: {
          organization_id: organization_id,
          year:
            parseInt(details.month) < 4
              ? parseInt(details.year) - 1
              : details.year,
          month: details.month,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        setIsready((prevData) => {
          return { ...prevData, val6: true };
        });
        console.log(data);
        setTotalSaleFy(data["page_context"]["sum_columns"]["sales"]);
      })
      .catch((err) => {
        console.log(err);
        setIsError(true);
      });
    axios
      .get("get-zohoinfo/totalpurchase", {
        params: {
          organization_id: organization_id,
          year:
            parseInt(details.month) < 4
              ? parseInt(details.year) - 1
              : details.year,
          month: details.month,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        console.log(data);
        setTotalPurchasesFy(data["page_context"]["sum_columns"]["amount"]);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // setIsError(true);
      });
  };
  // if (
  //   isready.val1 &&
  //   isready.val2 &&
  //   isready.val3 &&
  //   isready.val4 &&
  //   isready.val5 &&
  //   isready.val6 &&
  //   isready.val7
  // ) {
  //   setLoading(false);
  // }
  useEffect(() => {
    getOrganization();
  }, []);

  useEffect(() => {
    if (details.month && details.year && details.organization) {
      setPayable180(0);
      setPayable90(0);
      setInventory180(0);
      setPayable90_180(0);
      setInventory90(0);
      setInventory90_180(0);
      setRecievable180(0);
      setRecievable90(0);
      setRecievable90_180(0);
      setRecievableTotal(0);
      setTotalPurchases(0);
      setTotalPurchasesFy(0);
      setTotalSale(0);
      setTotalSaleFy(0);
      setInvExtraInfo([]);
      setpayExtraInfo([]);
      setPayableTotal(0);
      setRecievableTotal(0);
      setIsError(false);
      getData();
    }
    // eslint-disable-next-line
  }, [details]);
  const formValue = (event) => {
    if (event.target.name === "organization") {
      setorganization_id(event.target.value);
    }
    if (event.target.name === "year" && details.month) {
      if (
        !parseInt(details.month) < 4 &&
        parseInt(details.month) > monthArray.slice(-1)[0] + 1
      ) {
        setDetails((oldValue) => {
          return {
            ...oldValue,
            [event.target.name]: event.target.value,
            month: "",
          };
        });
      } else {
        setDetails((oldValue) => {
          return { ...oldValue, [event.target.name]: event.target.value };
        });
      }
    } else {
      setDetails((oldValue) => {
        return { ...oldValue, [event.target.name]: event.target.value };
      });
    }
  };
  const handleCheck = (event) => {
    // console.log(event.target.checked);
    setIsShare(event.target.checked);
  };
  console.log(details);
  return (
    <div className="share__dashboard">
      <div className="share__dashboard__sidenav">
        <div className="share__dashboard__sidenav__img">
          <img src={sync} alt="" />
        </div>
        <ul>
          <li className="active">
            <Link to="/dashboard">
              {/* eslint-disable-next-line */}
              <a href="#">
                <span className="icon">
                  <GridViewRoundedIcon />
                </span>
                Dashboard
              </a>
            </Link>
          </li>
          <li>
            <Link to="/my-organization">
              {/* eslint-disable-next-line */}
              <a href="#">
                <span className="icon">
                  <HomeRoundedIcon />
                </span>
                My Organization
              </a>
            </Link>
          </li>
          <li>
            <Link to="/settings">
              {/* eslint-disable-next-line */}
              <a href="#">
                <span className="icon">
                  <LoginIcon />
                </span>
                Login Settings
              </a>
            </Link>
          </li>
          <li>
            <Link to="/erp-systems">
              {/* eslint-disable-next-line */}
              <a href="#">
                <span className="icon">
                  <SettingsIcon />
                </span>
                Erp Settings
              </a>
            </Link>
          </li>
          <li>
            <a href="/logout" onClick={(e) => logout(e)}>
              <span className="icon">
                <LogoutIcon />
              </span>
              Logout
            </a>
          </li>
        </ul>
      </div>
      <div className="share__dashboard__topnav">
        <div className="share__dashboard__topnav__header">
          <h1>Dashboard</h1>
        </div>
        <div className="share__dashboard__topnav__item">
          <a href="/">
            <CircleNotificationsRoundedIcon className="notification_icon" />
          </a>
          <Avatar className="image__avatar" />
        </div>
      </div>
      <div className="share__dashboard__main__maindivision">
        <div className="share__dashboard__main__subdivision_fresh">
          <div className="share__dashboard__main__head">
            <div className="share__dashboard__main__head__div">
              <Grid container spacing={1}>
                <Grid item>
                  <FormLabel className="form-label">Year</FormLabel>
                  <FormControl fullWidth>
                    <Select
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={details.year}
                      name="year"
                      size="small"
                      onChange={formValue}
                      className="form__textfield__select"
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                    >
                      <MenuItem value={date.getFullYear()}>
                        {date.getFullYear()}
                      </MenuItem>
                      <MenuItem value={date.getFullYear() - 1}>
                        {date.getFullYear() - 1}
                      </MenuItem>
                      <MenuItem value={date.getFullYear() - 2}>
                        {date.getFullYear() - 2}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormLabel className="form-label">Month</FormLabel>
                  <FormControl fullWidth>
                    {!details.year && (
                      <Select
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        size="small"
                        name="month"
                        sx={{
                          "& fieldset": { border: "none" },
                        }}
                        className="form__textfield__select"
                      >
                        <MenuItem disabled>Select Year</MenuItem>
                      </Select>
                    )}
                    {details.year === date.getFullYear() && (
                      <Select
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={details.month}
                        size="small"
                        name="month"
                        onChange={formValue}
                        className="form__textfield__select"
                        sx={{
                          "& fieldset": { border: "none" },
                        }}
                      >
                        {monthArray.map((index) => {
                          return (
                            <MenuItem
                              value={index < 9 ? `${0}${index + 1}` : index + 1}
                            >
                              {monthNames[index]}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    )}
                    {details.year && details.year < date.getFullYear() && (
                      <Select
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={details.month}
                        size="small"
                        name="month"
                        onChange={formValue}
                        className="form__textfield__select"
                        sx={{
                          "& fieldset": { border: "none" },
                        }}
                      >
                        <MenuItem value="01">January</MenuItem>
                        <MenuItem value="02">February</MenuItem>
                        <MenuItem value="03">March</MenuItem>
                        <MenuItem value="04">April</MenuItem>
                        <MenuItem value="05">May</MenuItem>
                        <MenuItem value="06">June</MenuItem>
                        <MenuItem value="07">July</MenuItem>
                        <MenuItem value="08">August</MenuItem>
                        <MenuItem value="09">September</MenuItem>
                        <MenuItem value="10">October</MenuItem>
                        <MenuItem value="11">November</MenuItem>
                        <MenuItem value="12">December</MenuItem>
                      </Select>
                    )}
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormLabel className="form-label">Organization</FormLabel>
                  <FormControl fullWidth>
                    <Select
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={details.organization}
                      name="organization"
                      size="small"
                      onChange={formValue}
                      className="form__textfield__select"
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                    >
                      {organization.map((org) => {
                        return (
                          <MenuItem value={org.organization_id}>
                            {" "}
                            {org.name}{" "}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                {loading && (
                  <>
                    <div className="progress">
                      <CircularProgress size={20} /> Fetching Organization/data
                    </div>
                  </>
                )}
              </Grid>
            </div>
            <div>
              <p>(Value in INR)</p>
            </div>
          </div>
          {isError && (
            <div className="alert">
              <Alert variant="filled" severity="error">
                {isErrorDetails}
              </Alert>
            </div>
          )}
          <div className="share__table">
            <table>
              <tr>
                <th>Particulars</th>
                <th>Total</th>
                <th>0-90 Days</th>
                <th>90-180 Days</th>
                <th>Greater than 180</th>
              </tr>
              <tr>
                <td className="share__table__data">
                  <InventoryIcon className="inventory__color" />
                  <span className="table__data__text__special">Inventory</span>
                </td>
                <td>
                  {parseInt(
                    inventory90 + inventory90_180 + inventory180
                  )?.toLocaleString("hi")}
                </td>
                <td>{parseInt(inventory90)?.toLocaleString("hi")}</td>
                <td>{parseInt(inventory90_180)?.toLocaleString("hi")}</td>
                <td>{parseInt(inventory180).toLocaleString("hi")}</td>
              </tr>
              <tr>
                <td className="share__table__data">
                  <PaymentsIcon className="payable__color" />
                  <span className="share__table__data__text">Payable</span>
                  <AddCircleOutlineIcon
                    onClick={() => {
                      setDisplayinventory(!displayinventory);
                    }}
                    className="payable__add__icon"
                  />
                </td>
                <td>{parseInt(payableTotal).toLocaleString("hi")}</td>
                <td>{parseInt(payable90).toLocaleString("hi")}</td>
                <td>{parseInt(payable90_180).toLocaleString("hi")}</td>
                <td>{parseInt(payable180).toLocaleString("hi")}</td>
              </tr>
              {displayinventory && (
                <>
                  <tr>
                    <th>Name</th>
                    <th>Age</th>
                    <th>Value</th>
                    <th></th>
                    <th></th>
                  </tr>
                  {payExtraInfo?.map((data) => {
                    return (
                      <tr>
                        <td>{data.name}</td>
                        <td>{data.age ? data.age : "Not Available"}</td>
                        <td>{parseInt(data.value).toLocaleString("hi")}</td>
                        <td></td>
                        <td></td>
                      </tr>
                    );
                  })}
                </>
              )}
              <tr>
                <td className="share__table__data">
                  <InboxIcon className="recievable__color" />
                  <span className="share__table__data__text">Recievable</span>
                  <AddCircleOutlineIcon
                    onClick={() => setDisplayRecievable(!displayRecievable)}
                  />
                </td>
                <td>{parseInt(recievableTotal)?.toLocaleString("hi")}</td>
                <td>{parseInt(recievable90)?.toLocaleString("hi")}</td>
                <td>{parseInt(recievable90_180)?.toLocaleString("hi")}</td>
                <td>{parseInt(recievable180)?.toLocaleString("hi")}</td>
              </tr>
              {displayRecievable && (
                <>
                  <tr>
                    <th>Name</th>
                    <th>Age</th>
                    <th>Value</th>
                    <th></th>
                    <th></th>
                  </tr>
                  {invExtraInfo?.map((data) => {
                    return (
                      <tr>
                        <td>{data.name}</td>
                        <td>{data.age ? data.age : "Not Available"}</td>
                        <td>{parseInt(data.value).toLocaleString("hi")}</td>
                        <td></td>
                        <td></td>
                      </tr>
                    );
                  })}
                </>
              )}
            </table>
            {/* <CircularProgress /> */}
            <div className="share__table__2">
              <table>
                <tr>
                  <th></th>
                  <th>Current Month</th>
                  <th>Current FY</th>
                </tr>
                <tr>
                  <td className="share__table__data">
                    <BarChartIcon className="sales__color" />
                    <span className="table__data__text__special">Sales</span>
                  </td>
                  <td>{parseInt(totalSale)?.toLocaleString("hi")}</td>
                  <td>{parseInt(totalSaleFy)?.toLocaleString("hi")}</td>
                </tr>
                <tr>
                  <td className="share__table__data">
                    <ApartmentIcon className="purchase__color" />
                    <span className="share__table__data__text">Purchases</span>
                  </td>
                  <td>{parseInt(totalPurchases)?.toLocaleString("hi")}</td>
                  <td>{parseInt(totalPurchasesFy)?.toLocaleString("hi")}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div className="declaration__div__info_fresh">
          <p>
            <FlagIcon /> Declaration
          </p>
          <div className="declaration__div__info__form">
            <Checkbox
              onClick={handleCheck}
              className="declaration__div__form__checkbox"
            />
            <p>
              We Certify that the Data represented in the report is correct and
              is in accordance with the entries in our records.
            </p>
          </div>
          <div className="declaration__div__form__button">
            <button disabled={!isShare} onClick={getInfo}>
              Share
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfoDashboard;
