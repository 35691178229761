import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import sync from ".././static/Capture.png";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import SettingsIcon from "@mui/icons-material/Settings";
import { useGlobalContext } from "../context";
import LogoutIcon from "@mui/icons-material/Logout";
import axios from "../axiosBase";
import "../axiosInterceptors";
import CircleNotificationsRoundedIcon from "@mui/icons-material/CircleNotificationsRounded";
import { Avatar, Button, FormLabel, Grid, TextField } from "@mui/material";
import Alert from "@mui/material/Alert";
import "./css/Settings.css";
import { clearData, getRefreshToken } from "../handleToken";

function Settings1() {
  const { authenticate, setAuthenticate, setisAdmin } = useGlobalContext();
  const [isError, setisError] = useState(false);
  const [isErrorDetails, setisErrorDetails] = useState();
  const [isSuccess, setisSuccess] = useState(false);
  const history = useNavigate();
  if (authenticate === false) {
    history("/admin/login");
  }
  const [details, setdetails] = useState({
    oldPassword: "",
    newPassword1: "",
    newPassword2: "",
  });
  const formValue = (event) => {
    setdetails((prevData) => {
      return { ...prevData, [event.target.name]: [event.target.value] };
    });
  };
  const handleForm = (event) => {
    event.preventDefault();
    console.log(details.oldPassword);
    console.log(details.newPassword1);
    console.log(details.newPassword2);
    if (details.newPassword1[0] !== details.newPassword2[0]) {
      setisError(true);
      setisErrorDetails("Password not matching");
    } else {
      axios
        .post("admin-user/set_password", {
          old: details.oldPassword[0],
          new: details.newPassword1[0],
        })
        .then((response) => response.data)
        .then((data) => {
          console.log(data);
          if (data.message === "error") {
            setisSuccess(false);
            setisError(true);
            setisErrorDetails(data.data);
          } else {
            setisError(false);
            setisSuccess(true);
          }
        })
        .catch((err) => {
          console.log(err);
          setisError(true);
        });
    }
  };
  useEffect(() => {
    setInterval(() => {
      if (isError) {
        setisError(false);
      }
    }, 10000);
  }, [isError]);
  const logout = (e) => {
    e.preventDefault();
    const token = getRefreshToken();
    axios.post("api/token/blacklist/", { refresh: token }).then((response) => {
      clearData();
      setAuthenticate(false);
      setisAdmin(false);
      history("/admin/login");
    });
  };
  return (
    <div className="share__dashboard">
      <div className="share__dashboard__sidenav">
        <div className="share__dashboard__sidenav__img">
          <img src={sync} alt="" />
        </div>
        <ul>
          <li>
            <Link to="/admin/dashboard">
              <a href="admin/dashboard">
                <span className="icon">
                  <GridViewRoundedIcon />
                </span>
                Dashboard
              </a>
            </Link>
          </li>
          <li className="active-setting">
            <Link to="/admin/settings">
              <a href="admin/settings" onClick={(e) => e.preventDefault()}>
                <span className="icon">
                  <SettingsIcon />
                </span>
                Settings
              </a>
            </Link>
          </li>
          <li>
            <a href="/" onClick={(e) => logout(e)}>
              <span className="icon">
                <LogoutIcon />
              </span>
              Logout
            </a>
          </li>
        </ul>
      </div>
      <div className="share__dashboard__topnav">
        <div className="share__dashboard__topnav__header">
          <h1>My organization</h1>
        </div>
        <div className="share__dashboard__topnav__item">
          <a href="/">
            <CircleNotificationsRoundedIcon className="notification_icon" />
          </a>
          <Avatar className="image__avatar" />
        </div>
      </div>
      <div
        className="share__dashboard__main__maindivision"
        style={{ marginTop: "30px" }}
      >
        <div className="share__dashboard__main__subdivision">
          <div className="btn__settings">
            <Button
              className="login__button"
              style={{ color: "#272E4E", fontWeight: "600", fontSize: "12px" }}
            >
              Login Settings
            </Button>
          </div>
          <div className="share__table__organization">
            <div>
              {isSuccess && (
                <div className="alert-setting">
                  <Alert variant="filled" severity="success">
                    Update Success
                  </Alert>
                </div>
              )}
              {isError && (
                <div className="alert-setting">
                  <Alert variant="filled" severity="error">
                    {isErrorDetails
                      ? isErrorDetails
                      : "An error occured while processing request"}
                  </Alert>
                </div>
              )}
              <form action="">
                <Grid container item spacing={2}>
                  <Grid item xs={6}>
                    <FormLabel className="form-label">
                      Current Password
                    </FormLabel>
                    <TextField
                      fullWidth
                      id="oulined-basic"
                      variant="outlined"
                      name="oldPassword"
                      type="password"
                      placeholder="Enter your Current Password"
                      value={details.oldPassword}
                      onChange={formValue}
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                      size="small"
                      className="form__textfield"
                    />
                  </Grid>
                  <Grid item xs={6}></Grid>
                  <Grid item xs={6}>
                    <FormLabel className="form-label">New Password</FormLabel>
                    <TextField
                      fullWidth
                      id="oulined-basic"
                      type="password"
                      variant="outlined"
                      name="newPassword1"
                      placeholder="Enter your New Password"
                      value={details.newPassword1}
                      onChange={formValue}
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                      size="small"
                      className="form__textfield"
                    />
                  </Grid>
                  <Grid item xs={6}></Grid>
                  <Grid item xs={6}>
                    <FormLabel className="form-label">
                      Confirm Password
                    </FormLabel>
                    <TextField
                      fullWidth
                      type="password"
                      id="oulined-basic"
                      variant="outlined"
                      name="newPassword2"
                      placeholder="Enter Your Confirm Password"
                      value={details.newPassword2}
                      onChange={formValue}
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                      size="small"
                      className="form__textfield"
                    />
                  </Grid>
                  <Grid item xs={6}></Grid>
                  <Grid item sm={3}>
                    <Button
                      onClick={(e) => handleForm(e)}
                      className="button"
                      fullWidth
                      variant="contained"
                    >
                      Save
                    </Button>
                  </Grid>
                  <Grid item sm={2}>
                    <Button
                      // className="button"
                      fullWidth
                      variant="outlined"
                      href="/admin/dashboard"
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings1;
