export const getAccessToken = () => {
  const token = localStorage.getItem("access-token");
  if (token) {
    return token;
  }
};

export const setAccessToken = (token) => {
  localStorage.setItem("access-token", token);
};

export const setAccessRefreshToken = (access, refresh) => {
  localStorage.setItem("access-token", access);
  localStorage.setItem("refresh-token", refresh);
};

export const getRefreshToken = () => {
  const token = localStorage.getItem("refresh-token");
  if (token) {
    return token;
  }
};

// export const createUser = (pan_no, email) => {
//   localStorage.setItem("email", email);
//   localStorage.setItem("pan-no", pan_no);
// };

// export const getUser = () => {
//   const pan_no = localStorage.getItem("pan-no");
//   const email = localStorage.getItem("email");
//   return { email, pan_no };
// };

export const getTime = () => {
  const time = window.localStorage.getItem("time");
  return time;
};

export const clearData = () => {
  window.localStorage.clear()
}