import React, { createContext, useContext, useEffect, useState } from "react";
import axiosRequest from "./axiosBase";
import axios from "axios";
import "./axiosInterceptors";
import "./axiosPrivate";

const Value = createContext();
const Provider = ({ children }) => {
  const [authenticate, setAuthenticate] = useState();
  const [apiAuthenticate, setApiAuthenticate] = useState();
  const [username, setUsername] = useState();
  const [isAdmin, setisAdmin] = useState();
  const [pan, setPan] = useState();
  const [networkError, setNetworkError] = useState();

  // axios.defaults.headers.common["Authorization"] =
  //   "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjYxMzU3OTA4LCJpYXQiOjE2NjEzNTQzMDgsImp0aSI6IjRkOTEyOWQ2OWNlNzRlYWFiZDMyNzgxM2I4ZGRjNzMzIiwidXNlcl9pZCI6N30.aZ2oGZ3VliYW8oeHMKfBIgmPSYu_2G1O5uELMX2vAOQ";
  const getData = () => {
    axiosRequest
      .get("login-view")
      .then((response) => {
        if (response) {
          console.log(response);
          return response.data;
        }
      })
      .then((data) => {
        console.log(data.authentication);
        setAuthenticate(data.authentication);
        if (data.authentication === true) {
          console.log(data.user);
          setUsername(data.user.email);
        }
        if (data.is_admin) {
          setisAdmin(true);
        }
      })
      .catch((err) => {
        console.log(err.message);
        setNetworkError(true);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Value.Provider
      value={{
        authenticate,
        setAuthenticate,
        apiAuthenticate,
        setApiAuthenticate,
        setUsername,
        username,
        isAdmin,
        setisAdmin,
        setPan,
        pan,
        networkError,
      }}
    >
      {children}
    </Value.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(Value);
};

export { Provider, Value };
