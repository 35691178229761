import React, { useEffect, useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useGlobalContext } from "../context";
import {
  Grid,
  Avatar,
  TextField,
  Button,
  Select,
  MenuItem,
  Alert,
  FormLabel,
  FormControl,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import CircleNotificationsRoundedIcon from "@mui/icons-material/CircleNotificationsRounded";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import LogoutIcon from "@mui/icons-material/Logout";
import InventoryIcon from "@mui/icons-material/Inventory";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import PaymentsIcon from "@mui/icons-material/Payments";
import BarChartIcon from "@mui/icons-material/BarChart";
import ApartmentIcon from "@mui/icons-material/Apartment";
import InboxIcon from "@mui/icons-material/Inbox";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import sync from "./static/Capture 1.png";
import "./css/StockStatement.css";
import axios from "../axiosBase";
import "../axiosInterceptors";
import { clearData, getRefreshToken } from "../handleToken";
import StockStatement from "./StockStatement";
// var jsonDataFromXml = new XMLParser().;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function FreshbookStatement({ monthPassed, yearPassed }) {
  const date = new Date();
  // const { authenticate } = useGlobalContext();

  // const history = useHistory();
  // if (authenticate === false) {
  //   history("/login");
  // }
  const [isError, setIsError] = useState(false);
  const [totalPurchases, setTotalPurchases] = useState(0);
  const [totalSale, setTotalSale] = useState(0);
  const [totalPurchasesFy, setTotalPurchasesFy] = useState(0);
  const [totalSaleFy, setTotalSaleFy] = useState(0);
  const [payable90, setPayable90] = useState(0);
  const [payable0_30, setPayable0_30] = useState(0);
  const [payable31_60, setPayable31_60] = useState(0);
  const [payable61_90, setPayable61_90] = useState(0);
  const [recievable0_30, setRecievable0_30] = useState(0);
  const [recievable31_60, setRecievable31_60] = useState(0);
  const [recievable61_90, setRecievable61_90] = useState(0);
  const [recievable90, setRecievable90] = useState(0);
  const [payExtraInfo, setpayExtraInfo] = useState([]);
  const [invExtraInfo, setInvExtraInfo] = useState([]);
  const [displayinventory, setDisplayinventory] = useState(false);
  const [displayRecievable, setDisplayRecievable] = useState(false);
  const { authenticate, setAuthenticate, setisAdmin, isAdmin } =
    useGlobalContext();
  const [organizationName, setOrganizationName] = useState("Organization_Name");
  const [prevTotalPayable, setprevTotalPayable] = useState(0);
  const [prevTotalRecievable, setprevTotalRecievable] = useState(0);
  const [prevTotalSales, setprevTotalSales] = useState(0);
  const [prevTotalPurchase, setprevTotalPurchase] = useState(0);
  const [prevPrevTotalPayable, setprevPrevTotalPayable] = useState(0);
  const [prevPrevTotalRecievable, setprevPrevTotalRecievable] = useState(0);
  const [prevPrevTotalSales, setprevPrevTotalSales] = useState(0);
  const [prevPrevTotalPurchase, setprevPrevTotalPurchase] = useState(0);
  const [erpName, setErpName] = useState("");
  // const [prevRecieve90, setprevRecieve90] = useState(0);
  // const [prevPrevRecieve90, setprevPrevRecieve90] = useState(0);

  // const history = useHistory();
  // if (authenticate === false) {
  //   history("/login");
  // }
  let { pan } = useParams();
  const [flagNo, setFlagNo] = useState(0);
  const [FlagObject, setFlagObject] = useState([]);
  const [details, setDetails] = useState({
    month: monthPassed ? monthPassed : "",
    year: yearPassed ? yearPassed : "",
  });
  const history = useNavigate();
  if (authenticate === false) {
    history("/admin/login");
  }
  if (authenticate === true && isAdmin === false) {
    clearData();
    history("/login");
  }
  const logout = (e) => {
    e.preventDefault();
    const token = getRefreshToken();
    axios.post("api/token/blacklist/", { refresh: token }).then((response) => {
      clearData();
      setAuthenticate(false);
      setisAdmin(false);
      history("/admin/login");
    });
  };

  console.log(details.year, details.month);

  const getStockData = async () => {
    axios
      .get("stock-statement", {
        params: { pan_no: pan, year: details.year, month: details.month },
      })
      .then((response) => response.data)
      .then((result) => {
        if (result.message === "error") {
          setIsError(true);
          return result.message;
        }
        console.log(result.data.data.erp_name);
        setIsError(false);
        return result.data.data.erp_name;
      })
      .then((result) => {
        if (result === "error") {
          return console.log("error");
        }
        if (result !== "fresh-books") {
          setErpName(result);
        }
        getData();
        getPrevMonth();
        prevAfterPrevMonth();
      });
  };

  const getData = () => {
    axios
      .get("recievable", {
        params: {
          year: details.year,
          month: details.month,
          pan_no: pan,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        console.log(data);
        setInvExtraInfo(data.recievable);
      })
      .catch((err) => console.log(err));
    axios
      .get("recievable-info", {
        params: {
          year: details.year,
          month: details.month,
          pan_no: pan,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        console.log(data);
        setRecievable0_30(data.recievable[0]["_0_to_30"]);
        setRecievable90(data.recievable[0]["greater_than_90"]);
        setRecievable31_60(data.recievable[0]["_31_to_60"]);
        setRecievable61_90(data.recievable[0]["_61_to_90"]);
      })
      .catch((err) => console.log(err));
    axios
      .get("payable", {
        params: {
          year: details.year,
          month: details.month,
          pan_no: pan,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        console.log(data);
        setPayable0_30(data.data[0]["_0_to_30"]);
        setPayable90(data.data[0]["greater_than_90"]);
        setPayable31_60(data.data[0]["_31_to_60"]);
        setPayable61_90(data.data[0]["_61_to_90"]);
      })
      .catch((err) => console.log(err));
    axios
      .get("payable-info", {
        params: {
          year: details.year,
          month: details.month,
          pan_no: pan,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        console.log(data);
        setpayExtraInfo(data.data);
      })
      .catch((err) => console.log(err));
    axios
      .get("purchases", {
        params: {
          year: details.year,
          month: details.month,
          pan_no: pan,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        console.log(data);
        setTotalPurchases(data.data[0]["purchase_value"]);
        setTotalPurchasesFy(data.data[0]["purchases_cumulative_fy"]);
      })
      .catch((err) => console.log(err));
    axios
      .get("sales", {
        params: {
          year: details.year,
          month: details.month,
          pan_no: pan,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        console.log(data);
        setTotalSale(data.data[0]["sales_value"]);
        setTotalSaleFy(data.data[0]["sale_cummulative_fy"]);
      })
      .catch((err) => console.log(err));
  };

  const getPrevMonth = () => {
    console.log(details.month, "from prevMonth");
    axios
      .get("recievable-info", {
        params: {
          year:
            details.month === "01" ? parseInt(details.year) - 1 : details.year,
          month: details.month === "01" ? "12" : parseInt(details.month) - 1,
          pan_no: pan,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        if (data.message !== "error") {
          setprevTotalRecievable(
            parseFloat(data.recievable[0]["_0_to_30"]) +
              parseFloat(data.recievable[0]["greater_than_90"]) +
              parseFloat(data.recievable[0]["_31_to_60"]) +
              parseFloat(data.recievable[0]["_61_to_90"])
          );
        }
      })
      .catch((err) => console.log(err));
    axios
      .get("payable", {
        params: {
          year:
            details.month === "01" ? parseInt(details.year) - 1 : details.year,
          month: details.month === "01" ? "12" : parseInt(details.month) - 1,
          pan_no: pan,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        if (data.message !== "error") {
          setprevTotalPayable(
            parseFloat(data.data[0]["_0_to_30"]) +
              parseFloat(data.data[0]["greater_than_90"]) +
              parseFloat(data.data[0]["_31_to_60"]) +
              parseFloat(data.data[0]["_61_to_90"])
          );
        }
      })
      .catch((err) => console.log(err));
    axios
      .get("purchases", {
        params: {
          year:
            details.month === "01" ? parseInt(details.year) - 1 : details.year,
          month: details.month === "01" ? "12" : parseInt(details.month) - 1,
          pan_no: pan,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        console.log(data);
        if (data.message !== "error") {
          setprevTotalPurchase(data.data[0]["purchase_value"]);
        }
      })
      .catch((err) => console.log(err));
    axios
      .get("sales", {
        params: {
          year:
            details.month === "01" ? parseInt(details.year) - 1 : details.year,
          month: details.month === "01" ? "12" : parseInt(details.month) - 1,
          pan_no: pan,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        if (data.message !== "error") {
          setprevTotalSales(data.data[0]["sales_value"]);
        }
      })
      .catch((err) => console.log(err));
  };

  const prevAfterPrevMonth = () => {
    if (details.month === "01") {
      axios
        .get("recievable-info", {
          params: {
            year: parseInt(details.year) - 1,
            month: "11",
            pan_no: pan,
          },
        })
        .then((response) => response.data)
        .then((data) => {
          if (data.message !== "error") {
            setprevPrevTotalRecievable(
              parseFloat(data.data[0]["_0_to_30"]) +
                parseFloat(data.data[0]["greater_than_90"]) +
                parseFloat(data.data[0]["_31_to_60"]) +
                parseFloat(data.data[0]["_61_to_90"])
            );
          }
        })
        .catch((err) => console.log(err));
      axios
        .get("payable", {
          params: {
            year: parseInt(details.year) - 1,
            month: "11",
            pan_no: pan,
          },
        })
        .then((response) => response.data)
        .then((data) => {
          if (data.message !== "error") {
            setprevPrevTotalPayable(
              parseFloat(data.data[0]["_0_to_30"]) +
                parseFloat(data.data[0]["greater_than_90"]) +
                parseFloat(data.data[0]["_31_to_60"]) +
                parseFloat(data.data[0]["_61_to_90"])
            );
          }
        })
        .catch((err) => console.log(err));
      axios
        .get("purchases", {
          params: {
            year: parseInt(details.year) - 1,
            month: "11",
            pan_no: pan,
          },
        })
        .then((response) => response.data)
        .then((data) => {
          if (data.message !== "error") {
            setprevPrevTotalPurchase(data.data[0]["purchase_value"]);
          }
        })
        .catch((err) => console.log(err));
      axios
        .get("sales", {
          params: {
            year: parseInt(details.year) - 1,
            month: "11",
            pan_no: pan,
          },
        })
        .then((response) => response.data)
        .then((data) => {
          if (data.message !== "error") {
            setprevPrevTotalSales(data.data[0]["sales_value"]);
          }
        })
        .catch((err) => console.log(err));
    } else {
      axios
        .get("recievable-info", {
          params: {
            year:
              details.month === "02"
                ? parseInt(details.year) - 1
                : details.year,
            month: details.month === "02" ? "12" : parseInt(details.month) - 2,
            pan_no: pan,
          },
        })
        .then((response) => response.data)
        .then((data) => {
          if (data.message !== "error") {
            setprevPrevTotalRecievable(
              parseFloat(data.data[0]["_0_to_30"]) +
                parseFloat(data.data[0]["greater_than_90"]) +
                parseFloat(data.data[0]["_31_to_60"]) +
                parseFloat(data.data[0]["_61_to_90"])
            );
          }
        })
        .catch((err) => console.log(err));
      axios
        .get("payable", {
          params: {
            year:
              details.month === "02"
                ? parseInt(details.year) - 1
                : details.year,
            month: details.month === "02" ? "12" : parseInt(details.month) - 2,
            pan_no: pan,
          },
        })
        .then((response) => response.data)
        .then((data) => {
          if (data.message !== "error") {
            setprevPrevTotalPayable(
              parseFloat(data.data[0]["_0_to_30"]) +
                parseFloat(data.data[0]["greater_than_90"]) +
                parseFloat(data.data[0]["_31_to_60"]) +
                parseFloat(data.data[0]["_61_to_90"])
            );
          }
        })
        .catch((err) => console.log(err));
    }
    axios
      .get("purchases", {
        params: {
          year:
            details.month === "02" ? parseInt(details.year) - 1 : details.year,
          month: details.month === "02" ? "12" : parseInt(details.month) - 2,
          pan_no: pan,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        if (data.message !== "error") {
          setprevPrevTotalPurchase(data.data[0]["purchase_value"]);
        }
      })
      .catch((err) => console.log(err));
    axios
      .get("sales", {
        params: {
          year:
            details.month === "02" ? parseInt(details.year) - 1 : details.year,
          month: details.month === "02" ? "12" : parseInt(details.month) - 2,
          pan_no: pan,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        if (data.message !== "error") {
          setprevPrevTotalSales(data.data[0]["sales_value"]);
        }
      })
      .catch((err) => console.log(err));
  };
  const formValue = (event) => {
    setDetails((oldValue) => {
      return { ...oldValue, [event.target.name]: event.target.value };
    });
  };
  useEffect(() => {
    if (details.month && details.year) {
      setPayable90(0);
      setPayable0_30(0);
      setPayable31_60(0);
      setPayable61_90(0);
      setRecievable31_60(0);
      setRecievable61_90(0);
      setRecievable90(0);
      setTotalPurchases(0);
      setTotalPurchasesFy(0);
      setTotalSale(0);
      setTotalSaleFy(0);
      setInvExtraInfo([]);
      setpayExtraInfo([]);
      getStockData();
      // getData();
      // getPrevMonth();
      // prevAfterPrevMonth();
    }
    // eslint-disable-next-line
  }, [details]);

  //   const [flags, setFlags] = useState(FlagObject);
  const [newFlag, setNewFlag] = useState("");
  const getFlag = () => {
    axios
      .get("flag", {
        params: {
          pan_no: pan,
        },
      })
      .then((response) => response.data)
      .then((result) => {
        console.log(result);
        setFlagNo(result.flag_no);
        setFlagObject(result.data);
      })
      .catch((err) => console.log(err));
  };
  const getUser = () => {
    axios
      .get("user-data", {
        params: {
          pan_no: pan,
        },
      })
      .then((response) => response.data)
      .then((result) => {
        console.log(result);
        if (result.message === "success") {
          console.log(result);
          setOrganizationName(result.data.organization_name);
          setDetails((prevData) => {
            return {
              ...prevData,
              organization: result.data.organization_name,
              email: result.data.email,
              pan_no: result.data.pan_no,
              contact_no: result.data.contact_no,
              branch: result.data.branch,
              address: result.data.address,
              account_no: result.data.account_no,
            };
          });
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getUser();
    getFlag();
    // eslint-disable-next-line
  }, []);

  const setFlagHandler = (event) => {
    setNewFlag(event.target.value);
  };
  const [open, setOpen] = React.useState(false);
  const [openAdd, setOpenAdd] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleNewFlagsOpen = () => setOpenAdd(true);
  const handleNewFlagsclose = () => setOpenAdd(false);
  const submitHandler = (event) => {
    event.preventDefault();
    axios
      .post("flag", {
        pan_no: pan,
        remark: newFlag,
      })
      .then((response) => response.data)
      .then((result) => {
        console.log(result);
        getFlag();
      })
      .catch((err) => console.log(err));
    setNewFlag("");
    handleNewFlagsclose();
  };
  const resolveFlag = (Remark, Status) => {
    axios
      .put("flag", {
        pan_no: pan,
        remark: Remark,
        status: Status,
      })
      .then((response) => response.data)
      .then((result) => {
        console.log(result);
        if (result.message === "success") {
          getFlag();
          handleClose();
        }
      })
      .catch((err) => console.log(err));
  };

  const [check, setCheck] = useState(false);

  const handleToggleStatement = () => {
    setCheck(!check);
  };

  if (erpName && erpName !== "fresh-books") {
    return (
      <StockStatement monthPassed={details.month} yearPassed={details.year} />
    );
  }

  return (
    <div className="stock__dashboard">
      <div className="stock__dashboard__sidenav">
        <div className="stock__dashboard__sidenav__img">
          <img src={sync} alt="" />
        </div>
        <ul>
          <li className="active">
            <Link to="/admin/dashboard">
              <a href="admin/dashboard">
                <span className="icon">
                  <GridViewRoundedIcon />
                </span>
                Dashboard
              </a>
            </Link>
          </li>
          <li>
            <Link to="/admin/settings">
              {/* eslint-disable-next-line */}
              <a href="#">
                <span className="icon">
                  <SettingsIcon />
                </span>
                Settings
              </a>
            </Link>
          </li>
          <li>
            <a href="/admin/logout" onClick={(e) => logout(e)}>
              <span className="icon">
                <LogoutIcon />
              </span>
              Logout
            </a>
          </li>
        </ul>
      </div>
      <div className="share__dashboard__topnav">
        <div className="share__dashboard__topnav__header">
          <h1>{organizationName}</h1>
        </div>
        <div className="share__dashboard__topnav__item">
          <a href="/">
            <CircleNotificationsRoundedIcon className="notification_icon" />
          </a>
          <Avatar className="image__avatar" />
        </div>
      </div>
      <div className="share__dashboard__main__maindivision1">
        <div className="share__dashboard__main__subdivision01">
          {/* <div className="share__dashboard__main__subdivision1"> */}
          <div className="btn__settings">
            <Button
              className="login__button"
              style={{ color: "#272E4E", fontWeight: "600", fontSize: "12px" }}
              onClick={handleToggleStatement}
            >
              {check ? "Hide Statement" : "Show Statement"}
            </Button>
          </div>
          {check && (
            <div style={{ marginBottom: "100px" }}>
              {isError && (
                <div className="alert">
                  <Alert variant="filled" severity="error">
                    Stock Statement for this month has not been submitted
                  </Alert>
                </div>
              )}
              <div className="share__table" style={{ marginTop: "20px" }}>
                <table>
                  <tr>
                    <th>Particulars</th>
                    <th>Current Month</th>
                    <th>Previous Month</th>
                    <th>2nd Previous Month</th>
                  </tr>
                  <tr>
                    <td className="share__table__data">
                      <InventoryIcon className="inventory__color" />
                      <span className="table__data__text__special">
                        Inventory
                      </span>
                    </td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td className="share__table__data">
                      <PaymentsIcon className="payable__color" />
                      <span className="stock__table__data__text">Payable</span>
                    </td>
                    <td>
                      {(
                        payable0_30 +
                        payable90 +
                        payable31_60 +
                        payable61_90
                      )?.toLocaleString("hi")}
                    </td>
                    <td>{prevTotalPayable?.toLocaleString("hi")}</td>
                    <td>{prevPrevTotalPayable?.toLocaleString("hi")}</td>
                  </tr>
                  <tr>
                    <td className="share__table__data">
                      <InboxIcon className="recievable__color" />
                      <span className="stock__table__data__text">
                        Recievable
                      </span>
                    </td>
                    <td>
                      {(
                        recievable0_30 +
                        recievable90 +
                        recievable31_60 +
                        recievable61_90
                      )?.toLocaleString("hi")}
                    </td>
                    <td>{prevTotalRecievable?.toLocaleString("hi")}</td>
                    <td>{prevPrevTotalRecievable?.toLocaleString("hi")}</td>
                  </tr>
                  <tr>
                    <td className="share__table__data">
                      <BarChartIcon className="sales__color" />
                      <span className="table__data__text__special">Sales</span>
                    </td>
                    <td>{totalSale?.toLocaleString("hi")}</td>
                    <td>{prevTotalSales?.toLocaleString("hi")}</td>
                    <td>{prevPrevTotalSales?.toLocaleString("hi")}</td>
                  </tr>
                  <tr>
                    <td className="share__table__data">
                      <ApartmentIcon className="purchase__color" />
                      <span className="stock__table__data__text">
                        Purchases
                      </span>
                    </td>
                    <td>{totalPurchases?.toLocaleString("hi")}</td>
                    <td>{prevTotalPurchase?.toLocaleString("hi")}</td>
                    <td>{prevPrevTotalPurchase?.toLocaleString("hi")}</td>
                  </tr>
                  <tr>
                    <td className="share__table__data">Drawing Power</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                </table>
              </div>
            </div>
          )}
          <div className="share__dashboard__main__head1">
            <div className="share__dashboard__main__head1__div">
              <Grid container spacing={1}>
                <Grid item>
                  <FormLabel className="form-label">Month</FormLabel>
                  <FormControl fullWidth>
                    <Select
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={details.month}
                      size="small"
                      name="month"
                      onChange={formValue}
                      className="form__textfield"
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                    >
                      <MenuItem value="01">January</MenuItem>
                      <MenuItem value="02">February</MenuItem>
                      <MenuItem value="03">March</MenuItem>
                      <MenuItem value="04">April</MenuItem>
                      <MenuItem value="05">May</MenuItem>
                      <MenuItem value="06">June</MenuItem>
                      <MenuItem value="07">July</MenuItem>
                      <MenuItem value="08">August</MenuItem>
                      <MenuItem value="09">September</MenuItem>
                      <MenuItem value="10">October</MenuItem>
                      <MenuItem value="11">November</MenuItem>
                      <MenuItem value="12">December</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormLabel className="form-label">Year</FormLabel>
                  <FormControl fullWidth>
                    <Select
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={details.year}
                      name="year"
                      size="small"
                      onChange={formValue}
                      className="form__textfield"
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                    >
                      <MenuItem value={date.getFullYear()}>
                        {date.getFullYear()}
                      </MenuItem>
                      <MenuItem value={date.getFullYear() - 1}>
                        {date.getFullYear() - 1}
                      </MenuItem>
                      <MenuItem value={date.getFullYear() - 2}>
                        {date.getFullYear() - 2}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </div>
            <div>
              <p>(Value in INR)</p>
            </div>
          </div>
          {isError && (
            <div className="alert">
              <Alert variant="filled" severity="error">
                Stock Statement for this month has not been submitted
              </Alert>
            </div>
          )}
          {}
          <div className="share__table">
            <table>
              <tr>
                <th>Particulars</th>
                <th>Total</th>
                <th>0-31 Days</th>
                <th>31-60 Days</th>
                <th>61-90 Days</th>
                <th>Greater than 90</th>
              </tr>
              <tr>
                <td className="stock__table__data">
                  <InventoryIcon className="inventory__color" />
                  <span className="table__data__text__special">Inventory</span>
                </td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
              </tr>
              <tr>
                <td className="stock__table__data">
                  <PaymentsIcon className="payable__color" />
                  <span className="stock__table__data__text">Payable</span>
                  <AddCircleOutlineIcon
                    onClick={() => {
                      setDisplayinventory(!displayinventory);
                    }}
                    className="payable__add__icon"
                  />
                </td>
                <td>
                  {(
                    payable0_30 +
                    payable31_60 +
                    payable61_90 +
                    payable90
                  ).toLocaleString("hi")}
                </td>
                <td>{payable0_30.toLocaleString("hi")}</td>
                <td>{payable31_60.toLocaleString("hi")}</td>
                <td>{payable61_90.toLocaleString("hi")}</td>
                <td>{payable90.toLocaleString("hi")}</td>
              </tr>
              {displayinventory && (
                <>
                  <tr>
                    <th>Name</th>
                    <th>Age</th>
                    <th>Value</th>
                    <th></th>
                    <th></th>
                  </tr>
                  {payExtraInfo?.map((data) => {
                    return (
                      <tr>
                        <td>{data.creditor_name}</td>
                        <td>{data.age ? data.age : "Not Available"}</td>
                        <td>{parseFloat(data.value).toLocaleString("hi")}</td>
                        <td></td>
                        <td></td>
                      </tr>
                    );
                  })}
                </>
              )}
              <tr>
                <td className="stock__table__data">
                  <InboxIcon className="recievable__color" />
                  <span className="stock__table__data__text">Recievable</span>
                  <AddCircleOutlineIcon
                    onClick={() => setDisplayRecievable(!displayRecievable)}
                  />
                </td>
                <td>
                  {(
                    recievable0_30 +
                    recievable31_60 +
                    recievable61_90 +
                    recievable90
                  )?.toLocaleString("hi")}
                </td>
                <td>{recievable0_30?.toLocaleString("hi")}</td>
                <td>{recievable31_60?.toLocaleString("hi")}</td>
                <td>{recievable61_90?.toLocaleString("hi")}</td>
                <td>{recievable90?.toLocaleString("hi")}</td>
              </tr>
              {displayRecievable && (
                <>
                  <tr>
                    <th>Name</th>
                    <th>Age</th>
                    <th>Value</th>
                    <th></th>
                    <th></th>
                  </tr>
                  {invExtraInfo?.map((data) => {
                    return (
                      <tr>
                        <td>{data.debtor_name}</td>
                        <td>{data.age ? data.age : "Not Available"}</td>
                        <td>{parseFloat(data.value).toLocaleString("hi")}</td>
                        <td></td>
                        <td></td>
                      </tr>
                    );
                  })}
                </>
              )}
            </table>
            {/* <CircularProgress /> */}
            <div className="main__2">
              <div className="share__table__2">
                <table>
                  <tr>
                    <th></th>
                    <th>Current Month</th>
                    <th>Current FY</th>
                  </tr>
                  <tr>
                    <td className="share__table__data">
                      <BarChartIcon className="sales__color" />
                      <span className="table__data__text__special">Sales</span>
                    </td>
                    <td>{totalSale?.toLocaleString("hi")}</td>
                    <td>{totalSaleFy?.toLocaleString("hi")}</td>
                  </tr>
                  <tr>
                    <td className="share__table__data">
                      <ApartmentIcon className="purchase__color" />
                      <span className="share__table__data__text">
                        Purchases
                      </span>
                    </td>
                    <td>{totalPurchases?.toLocaleString("hi")}</td>
                    <td>{totalPurchasesFy?.toLocaleString("hi")}</td>
                  </tr>
                </table>
              </div>
              <div className="drawing">
                <h3>Drawing Power</h3>
                <p>0</p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="declaration_div">
            <p>{organizationName}</p>
            <div className="declaration__div__form__button1">
              <Link to={`/admin/organization/${pan}`} className="ha__div">
                <a href="/admin/organization" className="ha">
                  Overview
                </a>
              </Link>
            </div>
            <div className="declaration__div__form">
              <p>Flags</p>
            </div>
            <div className="declaration__div__form1">
              <p>{flagNo}</p>
            </div>
            <div className="form__buttons">
              <div
                className="declaration__div__form__button2"
                style={{ float: "left" }}
              >
                <Button onClick={handleOpen}>View</Button>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      style={{ borderBottom: "1px solid gray" }}
                    >
                      Flags
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      <table className="share__table1">
                        {FlagObject.map((e) => {
                          return (
                            <tr
                              style={{
                                borderBottom: "1px solid #d3d3d3",
                                textAlign: "left",
                                display: "block",
                              }}
                            >
                              {/* flag_remark: "Dummy" flag_status: false
                              month_year: "2022-09-02" */}
                              <td style={{ display: "inline-block" }}>
                                {e.flag_remark},
                                {e.flag_status ? "false" : "true"},
                                {e.month_year}
                                <Button
                                  className="resolve__button"
                                  onClick={() =>
                                    resolveFlag(e.flag_remark, e.flag_status)
                                  }
                                >
                                  Resolve
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                      </table>
                    </Typography>
                  </Box>
                </Modal>
              </div>
              <div
                className="declaration__div__form__button2"
                style={{ float: "right" }}
              >
                <Button onClick={handleNewFlagsOpen}>Add Flags</Button>
                <Modal
                  open={openAdd}
                  onClose={handleNewFlagsclose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      style={{ borderBottom: "1px solid gray" }}
                    >
                      Add Flag
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      <form action="" onSubmit={submitHandler}>
                        <Grid container item spacing={2}>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              id="outlined-multiline-flexible"
                              variant="outlined"
                              name="currpass"
                              placeholder="Add a New Flag"
                              multiline
                              maxRows={4}
                              value={newFlag}
                              onChange={setFlagHandler}
                              className="form__textfixeld"
                            />
                          </Grid>
                          <Grid item xs={6}></Grid>
                          <Grid item sm={3}>
                            <Button
                              // onClick={(e) => handleForm(e)}
                              type="submit"
                              className="button"
                              fullWidth
                              variant="contained"
                            >
                              Add
                            </Button>
                          </Grid>
                          <Grid item sm={2}>
                            <Button
                              // className="button"
                              fullWidth
                              variant="outlined"
                              onClick={handleNewFlagsclose}
                            >
                              Cancel
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    </Typography>
                  </Box>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FreshbookStatement;
