import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useGlobalContext } from "./context";
import {
  Avatar,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  FormControl,
  Alert,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import CircleNotificationsRoundedIcon from "@mui/icons-material/CircleNotificationsRounded";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import LogoutIcon from "@mui/icons-material/Logout";
import InventoryIcon from "@mui/icons-material/Inventory";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import PaymentsIcon from "@mui/icons-material/Payments";
import BarChartIcon from "@mui/icons-material/BarChart";
import ApartmentIcon from "@mui/icons-material/Apartment";
import InboxIcon from "@mui/icons-material/Inbox";
import LoginIcon from "@mui/icons-material/Login";
import sync from "./static/Capture.png";
import "./css/viewstock.css";
import axios from "./axiosBase";
import "./axiosInterceptors";
import { clearData, getRefreshToken } from "./handleToken";
import ViewFreshbooksStock from "./ViewFreshbooksStock";

const date = new Date();
function PastStockSatetement({ monthPassed, yearPassed }) {
  const [isError, setIsError] = useState(false);
  const [totalPurchases, setTotalPurchases] = useState(0);
  const [totalSale, setTotalSale] = useState(0);
  const [totalPurchasesFy, setTotalPurchasesFy] = useState(0);
  const [totalSaleFy, setTotalSaleFy] = useState(0);
  // const [payableTotal, setPayableTotal] = useState(0);
  const [payable90, setPayable90] = useState(0);
  const [payable90_180, setPayable90_180] = useState(0);
  const [payable180, setPayable180] = useState(0);
  // const [recievableTotal, setRecievableTotal] = useState(0);
  const [recievable90, setRecievable90] = useState(0);
  const [recievable90_180, setRecievable90_180] = useState(0);
  const [recievable180, setRecievable180] = useState(0);
  const [inventory90, setInventory90] = useState(0);
  const [inventory90_180, setInventory90_180] = useState(0);
  const [payExtraInfo, setpayExtraInfo] = useState([]);
  const [inventory180, setInventory180] = useState(0);
  const [invExtraInfo, setInvExtraInfo] = useState([]);
  const [displayinventory, setDisplayinventory] = useState(false);
  const [displayRecievable, setDisplayRecievable] = useState(false);
  const { authenticate, setAuthenticate, isAdmin } = useGlobalContext();
  const [isErrorDetails, setisErrorDetails] = useState(
    "Stock Statement for this month has not been submitted"
  );
  const [erpName, setErpName] = useState("");

  // const history = useHistory();
  // if (authenticate === false) {
  //   history("/login");
  // }
  const [details, setDetails] = useState({
    month: monthPassed ? monthPassed : "",
    year: yearPassed ? yearPassed : "",
  });
  const history = useNavigate();
  if (authenticate === false) {
    history("/login");
  }
  if (isAdmin === true) {
    history("/admin/dashboard");
  }
  const logout = (e) => {
    e.preventDefault();
    const token = getRefreshToken();
    axios.post("api/token/blacklist/", { refresh: token }).then((response) => {
      clearData();
      setAuthenticate(false);
      history("/login");
    });
  };
  const getStockData = async () => {
    axios
      .get("stock-statement", {
        params: { year: details.year, month: details.month },
      })
      .then((response) => response.data)
      .then((result) => {
        if (result.message === "error") {
          setIsError(true);
          return result.message;
        }
        console.log(result.data.data.erp_name);
        setIsError(false);
        return result.data.data.erp_name;
      })
      .then((result) => {
        if (result === "error") {
          return console.log("error");
        }
        if (result === "fresh-books") {
          setErpName(result);
        }
        getData();
      });
  };
  const getData = () => {
    axios
      .get("inventory", {
        params: {
          year: details.year,
          month: details.month,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        setInventory90(data["inventory"][0]["less_than_90"]);
        setInventory90_180(data["inventory"][0]["_90_to_180"]);
        setInventory180(data["inventory"][0]["greater_than_180"]);
      })
      .catch((err) => console.log(err));
    axios
      .get("recievable", {
        params: {
          year: details.year,
          month: details.month,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        console.log(data);
        setInvExtraInfo(data.recievable);
      })
      .catch((err) => console.log(err));
    axios
      .get("recievable-info", {
        params: {
          year: details.year,
          month: details.month,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        setRecievable180(data.recievable[0]["greater_than_180"]);
        setRecievable90(data.recievable[0]["less_than_90"]);
        setRecievable90_180(data.recievable[0]["_90_to_180"]);
      })
      .catch((err) => console.log(err));
    axios
      .get("payable", {
        params: {
          year: details.year,
          month: details.month,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        setPayable180(data.data[0]["greater_than_180"]);
        setPayable90(data.data[0]["less_than_90"]);
        setPayable90_180(data.data[0]["_90_to_180"]);
      })
      .catch((err) => console.log(err));
    axios
      .get("payable-info", {
        params: {
          year: details.year,
          month: details.month,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        console.log(data);
        setpayExtraInfo(data.data);
      })
      .catch((err) => console.log(err));
    axios
      .get("purchases", {
        params: {
          year: details.year,
          month: details.month,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        console.log(data);
        setTotalPurchases(data.data[0]["purchase_value"]);
        setTotalPurchasesFy(data.data[0]["purchases_cumulative_fy"]);
      })
      .catch((err) => console.log(err));
    axios
      .get("sales", {
        params: {
          year: details.year,
          month: details.month,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        console.log(data);
        setTotalSale(data.data[0]["sales_value"]);
        setTotalSaleFy(data.data[0]["sale_cummulative_fy"]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const formValue = (event) => {
    setDetails((oldValue) => {
      return { ...oldValue, [event.target.name]: event.target.value };
    });
  };
  useEffect(() => {
    if (details.month && details.year) {
      setPayable180(0);
      setPayable90(0);
      setInventory180(0);
      setPayable90_180(0);
      setInventory90(0);
      setInventory90_180(0);
      setRecievable180(0);
      setRecievable90(0);
      setRecievable90_180(0);
      setTotalPurchases(0);
      setTotalPurchasesFy(0);
      setTotalSale(0);
      setTotalSaleFy(0);
      setInvExtraInfo([]);
      setpayExtraInfo([]);
      getStockData();
    }
    // eslint-disable-next-line
  }, [details]);
  if (erpName === "fresh-books") {
    return (
      <ViewFreshbooksStock
        monthPassed={details.month}
        yearPassed={details.year}
      />
    );
  }
  return (
    <div className="stock__dashboard">
      <div className="stock__dashboard__sidenav">
        <div className="stock__dashboard__sidenav__img_past">
          <img src={sync} alt="" />
        </div>
        <ul>
          <li className="active-dash">
            <Link to="/dashboard">
              {/* eslint-disable-next-line */}
              <a href="#">
                <span className="icon">
                  <GridViewRoundedIcon />
                </span>
                Dashboard
              </a>
            </Link>
          </li>
          <li>
            <Link to="/my-organization">
              {/* eslint-disable-next-line */}
              <a href="#">
                <span className="icon">
                  <HomeRoundedIcon />
                </span>
                My Organization
              </a>
            </Link>
          </li>
          <li>
            <Link to="/settings">
              {/* eslint-disable-next-line */}
              <a href="#">
                <span className="icon">
                  <LoginIcon />
                </span>
                Login Settings
              </a>
            </Link>
          </li>
          <li>
            <Link to="/erp-systems">
              {/* eslint-disable-next-line */}
              <a href="#">
                <span className="icon">
                  <SettingsIcon />
                </span>
                Erp Settings
              </a>
            </Link>
          </li>
          <li>
            <a href="/logout" onClick={(e) => logout(e)}>
              <span className="icon">
                <LogoutIcon />
              </span>
              Logout
            </a>
          </li>
        </ul>
      </div>
      <div className="stock__dashboard__topnav">
        <div className="stock__dashboard__topnav__header">
          <h1>Dashboard</h1>
        </div>
        <div className="stock__dashboard__topnav__item">
          <a href="/">
            <CircleNotificationsRoundedIcon className="notification_icon" />
          </a>
          <Avatar className="image__avatar" />
        </div>
      </div>
      <div className="stock__dashboard__main__subdivision_past">
        <div className="stock__dashboard__main__head">
          <div className="stock__dashboard__main__head__div">
            <Grid container spacing={1}>
              <Grid item>
                <FormLabel className="form-label">Month</FormLabel>
                <FormControl fullWidth>
                  <Select
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={details.month}
                    size="small"
                    name="month"
                    onChange={formValue}
                    className="form__textfield"
                    sx={{
                      "& fieldset": { border: "none" },
                    }}
                  >
                    <MenuItem value="01">January</MenuItem>
                    <MenuItem value="02">February</MenuItem>
                    <MenuItem value="03">March</MenuItem>
                    <MenuItem value="04">April</MenuItem>
                    <MenuItem value="05">May</MenuItem>
                    <MenuItem value="06">June</MenuItem>
                    <MenuItem value="07">July</MenuItem>
                    <MenuItem value="08">August</MenuItem>
                    <MenuItem value="09">September</MenuItem>
                    <MenuItem value="10">October</MenuItem>
                    <MenuItem value="11">November</MenuItem>
                    <MenuItem value="12">December</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <FormLabel className="form-label">Year</FormLabel>
                <FormControl fullWidth>
                  <Select
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={details.year}
                    name="year"
                    size="small"
                    onChange={formValue}
                    className="form__textfield"
                    sx={{
                      "& fieldset": { border: "none" },
                    }}
                  >
                    <MenuItem value={date.getFullYear()}>
                      {date.getFullYear()}
                    </MenuItem>
                    <MenuItem value={date.getFullYear() - 1}>
                      {date.getFullYear() - 1}
                    </MenuItem>
                    <MenuItem value={date.getFullYear() - 2}>
                      {date.getFullYear() - 2}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>
          <div>
            <p>(Value in INR)</p>
          </div>
        </div>
        {isError && (
          <div className="alert">
            <Alert variant="filled" severity="error">
              {isErrorDetails}
            </Alert>
          </div>
        )}
        <div className="stock__table">
          <table>
            <tr>
              <th>Particulars</th>
              <th>Total</th>
              <th>0-90 Days</th>
              <th>90-180 Days</th>
              <th>Greater than 180</th>
            </tr>
            <tr>
              <td className="stock__table__data">
                <InventoryIcon className="inventory__color" />
                <span className="table__data__text__special">Inventory</span>
              </td>
              <td>
                {parseInt(
                  inventory90 + inventory90_180 + inventory180
                )?.toLocaleString("hi")}
              </td>
              <td>{parseInt(inventory90)?.toLocaleString("hi")}</td>
              <td>{parseInt(inventory90_180)?.toLocaleString("hi")}</td>
              <td>{parseInt(inventory180)?.toLocaleString("hi")}</td>
            </tr>
            <tr>
              <td className="stock__table__data">
                <PaymentsIcon className="payable__color" />
                <span className="stock__table__data__text">Payable</span>
                <AddCircleOutlineIcon
                  onClick={() => {
                    setDisplayinventory(!displayinventory);
                  }}
                  className="payable__add__icon"
                />
              </td>
              <td>
                {parseInt(
                  payable180 + payable90 + payable90_180
                )?.toLocaleString("hi")}
              </td>
              <td>{parseInt(payable90)?.toLocaleString("hi")}</td>
              <td>{parseInt(payable90_180)?.toLocaleString("hi")}</td>
              <td>{parseInt(payable180)?.toLocaleString("hi")}</td>
            </tr>
            {displayinventory && (
              <>
                <tr>
                  <th>Name</th>
                  <th>Age</th>
                  <th>Value</th>
                  <th></th>
                  <th></th>
                </tr>
                {payExtraInfo?.map((data) => {
                  return (
                    <tr>
                      <td>{data.creditor_name}</td>
                      <td>{data.age ? data.age : "Not Available"}</td>
                      <td>{parseInt(data.value).toLocaleString("hi")}</td>
                      <td></td>
                      <td></td>
                    </tr>
                  );
                })}
              </>
            )}
            <tr>
              <td className="stock__table__data">
                <InboxIcon className="recievable__color" />
                <span className="stock__table__data__text">Recievable</span>
                <AddCircleOutlineIcon
                  onClick={() => setDisplayRecievable(!displayRecievable)}
                />
              </td>
              <td>
                {parseInt(
                  recievable180 + recievable90 + recievable90_180
                )?.toLocaleString("hi")}
              </td>
              <td>{parseInt(recievable90)?.toLocaleString("hi")}</td>
              <td>{parseInt(recievable90_180)?.toLocaleString("hi")}</td>
              <td>{parseInt(recievable180)?.toLocaleString("hi")}</td>
            </tr>
            {displayRecievable && (
              <>
                <tr>
                  <th>Name</th>
                  <th>Age</th>
                  <th>Value</th>
                  <th></th>
                  <th></th>
                </tr>
                {invExtraInfo?.map((data) => {
                  return (
                    <tr>
                      <td>{data.debtor_name}</td>
                      <td>{data.age ? data.age : "Not Available"}</td>
                      <td>{parseInt(data.value).toLocaleString("hi")}</td>
                      <td></td>
                      <td></td>
                    </tr>
                  );
                })}
              </>
            )}
          </table>
          {/* <CircularProgress /> */}
          <div className="stock__table__2">
            <table>
              <tr>
                <th></th>
                <th>Current Month</th>
                <th>Current FY</th>
              </tr>
              <tr>
                <td className="stock__table__data">
                  <BarChartIcon className="sales__color" />
                  <span className="table__data__text__special">Sales</span>
                </td>
                <td>{parseInt(totalSale)?.toLocaleString("hi")}</td>
                <td>{parseInt(totalSaleFy)?.toLocaleString("hi")}</td>
              </tr>
              <tr>
                <td className="stock__table__data">
                  <ApartmentIcon className="purchase__color" />
                  <span className="stock__table__data__text">Purchases</span>
                </td>
                <td>{parseInt(totalPurchases)?.toLocaleString("hi")}</td>
                <td>{parseInt(totalPurchasesFy)?.toLocaleString("hi")}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PastStockSatetement;
