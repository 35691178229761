import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./SyncBankerLogin.css";
import { useGlobalContext } from "../context";
import Capture from ".././static/Capture.png";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormLabel,
  Grid,
  Link,
  TextField,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import Splash from "../Splash";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import axios from "../axiosBase";
import "../axiosInterceptors";
import { setAccessRefreshToken } from "../handleToken";

function SyncBankerLogin() {
  const {
    setAuthenticate,
    setUsername,
    setisAdmin,
    authenticate,
    networkError,
    isAdmin,
  } = useGlobalContext();
  const history = useNavigate();

  const [isError, setIsError] = useState(false);
  const [isErrorDetails, setIsErrorDetails] = useState("An Error occured");
  const [loading, setLoading] = useState(true);
  const [appLoading, setAppLoading] = useState(false);
  const [details, setDetails] = useState({
    username: "",
    password: "",
  });
  const formValue = (e) => {
    setDetails((prevData) => {
      return { ...prevData, [e.target.name]: [e.target.value] };
    });
  };
  console.log(authenticate);
  useEffect(() => {
    if (authenticate === false) {
      setLoading(false);
    }
    if (authenticate === true && isAdmin === true) {
      window.location.replace("/admin/dashboard");
    }

    if (authenticate === true && isAdmin === false) {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [authenticate, isAdmin]);
  useEffect(() => {
    if (networkError) {
      setLoading(false);
      setIsError(true);
      setIsErrorDetails("Internet Connection Error");
    }
  }, [networkError]);
  const handleForm = (event) => {
    event.preventDefault();
    setAppLoading(true);
    console.log(details.username[0]);
    axios
      .post("login-view", {
        email: details.username[0],
        password: details.password[0],
      })
      .then((response) => response.data)
      .then((data) => {
        setAppLoading(false);
        if (data.message === "success") {
          console.log(data);
          if (data.is_admin) {
            console.log("admin");
            setisAdmin(true);
            setAuthenticate(true);
            history("/admin/dashboard");
            setUsername(details.username[0]);
            setAccessRefreshToken(data.data.access, data.data.refresh);
          } else {
            console.log("not admin");
            localStorage.clear();
          }
        } else {
          console.log(data.data);
          setIsError(true);
          setIsErrorDetails(data.data);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    setInterval(() => {
      if (isError) {
        setIsError(false);
      }
    }, 10000);
  }, [isError]);
  if (loading === true) {
    return <Splash />;
  }
  return (
    <div className="SyncBankerLogin_SyncBankerLogin">
      {/* <img className="Vector" src={ImgAsset.SyncBankerLogin_Vector} /> */}
      <div className="AddDocumentPopup">
        <div className="cardheader"></div>
        <div className="Frame420"></div>
        {isError && (
          <div className="alert-login">
            <Alert variant="filled" severity="error" className="alert-message">
              {isErrorDetails}
            </Alert>
          </div>
        )}
        <span className="Login">Login </span>
        <form action="" className="form">
          <Grid container item spacing={2}>
            <Grid item sm={12}>
              <FormLabel className="form-label">User ID</FormLabel>
              <TextField
                fullWidth
                hiddenLabel
                id="oulined-basic"
                variant="outlined"
                name="username"
                placeholder="Enter Your User ID"
                value={details.username}
                onChange={formValue}
                sx={{
                  "& fieldset": { border: "none" },
                }}
                size="small"
                className="form__textfield"
              />
            </Grid>
            <Grid item sm={12}>
              <FormLabel className="form-label">Password</FormLabel>
              <TextField
                fullWidth
                id="oulined-basic"
                type="password"
                variant="outlined"
                placeholder="Enter Your Password"
                name="password"
                value={details.password}
                onChange={formValue}
                sx={{
                  "& fieldset": { border: "none" },
                }}
                size="small"
                className="form__textfield"
              />
            </Grid>
            <div className="form__text">
              <FormControlLabel
                className="form__text_checkbox"
                control={
                  <Checkbox
                    defaultChecked
                    sx={{ "&.Mui-checked": { color: "#002956" } }}
                  />
                }
                label="Remember Me"
              />
            </div>
            <Grid item xs={12}>
              {appLoading === false ? (
                <Button
                  className="button"
                  type="submit"
                  onClick={(e) => handleForm(e)}
                  fullWidth
                  variant="contained"
                >
                  Login <ArrowRightAltIcon />
                </Button>
              ) : (
                <Button
                  className="button"
                  type="submit"
                  onClick={(e) => handleForm(e)}
                  fullWidth
                  variant="contained"
                >
                  Login <CircularProgress size={20} color="inherit" />
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </div>
      <div className="AddDocumentPopup_1">
        <div className="cardheader_1"></div>
        <div className="Frame420_1"></div>
        <img className="Capture1" src={Capture} alt="Capture" />
        <span className="Bank">Admin</span>
      </div>
    </div>
  );
}
export default SyncBankerLogin;
