import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./css/SyncLogin.css";
import { useGlobalContext } from "./context";
import Capture from "./static/Capture.png";
import CaptureDot1 from "./bankerComponents/public/SyncBankerLogin_dot_1.png";
import CaptureDot2 from "./bankerComponents/public/SyncBankerLogin_dot_3.png";
import CaptureDot3 from "./bankerComponents/public/SyncBankerLogin_dot_5.png";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormLabel,
  Grid,
  Link,
  TextField,
} from "@mui/material";
import Splash from "./Splash";
import Alert from "@mui/material/Alert";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import axios from "./axiosBase";
import "./axiosInterceptors";
import "./css/login.css";
import { setAccessRefreshToken } from "./handleToken";

export default function SyncBankerLogin() {
  const { authenticate, setAuthenticate, setUsername, networkError } =
    useGlobalContext();
  const history = useNavigate();
  const [isError, setIsError] = useState(false);
  const [appLoading, setappLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isErrorDetails, setIsErrorDetails] = useState("Invalid Login Details");
  const [details, setDetails] = useState({
    username: "",
    password: "",
  });
  if (authenticate === true) {
    history("/dashboard");
  }
  useEffect(() => {
    if (authenticate === false) {
      setLoading(false);
    }
  }, [authenticate]);
  useEffect(() => {
    if (networkError) {
      setLoading(false);
      setIsError(true);
      setIsErrorDetails("Internet Connection Error");
    }
  }, [networkError]);
  const formValue = (e) => {
    setDetails((prevData) => {
      return { ...prevData, [e.target.name]: [e.target.value] };
    });
  };
  const handleForm = (event) => {
    event.preventDefault();
    setappLoading(true);
    setIsError(false);
    console.log(details.username[0]);
    axios
      .post("login-view", {
        email: details.username[0],
        password: details.password[0],
      })
      .then((response) => response.data)
      .then((data) => {
        setappLoading(false);
        if (data.message === "success") {
          setAuthenticate(true);
          console.log(data.data);
          setUsername(details.username[0]);
          setAccessRefreshToken(data.data.access, data.data.refresh);
          history("/dashboard");
        } else {
          if (
            data.data ===
            "Please activate, verification link has been sent to email"
          ) {
            setappLoading(false);
            setIsError(true);
            setIsErrorDetails(data.data);
          } else {
            console.log(data.data);
            setappLoading(false);
            setIsError(true);
            setIsErrorDetails(data.data);
          }
        }
      })
      .catch((err) => {
        setappLoading(false);
        setIsError(true);
        setIsErrorDetails("Issue with Network Try again");
      });
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (isError) {
        setIsError(false);
      }
    }, 10000);
    return clearInterval(interval);
  }, [isError]);
  if (loading === true) {
    return <Splash />;
  }
  return (
    <div className="LoginMainBody">
      <div className="SyncBankerLogin_SyncBankerLogin_1">
        <div className="AddDocumentPopup1">
          {isError && (
            <div className="alert-login">
              <Alert
                variant="filled"
                severity="error"
                className="alert-message"
              >
                {isErrorDetails}
              </Alert>
            </div>
          )}
          <span className="Login">Login </span>
          <form action="" className="form">
            <Grid container item spacing={2}>
              <Grid item sm={12}>
                <FormLabel className="form-label_">User ID</FormLabel>
                <TextField
                  fullWidth
                  hiddenLabel
                  id="oulined-basic"
                  variant="outlined"
                  name="username"
                  placeholder="Enter Your User ID"
                  value={details.username}
                  onChange={formValue}
                  sx={{
                    "& fieldset": { border: "none" },
                  }}
                  size="small"
                  className="form__textfield"
                />
              </Grid>
              <Grid item sm={12}>
                <FormLabel className="form-label_">Password</FormLabel>
                <TextField
                  fullWidth
                  id="oulined-basic"
                  type="password"
                  variant="outlined"
                  placeholder="Enter Your Password"
                  name="password"
                  value={details.password}
                  onChange={formValue}
                  sx={{
                    "& fieldset": { border: "none" },
                  }}
                  size="small"
                  className="form__textfield"
                />
              </Grid>
              <div className="form__text">
                <FormControlLabel
                  className="form__text_checkbox"
                  control={
                    <Checkbox
                      defaultChecked
                      sx={{ "&.Mui-checked": { color: "#30BFC3" } }}
                    />
                  }
                  label="Remember Me"
                />
                <Link
                  href="/reset-password"
                  className="login__class"
                  color="#30BFC3"
                >
                  Forgot Password?
                </Link>
              </div>
              <Grid item xs={12}>
                {appLoading === false ? (
                  <Button
                    className="button"
                    type="submit"
                    onClick={(e) => handleForm(e)}
                    fullWidth
                    variant="contained"
                  >
                    Login <ArrowRightAltIcon />
                  </Button>
                ) : (
                  <Button
                    className="button"
                    type="submit"
                    onClick={(e) => handleForm(e)}
                    fullWidth
                    variant="contained"
                  >
                    Login <CircularProgress size={20} color="inherit" />
                  </Button>
                )}
              </Grid>
              <Grid item className="register__div">
                <p className="register__p">
                  Don't have an account?
                  <Link href="/register" className="register" color="#5048E5">
                    Register
                  </Link>
                </p>
              </Grid>
            </Grid>
          </form>
        </div>
        <div className="AddDocumentPopup_1_1">
          <img className="Capture1" src={Capture} alt="Capture" />
        </div>
      </div>
    </div>
  );
}
