import React from "react";
import "./css/ErpSystems.css";
import sync from "./static/Capture.png";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import { useGlobalContext } from "./context";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import CircleNotificationsRoundedIcon from "@mui/icons-material/CircleNotificationsRounded";
import { Avatar } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import LoginIcon from "@mui/icons-material/Login";
import { clearData, getRefreshToken } from "./handleToken";
import axios from "./axiosBase";
import TallyPrime from "./static/TallyPrime.png";
import TallyErp from "./static/TallyERP.png";
import Zoho from "./static/Zoho.png";
import SAP from "./static/SAP.jpeg";
import Oracle from "./static/Oracle.jpeg";
import SAGE from "./static/SAGE.jpeg";
import FreshBooks from "./static/Freshbooks.png";
import Dynamics from "./static/dynamics.png";
import { useState } from "react";
import { useEffect } from "react";

const products = [
  { id: 1, image: { TallyPrime }, name: "tally-prime" },
  { id: 2, image: { TallyErp }, name: "tally-erp9" },
  { id: 3, image: { Zoho }, name: "zoho-books" },
];

const products1 = [
  { id: 4, image: { FreshBooks }, name: "fresh-books" },
  { id: 5, image: { Dynamics }, name: "business-central" },
  { id: 6, image: { SAGE }, name: "SAGE" },
];

const products2 = [
  { id: 7, image: { Oracle }, name: "Oracle" },
  { id: 8, image: { SAP }, name: "SAP" },
];

function ErpSystems() {
  const [erpName, setErpName] = useState("");
  const { username, setAuthenticate, authenticate, isAdmin } =
    useGlobalContext();
  const history = useNavigate();
  const date = new Date();
  if (authenticate === false) {
    history("/login");
  }
  if (isAdmin === true) {
    history("/admin/dashboard");
  }
  console.log(username);
  const logout = (e) => {
    e.preventDefault();
    const token = getRefreshToken();
    axios.post("api/token/blacklist/", { refresh: token }).then((response) => {
      clearData();
      setAuthenticate(false);
      history("/login");
    });
  };
  const getErpName = () => {
    axios
      .get("erp-settings", {
        params: {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        console.log(data);
        if (data.message === "success") {
          setErpName(data.data.erp_name);
        } else {
          setErpName(null);
        }
      });
  };
  useEffect(() => {
    getErpName();
  }, []);
  const getProduct = (name) => {
    if (name === "zoho-books") {
      window.open(
        `https://accounts.zoho.in/oauth/v2/auth?scope=ZohoBooks.fullaccess.all&client_id=1000.3AO5JS5334W8WUJQOKCC3E21LDSJ2K&response_type=code&redirect_uri=https://syncapi-d5gshqbmfyepg7hu.centralindia-01.azurewebsites.net/sync/redirect&access_type=offline&state=${username}&prompt=consent`
      );
    } else if (name === "tally-prime") {
      history("/TallyErp/tally-prime");
    } else if (name === "tally-erp9") {
      history("/TallyErp/tally-erp9");
    } else if (name === "fresh-books") {
      window.open(
        `https://auth.freshbooks.com/oauth/authorize?client_id=71d1042034f3a21a72cf323cf396d262d6459378a276832838ec470ebe87933f&response_type=code&redirect_uri=https%3A%2F%2Fsync-backend.azurewebsites.net%2Fsync%2Fredirect%2Ffresh-books&scope=user%3Aprofile%3Aread%20user%3Areports%3Aread&state=${username}`
      );
    } else if (name === "business-central") {
      window.open(
        `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?response_type=code&client_id=2775cdc9-6645-41d6-bc34-296a2783e188&redirect_uri=https://sync-backend.azurewebsites.net/sync/redirect/microsoft&state=${username}&prompt=consent&scope=openid offline_access https://api.businesscentral.dynamics.com/.default`
      );
    }
  };
  return (
    <div className="dashboard">
      <div className="dashboard__sidenav">
        <div className="dashboard__sidenav__img_main">
          <img src={sync} alt="" />
        </div>
        <ul>
          <li>
            <Link to="/dashboard">
              {/* eslint-disable-next-line */}
              <a href="#">
                <span className="icon">
                  <GridViewRoundedIcon />
                </span>
                Dashboard
              </a>
            </Link>
          </li>
          <li>
            <Link to="/my-organization">
              {/* eslint-disable-next-line */}
              <a href="#">
                <span className="icon">
                  <HomeRoundedIcon />
                </span>
                My Organization
              </a>
            </Link>
          </li>
          <li>
            <Link to="/settings">
              {/* eslint-disable-next-line */}
              <a href="#">
                <span className="icon">
                  <LoginIcon />
                </span>
                Login Settings
              </a>
            </Link>
          </li>
          <li className="active">
            <Link to="/erp-systems">
              {/* eslint-disable-next-line */}
              <a href="#">
                <span className="icon">
                  <SettingsIcon />
                </span>
                Erp Settings
              </a>
            </Link>
          </li>
          <li>
            <a href="/logout" onClick={(e) => logout(e)}>
              <span className="icon">
                <LogoutIcon />
              </span>
              Logout
            </a>
          </li>
        </ul>
      </div>
      <div className="dashboard__topnav">
        <div className="dashboard__topnav__header">
          <h1>Connect ERP System</h1>
        </div>
        <div className="dashboard__topnav__item">
          <a href="/">
            <CircleNotificationsRoundedIcon className="notification_icon" />
          </a>
          <Avatar className="image__avatar" />
        </div>
      </div>
      <div className="dashboard__main">
        {products.map((product) => (
          <div
            className={`dashboard__main__div_erp ${
              erpName && erpName === product.name && "active-erp"
            }`}
            key={product.id}
          >
            <div className="center">
              {/* <img
                class={`product ${product.name}`}
                src={}
                alt={product.name}
                height="100px"
              /> */}
              {product.name === "zoho-books" && (
                <>
                  <img
                    class={`product ${product.name}`}
                    src={product.image.Zoho}
                    alt={product.name}
                    height="100px"
                  />
                  <div className="link_erp">
                    {/* eslint-disable-next-line */}
                    <a href="#" onClick={() => getProduct(product.name)}>
                      Connect
                    </a>
                  </div>
                </>
              )}
              {product.name === "tally-erp9" && (
                <>
                  <img
                    class={`product ${product.name}`}
                    src={product.image.TallyErp}
                    alt={product.name}
                    height="100px"
                  />
                  <div className="link_erp">
                    {/* eslint-disable-next-line */}
                    <a href="#" onClick={() => getProduct(product.name)}>
                      Connect
                    </a>
                  </div>
                </>
              )}
              {product.name === "tally-prime" && (
                <>
                  <img
                    class={`product ${product.name}`}
                    src={product.image.TallyPrime}
                    alt={product.name}
                    height="100px"
                  />
                  <div className="link_erp">
                    {/* eslint-disable-next-line */}
                    <a href="#" onClick={() => getProduct(product.name)}>
                      Connect
                    </a>
                  </div>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="dashboard__main">
        {products1.map((product) => (
          <div className="dashboard__main__div" key={product.id}>
            <div className="center">
              {product.name === "fresh-books" && (
                <>
                  <img
                    className={`product ${product.name}`}
                    src={product.image.FreshBooks}
                    alt={product.name}
                    height="100px"
                    style={{ filter: "grayscale(100%)" }}
                  />
                  {/* <div className="link_erp">
                    <a href="#" onClick={() => getProduct(product.name)}>
                      Connect
                    </a>
                  </div> */}
                </>
              )}
              {product.name === "business-central" && (
                <>
                  <img
                    className={`product ${product.name}`}
                    src={product.image.Dynamics}
                    alt={product.name}
                    height="60px"
                    style={{ paddingBottom: "40px", filter: "grayscale(100%)" }}
                  />
                  {/* <div className="link_erp">
                    <a href="#" onClick={() => getProduct(product.name)}>
                      Connect
                    </a>
                  </div> */}
                </>
              )}
              {product.name === "SAGE" && (
                <>
                  <img
                    className={`product ${product.name}`}
                    src={product.image.SAGE}
                    alt={product.name}
                    height="100px"
                  />
                </>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="dashboard__main">
        {products2.map((product) => (
          <div className="dashboard__main__div" key={product.id}>
            <div className="center">
              {product.name === "Oracle" && (
                <img
                  className={`product ${product.name}`}
                  src={product.image.Oracle}
                  alt={product.name}
                  height="100px"
                  style={{ paddingBottom: "50px" }}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ErpSystems;
