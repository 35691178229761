import React, { useState } from "react";
import "./css/SyncLogin.css";
import Capture from "./static/Capture.png";
import {
  Button,
  CircularProgress,
  FormLabel,
  Grid,
  TextField,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import axios from "./axiosBase";
import "./axiosInterceptors";
import "./css/login.css";
import "./css/reset-password.css";
import { Link } from "react-router-dom";

function ResetPassword() {
  const [isSuccess, setIsSuccess] = useState(false);
  const [email, setEmail] = useState("");
  const [appLoading, setappLoading] = useState(false);
  const handleForm = (e) => {
    e.preventDefault();
    setappLoading(true);
    axios
      .post("reset-password", {
        email: email,
      })
      .then((result) => result.data)
      .then((data) => {
        console.log(data);
        if (data.message === "success") {
          setIsSuccess(true);
          setappLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setappLoading(false);
      });
  };
  return (
    <div className="SyncBankerLogin_SyncBankerLogin_1">
      {/* <img className="Vector" src={ImgAsset.SyncBankerLogin_Vector} /> */}
      <div className="AddDocumentPopup1">
        <div className="cardheader"></div>
        <div className="Frame420"></div>
        <span className="Login-Reset">Reset Password</span>
        {isSuccess && (
          <div className="alert-login">
            <Alert
              variant="filled"
              severity="success"
              className="alert-message"
            >
              Reset Email Link has been sent
            </Alert>
          </div>
        )}
        <form action="" className="form">
          <Grid container item spacing={2}>
            <Grid item sm={12}>
              <FormLabel className="form-label">Enter Email</FormLabel>
              <TextField
                fullWidth
                hiddenLabel
                id="oulined-basic"
                variant="outlined"
                name="username"
                placeholder="Enter Your email"
                value={email}
                onChange={(e) => {
                  console.log(e.target.value);
                  setEmail(e.target.value);
                }}
                sx={{
                  "& fieldset": { border: "none" },
                }}
                size="small"
                className="form__textfield"
              />
            </Grid>
            <Grid item xs={12}>
              {appLoading === false ? (
                <Button
                  className="button"
                  type="submit"
                  onClick={(e) => handleForm(e)}
                  fullWidth
                  variant="contained"
                >
                  Send Reset Link
                </Button>
              ) : (
                <Button
                  className="button"
                  type="submit"
                  //   onClick={(e) => handleForm(e)}
                  fullWidth
                  variant="contained"
                >
                  Send Reset Link <CircularProgress size={20} color="inherit" />
                </Button>
              )}
            </Grid>
            <div className="login_link">
              <Link to="Login" className="login_link_link">
                Login
              </Link>
            </div>
          </Grid>
        </form>
      </div>
      <div className="AddDocumentPopup_1_1">
        <img className="Capture1" src={Capture} alt="Capture" />
      </div>
    </div>
  );
}

export default ResetPassword;
