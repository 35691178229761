import React, { useState } from "react";
import { useGlobalContext } from "./context";
import { useNavigate, Link } from "react-router-dom";
import { clearData, getRefreshToken } from "./handleToken";
import sync from "./static/Capture.png";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import CircleNotificationsRoundedIcon from "@mui/icons-material/CircleNotificationsRounded";
import {
  Avatar,
  Button,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  Select,
} from "@mui/material";
import "./css/Account.css";
import axiosBase from "./axiosBase";

function Settings() {
  const { authenticate, setAuthenticate, username } = useGlobalContext();
  console.log(username);
  const history = useNavigate();
  const [erpName, setErpName] = useState();
  const handleForm = (event) => {
    event.preventDefault();
    console.log(erpName);
    if (erpName === "Zoho-books") {
      // window.open(
      //   `https://accounts.zoho.in/oauth/v2/auth?scope=ZohoBooks.fullaccess.all&client_id=1000.M2SUIIASRPM4SRGAYVI56Y8HVIJBKX&response_type=code&redirect_uri=http://localhost:8000/sync/redirect&access_type=offline&state=${username}&prompt=consent`,
      //   "_blank"
      // );
      window.open(
        `https://accounts.zoho.in/oauth/v2/auth?scope=ZohoBooks.fullaccess.all&client_id=1000.M2SUIIASRPM4SRGAYVI56Y8HVIJBKX&response_type=code&redirect_uri=https://sync-backend.azurewebsites.net/sync/redirect&access_type=offline&state=${username}&prompt=consent`,
        "_blank"
      );
    } else if (erpName === "Tally-Prime") {
      history("/TallyErp/tally-prime");
    } else if (erpName === "Tally-Erp") {
      history("/TallyErp/tally-erp9");
    } else if (erpName === "Fresh-books") {
      window.open(
        `https://auth.freshbooks.com/oauth/authorize?client_id=71d1042034f3a21a72cf323cf396d262d6459378a276832838ec470ebe87933f&response_type=code&redirect_uri=https%3A%2F%2Fsync-backend.azurewebsites.net%2Fsync%2Fredirect%2Ffresh-books&scope=user%3Aprofile%3Aread%20user%3Areports%3Aread&state=${username}`,
        "_blank"
      );
    }
  };
  const logout = (e) => {
    e.preventDefault();
    const token = getRefreshToken();
    axiosBase
      .post("api/token/blacklist/", { refresh: token })
      .then((response) => {
        clearData();
        setAuthenticate(false);
        history("/login");
      });
  };

  if (authenticate === false) {
    history("/login");
  }
  return (
    <div className="share__dashboard">
      <div className="share__dashboard__sidenav">
        <div className="share__dashboard__sidenav__img">
          <img src={sync} alt="" />
        </div>
        <ul>
          <li className="active-dashboard">
            <Link to="/dashboard">
              {/* eslint-disable-next-line */}
              <a href="#">
                <span className="icon">
                  <GridViewRoundedIcon />
                </span>
                Dashboard
              </a>
            </Link>
          </li>
          <li>
            <Link to="/my-organization">
              {/* eslint-disable-next-line */}
              <a href="#">
                <span className="icon">
                  <HomeRoundedIcon />
                </span>
                My Organization
              </a>
            </Link>
          </li>
          <li className="active-setting">
            <Link to="/accounting-settings">
              {/* eslint-disable-next-line */}
              <a href="#">
                <span className="icon">
                  <SettingsIcon />
                </span>
                Settings
              </a>
            </Link>
          </li>
          <li>
            {/* eslint-disable-next-line */}
            <a href="#" onClick={logout}>
              <span className="icon">
                <LogoutIcon />
              </span>
              Logout
            </a>
          </li>
        </ul>
      </div>
      <div className="share__dashboard__topnav">
        <div className="share__dashboard__topnav__header">
          <h1>My organization</h1>
        </div>
        <div className="share__dashboard__topnav__item">
          <a href="/">
            <CircleNotificationsRoundedIcon className="notification_icon" />
          </a>
          <Avatar className="image__avatar" />
        </div>
      </div>
      <div className="share__dashboard__main__maindivision">
        <div className="share__dashboard__main__subdivision">
          <div className="btn__settings">
            <Link to="/settings">
              <a
                href="/settings"
                // onClick={(e) => e.preventDefault()}
                className="inactive-account-setting-dashboard"
              >
                <LoginIcon /> Login Settings
              </a>
            </Link>
            <Link to="/accounting-settings">
              <a
                href="/accounting-settings"
                // onClick={(e) => e.preventDefault()}
                className="active-account-setting-dashboard"
              >
                Erp/Accounting System Settings
              </a>
            </Link>
          </div>
          <div className="share__table__organization">
            <div>
              <form action="">
                <Grid container item spacing={2}>
                  <Grid xs={6} item>
                    <FormLabel className="form-label">
                      Erp/Accounting System Settings
                    </FormLabel>
                    <FormControl fullWidth>
                      <Select
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={erpName || ""}
                        name="branch"
                        size="small"
                        onChange={(e) => setErpName(e.target.value)}
                        className="form__textfield__select"
                        sx={{
                          "& fieldset": { border: "none" },
                        }}
                      >
                        <MenuItem value="Zoho-books">Zoho Books</MenuItem>
                        <MenuItem value="Tally-Prime">Tally Prime</MenuItem>
                        <MenuItem value="Tally-Erp">Tally Erp</MenuItem>
                        <MenuItem value="Fresh-books">Fresh Books</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}></Grid>
                  <Grid item sm={3}>
                    <Button
                      onClick={(e) => handleForm(e)}
                      className="button"
                      fullWidth
                      variant="contained"
                    >
                      Change
                    </Button>
                  </Grid>
                  <Grid item sm={2}>
                    <Button
                      // className="button"
                      fullWidth
                      variant="outlined"
                      href="/dashboard"
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
