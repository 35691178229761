import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Splash from "../Splash";
import {
  Avatar,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  FormControl,
  Button,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import InputBase from "@mui/material/InputBase";
import CircleNotificationsRoundedIcon from "@mui/icons-material/CircleNotificationsRounded";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import LogoutIcon from "@mui/icons-material/Logout";
import SearchIcon from "@mui/icons-material/Search";
import sync from ".././static/Capture.png";
import "./Components/BankerDashboard.css";
import "./css/dashboard.css";
import axios from "../axiosBase";
import "../axiosInterceptors";
import "./Components/SyncBankerDashboard.css";
import { styled, alpha } from "@mui/material/styles";
import { clearData, getRefreshToken } from "../handleToken";
import { useGlobalContext } from "../context";
import ScaleText from "react-scale-text";
import Done from "@mui/icons-material/Done";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  borderColor: alpha(theme.palette.common.black, 0.15),
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "180%",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

function Dashboard1() {
  const { authenticate, setAuthenticate, setisAdmin, isAdmin, networkError } =
    useGlobalContext();

  const history = useNavigate();
  if (authenticate === false) {
    history("/admin/login");
  }
  if (authenticate === true && isAdmin === false) {
    clearData();
    history("/login");
  }
  const logout = (e) => {
    e.preventDefault();
    const token = getRefreshToken();
    axios.post("api/token/blacklist/", { refresh: token }).then((response) => {
      clearData();
      setAuthenticate(false);
      setisAdmin(false);
      history("/admin/login");
    });
  };
  const [branches, setBranches] = useState(0);
  const [flags, setflags] = useState(0);
  const [users, setusers] = useState([]);
  const [numUsers, setNumUsers] = useState();
  const [stockSubmmited, setstockSubmmited] = useState();
  const [searchInput, setSearchInput] = useState("");
  const [filteredResults, setFilteredResults] = useState(users);
  const [individualStock, setIndividualStock] = useState([]);
  const [submissionColor, setSubmissionColor] = useState(null);
  const [redFlagsColor, setRedFlagsColor] = useState(null);
  const [greebFlagsColor, setgreebFlagsColor] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isError, setisError] = useState(false);
  const [isErrorDetails, setIsErrorDetails] = useState("Error in Data");

  useEffect(() => {
    if (networkError) {
      setLoading(false);
      setisError(true);
      setIsErrorDetails("Internet Connection Error");
    }
  }, [networkError]);
  const searchUser = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const searchValue = event.target.value;
    console.log(searchValue);
    setSearchInput(searchValue);
  };
  const getErpDetails = async (panNo) => {
    const response = await axios.get("erp-settings", {
      params: { pan_no: panNo },
    });
    const data = await response.data;
    return data.data.erp_name;
  };

  useEffect(() => {
    // if (searchInput.length !== 0) {
    const filteredData = users.filter((user) => {
      return (
        user.pan_no.toLowerCase().startsWith(searchInput.toLowerCase()) ||
        user.email.toLowerCase().startsWith(searchInput.toLowerCase())
      );
    });
    setFilteredResults(filteredData);
    // eslint-disable-next-line
  }, [searchInput]);

  const [details, setDetails] = useState({
    month: "",
    year: "",
    erp_status: "",
    branch: "",
    flags: "",
  });
  const resetFilter = () => {
    setFilteredResults([]);
    setRedFlagsColor(null);
    setgreebFlagsColor(null);
    setSubmissionColor(null);
    setFilteredResults(users);
    setDetails((oldValue) => {
      return { ...oldValue, erp_status: "", branch: "", flags: "" };
    });
  };
  const date = new Date();
  const formValue = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const { name, value } = event.target;
    if (value === "0" && name === "erp_status") {
      setSubmissionColor(false);
    } else if (value === "-1" && name === "erp_status") {
      setSubmissionColor(true);
    } else if (value === "1" && name === "flags") {
      setRedFlagsColor(false);
      setgreebFlagsColor(null);
    } else if (name === "flag-green") {
      setgreebFlagsColor(true);
      setRedFlagsColor(null);
    }
    console.log(value);
    if (name === "flag-green") {
      setDetails((oldValue) => {
        return { ...oldValue, flags: value };
      });
    }
    console.log(name);
    setDetails((oldValue) => {
      return { ...oldValue, [name]: value };
    });
    console.log(details);
  };
  const getInfo = () => {
    axios
      .get("get-all-users", {
        params: {
          year:
            date.getMonth() === 0 ? date.getFullYear() - 1 : date.getFullYear(),
          month: date.getMonth() === 0 ? 12 : date.getMonth(),
        },
      })
      .then((response) => response.data)
      .then((response) => {
        console.log(response);
        if (response.message === "success") {
          setBranches(response.branches);
          setflags(response.flags);
          setNumUsers(response.no_of_borrowers);
          setstockSubmmited(response.submitted_logs);
          setusers(response.users);
          setFilteredResults(response.users);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  useEffect(() => {
    if (
      branches.length !== 0 &&
      users.length !== 0 &&
      individualStock.length !== 0
    ) {
      setLoading(false);
    }
    //disable eslint
  }, [branches, users, individualStock]);
  const currentStockInfo = () => {
    axios
      .get("admin-user/stock-statement-view", {
        params: {
          month: details.month,
          year: details.year,
        },
      })
      .then((response) => response.data)
      .then((result) => {
        console.log(result);
        if (result.message === "success") {
          setstockSubmmited(result.count);
          setIndividualStock(result.stock);
        }
      })
      .catch((err) => console.log(err));
  };
  const currentStockInfoInit = () => {
    axios
      .get("admin-user/stock-statement-view", {
        params: {
          year:
            date.getMonth() === 0 ? date.getFullYear() - 1 : date.getFullYear(),
          month: date.getMonth() === 0 ? 12 : date.getMonth(),
        },
      })
      .then((response) => response.data)
      .then((result) => {
        console.log(result);
        if (result.message === "success") {
          setstockSubmmited(result.count);
          setIndividualStock(result.stock);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (details.year && details.month) {
      currentStockInfo();
    }
    // eslint-disable-next-line
  }, [details]);
  useEffect(() => {
    getInfo();
    currentStockInfoInit();
    // eslint-disable-next-line
  }, []);

  const filterUserData = () => {
    if (details.flags && details.branch && details.erp_status) {
      const newFilterData = users.filter((user) => {
        const userStockIndex = individualStock.findIndex(
          (stockUser) => stockUser.pan_no === user.pan_no
        );
        const flag = parseInt(details.flags);
        if (flag === 0 && parseInt(details.erp_status) === -1) {
          return (
            user.branch.toLowerCase() === details.branch.toLowerCase() &&
            user.flag === 0 &&
            userStockIndex === -1
          );
        } else if (flag !== 0 && parseInt(details.erp_status) !== -1) {
          return (
            user.branch.toLowerCase() === details.branch.toLowerCase() &&
            user.flag > 0 &&
            userStockIndex >= 0
          );
        } else if (flag === 0 && parseInt(details.erp_status) !== -1) {
          return (
            user.branch.toLowerCase() === details.branch.toLowerCase() &&
            user.flag === 0 &&
            userStockIndex >= 0
          );
        } else if (flag !== 0 && parseInt(details.erp_status) === -1) {
          return (
            user.branch.toLowerCase() === details.branch.toLowerCase() &&
            user.flag > 0 &&
            userStockIndex === -1
          );
        } else {
          console.log("something wrong");
        }
      });
      console.log(newFilterData);
      setFilteredResults(newFilterData);
    }
    if (details.branch && !details.erp_status && !details.flags) {
      const newFilterData = users.filter((user) => {
        return user.branch.toLowerCase() === details.branch.toLowerCase();
      });
      setFilteredResults(newFilterData);
    }

    if (!details.branch && details.erp_status && !details.flags) {
      const newFilterData = users.filter((user) => {
        const userStockIndex = individualStock.findIndex(
          (stockUser) => stockUser.pan_no === user.pan_no
        );
        if (parseInt(details.erp_status) === -1) {
          return userStockIndex === -1;
        } else {
          return userStockIndex >= 0;
        }
      });
      setFilteredResults(newFilterData);
    }
    if (!details.branch && !details.erp_status && details.flags) {
      const newFilterData = users.filter((user) => {
        if (parseInt(details.flags) === 0) {
          return user.flag === 0;
        } else {
          return user.flag > 0;
        }
      });
      setFilteredResults(newFilterData);
    }
    if (details.branch && details.erp_status && !details.flags) {
      const newFilterData = users.filter((user) => {
        const userStockIndex = individualStock.findIndex(
          (stockUser) => stockUser.pan_no === user.pan_no
        );
        if (parseInt(details.erp_status) === -1) {
          return (
            user.branch.toLowerCase() === details.branch.toLowerCase() &&
            userStockIndex === -1
          );
        } else {
          return (
            user.branch.toLowerCase() === details.branch.toLowerCase() &&
            userStockIndex >= 0
          );
        }
      });
      setFilteredResults(newFilterData);
    }
    if (details.branch && details.flags && !details.erp_status) {
      const newFilterData = users.filter((user) => {
        if (parseInt(details.flags) === 0) {
          return (
            user.branch.toLowerCase() === details.branch.toLowerCase() &&
            user.flag === 0
          );
        } else {
          return (
            user.branch.toLowerCase() === details.branch.toLowerCase() &&
            user.flag > 0
          );
        }
      });
      setFilteredResults(newFilterData);
    }

    if (details.erp_status && details.flags && !details.branch) {
      const newFilterData = users.filter((user) => {
        const userStockIndex = individualStock.findIndex(
          (stockUser) => stockUser.pan_no === user.pan_no
        );
        if (parseInt(details.erp_status) === -1 && details.flags === 0) {
          return userStockIndex === -1 && user.flag === 0;
        } else if (details.erp_status !== -1 && details.flags === 0) {
          return userStockIndex >= 0 && user.flag === 0;
        } else if (parseInt(details.erp_status) === -1 && details.flags !== 0) {
          return userStockIndex === -1 && user.flag > 0;
        } else if (details.erp_status !== -1 && details.flags !== 0) {
          return userStockIndex >= 0 && user.flag > 0;
        }
      });
      setFilteredResults(newFilterData);
    }
  };
  async function handleRowClick(key) {
    const erpName = await getErpDetails(key);
    console.log(erpName);
    if (erpName === "fresh-books") {
      history(`/admin/fresh-books/statement/${key}`);
    } else {
      history(`/admin/statement/${key}`);
    }
  }

  if (loading === true) {
    return <Splash />;
  }
  return (
    <div className="share__dashboard">
      <div className="share__dashboard__sidenav">
        <div className="share__dashboard__sidenav__img">
          <img src={sync} alt="" />
        </div>
        <ul>
          <li className="active">
            <Link to="/admin/dashboard">
              <a href="admin/dashboard">
                <span className="icon">
                  <GridViewRoundedIcon />
                </span>
                Dashboard
              </a>
            </Link>
          </li>
          <li>
            <Link to="/admin/settings">
              <a href="/admin/settings">
                <span className="icon">
                  <SettingsIcon />
                </span>
                Settings
              </a>
            </Link>
          </li>
          <li>
            <a href="/" onClick={(e) => logout(e)}>
              <span className="icon">
                <LogoutIcon />
              </span>
              Logout
            </a>
          </li>
        </ul>
      </div>
      <div className="share__dashboard__topnav">
        <div className="share__dashboard__topnav__header">
          <h1>Dashboard</h1>
        </div>
        <div className="share__dashboard__topnav__item">
          <a href="/">
            <CircleNotificationsRoundedIcon className="notification_icon" />
          </a>
          <Avatar className="image__avatar" />
        </div>
      </div>
      <div className="share__dashboard__main__maindivision">
        <div>
          <div className="share__dashboard__main__maindivision">
            <div className="share__dashboard__main__subdivision__view1">
              <p className="Borrowers">Branches</p>
              <p className="values1 values">{branches}</p>
            </div>
            <div className="share__dashboard__main__subdivision__view2">
              <p className="Borrowers">Borrowers</p>
              <p className="values2 values">{numUsers}</p>
            </div>
            <div className="share__dashboard__main__subdivision__view3">
              <p className="Borrowers">Stock Statements Submitted</p>
              <p className="values3 values">{stockSubmmited}</p>
            </div>
            <div className="share__dashboard__main__subdivision__view4">
              <p className="Borrowers">Accounts Flagged</p>
              <p className="values4 values">{flags}</p>
            </div>
          </div>
          {/*<div className="share__dashboard__main__maindivision">*/}
          <div className="share__dashboard__main__subdivision69">
            <div className="share__dashboard__main__head">
              <div className="share__dashboard__main__head__div">
                <h3 className="borrower__header">Borrower List View</h3>
                <Grid container>
                  <Grid item>
                    <Search className="search search-icon">
                      <SearchIconWrapper>
                        <SearchIcon />
                      </SearchIconWrapper>
                      <StyledInputBase
                        placeholder="Search by Pan number, email id"
                        fullWidth
                        inputProps={{ "aria-label": "search" }}
                        onChange={searchUser}
                        value={searchInput}
                      />
                    </Search>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className="share__table__tags">
              <table>
                <tr>
                  <th>Organization Name</th>
                  <th>Email-Id</th>
                  <th>Pan Number</th>
                  <th>branch</th>
                  <th>Stock Statement Status</th>
                  <th>Flags</th>
                </tr>
                {filteredResults.length >= 1 ? (
                  filteredResults.map((user) => {
                    return (
                      <tr
                        key={user.pan_no}
                        onClick={() => handleRowClick(user.pan_no)}
                        style={{ cursor: "pointer" }}
                      >
                        <td className="fit">
                          {" "}
                          {/*<Link to={`/admin/organization/${user.pan_no}`}>*/}
                          <ScaleText>
                            <p>{user.organization_name}</p>
                          </ScaleText>
                          {/*</Link>*/}
                        </td>
                        <td className="fit">
                          {user.email ? user.email : "undefined"}
                        </td>
                        <td className="fit">
                          <ScaleText>
                            <p>{user.pan_no}</p>
                          </ScaleText>
                        </td>
                        <td className="fit">
                          <ScaleText>
                            {user.branch ? user.branch : "undefined"}
                          </ScaleText>
                        </td>
                        <td className="fit">
                          {individualStock.findIndex(
                            (stockUser) => stockUser.pan_no === user.pan_no
                          ) === -1 ? (
                            <span className="not_submitted">Not Submitted</span>
                          ) : (
                            <span className="submitted">Submitted</span>
                          )}
                        </td>
                        <td className="fit">
                          {user?.flag === 0 ? (
                            <span className="green"></span>
                          ) : (
                            <span className="red"></span>
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <>
                    <p>No Data Available</p>
                  </>
                )}
              </table>
            </div>
          </div>
        </div>
        <div className="share__dashboard__main__head__div__tags">
          <div
            className="declaration__div__tags"
            style={{ width: "320px", height: "530px" }}
          >
            <Grid container spacing={1}>
              <Grid item>
                <FormLabel className="form-label__month">Month</FormLabel>
                <FormControl fullWidth>
                  <Select
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={details.month}
                    size="small"
                    name="month"
                    onChange={formValue}
                    className="form__textfield__month"
                    sx={{
                      "& fieldset": { border: "none" },
                    }}
                  >
                    <MenuItem value="01">January</MenuItem>
                    <MenuItem value="02">February</MenuItem>
                    <MenuItem value="03">March</MenuItem>
                    <MenuItem value="04">April</MenuItem>
                    <MenuItem value="05">May</MenuItem>
                    <MenuItem value="06">June</MenuItem>
                    <MenuItem value="07">July</MenuItem>
                    <MenuItem value="08">August</MenuItem>
                    <MenuItem value="09">September</MenuItem>
                    <MenuItem value="10">October</MenuItem>
                    <MenuItem value="11">November</MenuItem>
                    <MenuItem value="12">December</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <FormLabel className="form-label__year">Year</FormLabel>
                <FormControl fullWidth>
                  <Select
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={details.year}
                    name="year"
                    size="small"
                    onChange={formValue}
                    sx={{
                      "& fieldset": { border: "none" },
                    }}
                    className="form__textfield__year"
                  >
                    <MenuItem value={date.getFullYear()}>
                      {date.getFullYear()}
                    </MenuItem>
                    <MenuItem value={date.getFullYear() - 1}>
                      {date.getFullYear() - 1}
                    </MenuItem>
                    <MenuItem value={date.getFullYear() - 2}>
                      {date.getFullYear() - 2}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <h3 className="form-label__tags">Tags and Filters</h3>
            <span className="subheadings">By Stock Statement Status</span>
            <Button
              className="buttonn"
              value={0}
              name="erp_status"
              onClick={formValue}
              style={{
                backgroundColor:
                  submissionColor === false ? "#00dfae" : "#712ecf",
              }}
            >
              Submitted
            </Button>
            <Button
              className="buttonn"
              value={-1}
              name="erp_status"
              onClick={formValue}
              style={{
                backgroundColor:
                  submissionColor === true ? "#00dfae" : "#712ecf",
              }}
            >
              Not Submitted
            </Button>
            <div>
              <span className="subheadings">By Branch</span>
            </div>
            <Grid container>
              <Grid item>
                <Search
                  style={{
                    marginTop: "10px",
                    width: "80%",
                    marginLeft: "26px",
                    border: "2px solid #712ECF",
                    borderRadius: "50px",
                    paddingTop: "10px",
                    paddingBottom: "5px",
                    fontSize: "17px",
                  }}
                >
                  <SearchIconWrapper className="new__search-icon">
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    className="searchbar__tags"
                    placeholder="Search by Branch"
                    onChange={formValue}
                    name="branch"
                    fullWidth
                    inputProps={{ "aria-label": "search" }}
                  />
                </Search>
              </Grid>
            </Grid>
            <p
              style={{
                borderBottom: "1px solid grey",
                marginTop: "5px",
                marginLeft: "15px",
                marginRight: "15px",
              }}
            />
            <span className="subheadings">By Flags</span>
            <div className="button__header">
              <Button
                className="subheadings__flags"
                style={{
                  color: redFlagsColor === false ? "black" : "#8C8C8C",
                  fontWeight: "500",
                  fontSize: "14px",
                  marginLeft: "26px",
                  textTransform: "capitalize",
                  backgroundColor: redFlagsColor === false ? "#ff3b30" : "",
                }}
                value="1"
                name="flags"
                onClick={formValue}
              >
                <Done fontSize="small" className="done-red" /> Red
              </Button>
              <Button
                className="subheadings__flags"
                style={{
                  color: greebFlagsColor === true ? "black" : "#8C8C8C",
                  fontWeight: "500",
                  fontSize: "14px",
                  textTransform: "capitalize",
                  backgroundColor: greebFlagsColor === true ? "#53c41a" : "",
                }}
                value="0"
                name="flag-green"
                onClick={(e) => formValue(e)}
              >
                <Done fontSize="small" className="done-green" /> Green
              </Button>
            </div>
            <div className="button__div">
              <button className="apply" onClick={filterUserData}>
                Apply
              </button>
              <button className="reset" onClick={resetFilter}>
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>
      {/*</div>*/}
      {/*</div>*/}
    </div>
  );
}

export default Dashboard1;
