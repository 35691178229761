import React, { useEffect, useState } from "react";
import sync from "./static/Capture.png";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import Alert from "@mui/material/Alert";
import axios from "./axiosBase";
import "./axiosInterceptors";
import { clearData, getRefreshToken } from "./handleToken";
import CircleNotificationsRoundedIcon from "@mui/icons-material/CircleNotificationsRounded";
import {
  Grid,
  Avatar,
  TextField,
  Button,
  Select,
  MenuItem,
  FormLabel,
  FormControl,
} from "@mui/material";
import Splash from "./Splash";
import "./css/MyOrganization.css";

import { useGlobalContext } from "./context";
import { Link, useNavigate } from "react-router-dom";
// import {useState} from "@types/react";

function OrganizationData() {
  const { authenticate, setAuthenticate, isAdmin } = useGlobalContext();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const history = useNavigate();
  if (authenticate === false) {
    history("/login");
    // history.location.search()
  }
  if (isAdmin === true) {
    history("/admin/dashboard");
  }
  const logout = (e) => {
    e.preventDefault();
    const token = getRefreshToken();
    axios.post("api/token/blacklist/", { refresh: token }).then((response) => {
      clearData();
      setAuthenticate(false);
      history("/login");
    });
  };
  const [branches, setBranches] = useState([]);
  const [details, setDetails] = useState({
    old_pan_no: "",
    email: "",
    organization: "",
    pan_no: "",
    address: "",
    account_no: "",
    contact_no: "",
    password: "",
    branch: "",
  });

  const formValue = (e) => {
    console.log(e.target.value, e.target.name);
    setDetails((prevData) => {
      return { ...prevData, [e.target.name]: [e.target.value] };
    });
  };

  const sendData = () => {
    axios
      .post("update-user", {
        organization_no: details.organization[0],
        address: details.address[0],
        contact_no: details.contact_no[0],
        password: details.password[0],
        account_no: details.account_no[0],
        branch: details.branch[0],
        old_pan_no: details.old_pan_no[0],
      })
      .then((response) => response.data)
      .then((data) => {
        console.log(data);
        if (data.message === "success") {
          setLoading(false);
          setDetails((prevData) => {
            return {
              ...prevData,
              organization: [data.data.organization_name],
              email: [data.data.email],
              account_no: [data.data.account_no],
              pan_no: [data.data.pan_no],
              branch: [data.data.branch],
              address: [data.data.address],
              contact_no: [data.data.contact_no],
              old_pan_no: [data.data.pan_no],
            };
          });
        }
      })
      .catch((err) => console.log(err));
  };
  const handleForm = (event) => {
    event.preventDefault();
    console.log(
      details.email,
      details.pan_no,
      details.organization,
      details.contact_no
    );
    sendData();
  };
  const getData = () => {
    axios
      .get("user-data")
      .then((response) => response.data)
      .then((data) => {
        console.log(data.data);
        if (data.message === "success") {
          return setDetails((prevData) => {
            setLoading(false);
            return {
              ...prevData,
              organization: [data.data.organization_name],
              email: [data.data.email],
              account_no: [data.data.account_no],
              pan_no: [data.data.pan_no],
              branch: [data.data.branch],
              address: [data.data.address],
              contact_no: [data.data.contact_no],
              old_pan_no: [data.data.pan_no],
            };
          });
        }
      })
      .then((data) => {
        axios
          .get("branches")
          .then((response) => response.data)
          .then((data) => {
            console.log(data);
            setBranches(data.message);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    setTimeout(() => {
      setSuccess(false);
    }, 10000);
  });
  useEffect(() => {
    getData();
  }, []);
  if (loading === true) {
    return <Splash />;
  }
  return (
    <div className="share__dashboard">
      <div className="share__dashboard__sidenav__org">
        <div className="share__dashboard__sidenav__img">
          <img src={sync} alt="" />
        </div>
        <ul>
          <li>
            <Link to="/dashboard">
              {/* eslint-disable-next-line */}
              <a href="#">
                <span className="icon">
                  <GridViewRoundedIcon />
                </span>
                Dashboard
              </a>
            </Link>
          </li>
          <li className="active-org">
            <Link to="/my-organization">
              {/* eslint-disable-next-line */}
              <a href="#">
                <span className="icon">
                  <HomeRoundedIcon />
                </span>
                My Organization
              </a>
            </Link>
          </li>
          <li>
            <Link to="/settings">
              {/* eslint-disable-next-line */}
              <a href="#">
                <span className="icon">
                  <LoginIcon />
                </span>
                Login Settings
              </a>
            </Link>
          </li>
          <li>
            <Link to="/erp-systems">
              {/* eslint-disable-next-line */}
              <a href="#">
                <span className="icon">
                  <SettingsIcon />
                </span>
                Erp Settings
              </a>
            </Link>
          </li>
          <li>
            <a href="/logout" onClick={(e) => logout(e)}>
              <span className="icon">
                <LogoutIcon />
              </span>
              Logout
            </a>
          </li>
        </ul>
      </div>
      <div className="share__dashboard__topnav">
        <div className="share__dashboard__topnav__header">
          <h1>My organization</h1>
        </div>
        <div className="share__dashboard__topnav__item">
          <a href="/">
            <CircleNotificationsRoundedIcon className="notification_icon" />
          </a>
          <Avatar className="image__avatar" />
        </div>
      </div>
      <div className="share__dashboard__main__maindivision">
        <div className="share__dashboard__main__subdivision_org">
          <div className="share__table__organization">
            <div>
              {success && (
                <div className="alert">
                  <Alert variant="filled" severity="success">
                    Update Success
                  </Alert>
                </div>
              )}
              <form action="">
                <Grid container item spacing={2}>
                  <Grid item xs={6}>
                    <FormLabel className="form-label">
                      Organization Name
                    </FormLabel>
                    <TextField
                      fullWidth
                      id="oulined-basic"
                      variant="outlined"
                      name="organization"
                      placeholder="Enter your Organization name"
                      value={details.organization}
                      onChange={formValue}
                      className="form__textfield"
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormLabel className="form-label">Email ID</FormLabel>
                    <TextField
                      fullWidth
                      id="oulined-basic"
                      type="email"
                      variant="outlined"
                      name="email"
                      placeholder="Enter your Email-ID/Phone number"
                      value={details.email}
                      disabled
                      onChange={formValue}
                      className="form__textfield"
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormLabel className="form-label">Pan Number</FormLabel>
                    <TextField
                      fullWidth
                      type="text"
                      id="oulined-basic"
                      variant="outlined"
                      placeholder="Enter Your Pan number"
                      name="pan_no"
                      disabled
                      value={details.pan_no}
                      className="form__textfield"
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormLabel className="form-label">Account Number</FormLabel>
                    <TextField
                      fullWidth
                      id="oulined-basic"
                      variant="outlined"
                      name="account_no"
                      value={details.account_no}
                      onChange={formValue}
                      className="form__textfield"
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormLabel className="form-label">Contact Number</FormLabel>
                    <TextField
                      fullWidth
                      id="oulined-basic"
                      variant="outlined"
                      placeholder="Enter your Contact number"
                      type="number"
                      name="contact_no"
                      value={details.contact_no}
                      onChange={formValue}
                      className="form__textfield"
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                    />
                  </Grid>
                  <Grid xs={6} item>
                    <FormLabel className="form-label">Branch</FormLabel>
                    <FormControl fullWidth>
                      <Select
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={details.branch}
                        name="branch"
                        onChange={formValue}
                        className="form__textfield__select"
                        sx={{
                          "& fieldset": { border: "none" },
                        }}
                      >
                        {branches.map((data) => {
                          return (
                            <MenuItem
                              key={data.branch_name}
                              value={data.branch_name}
                            >
                              {data.branch_name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormLabel className="form-label">Address</FormLabel>
                    <TextField
                      fullWidth
                      id="oulined-basic"
                      variant="outlined"
                      name="address"
                      value={details.address}
                      placeholder="Enter your address"
                      onChange={formValue}
                      className="form__textfield"
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                    />
                  </Grid>
                  <Grid item sm={3}>
                    <Button
                      onClick={(e) => handleForm(e)}
                      className="button"
                      fullWidth
                      variant="contained"
                    >
                      Save
                    </Button>
                  </Grid>
                  <Grid item sm={2}>
                    <Button
                      // className="button"
                      fullWidth
                      variant="outlined"
                      href="/dashboard"
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrganizationData;
