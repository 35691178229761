import React from "react";
import "./css/TallyConnect.css";
import "./css/dashboard.css";
import sync from "./static/Capture.png";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import { clearData, getRefreshToken } from "./handleToken";
import { Link, useNavigate, useParams } from "react-router-dom";
import CircleNotificationsRoundedIcon from "@mui/icons-material/CircleNotificationsRounded";
import { Avatar } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useGlobalContext } from "./context";
import "./axiosInterceptors";
import axiosBase from "./axiosBase";

function TallyConnect() {
  // const product = products.find((p) => p.name === match.params.name);
  const { setAuthenticate, isAdmin, authenticate } = useGlobalContext();
  const history = useNavigate();
  if (authenticate === false) {
    history("/login");
  }
  if (isAdmin === true) {
    history("/admin/dashboard");
  }
  let { erpName } = useParams();
  console.log(erpName);
  const logout = (e) => {
    e.preventDefault();
    const token = getRefreshToken();
    axiosBase
      .post("api/token/blacklist/", { refresh: token })
      .then((response) => {
        clearData();
        setAuthenticate(false);
        history("/login");
      });
  };

  return (
    <div className="share__dashboard">
      <div className="share__dashboard__sidenav">
        <div className="share__dashboard__sidenav__img">
          <img src={sync} alt="" />
        </div>
        <ul>
          <li>
            <Link to="/dashboard">
              {/* eslint-disable-next-line */}
              <a href="#">
                <span className="icon">
                  <GridViewRoundedIcon />
                </span>
                Dashboard
              </a>
            </Link>
          </li>
          <li>
            <Link to="/my-organization">
              {/* eslint-disable-next-line */}
              <a href="#">
                <span className="icon">
                  <HomeRoundedIcon />
                </span>
                My Organization
              </a>
            </Link>
          </li>
          <li>
            <Link to="/settings">
              {/* eslint-disable-next-line */}
              <a href="#">
                <span className="icon">
                  <LoginIcon />
                </span>
                Login Settings
              </a>
            </Link>
          </li>
          <li className="active">
            <Link to="/erp-systems">
              {/* eslint-disable-next-line */}
              <a href="#">
                <span className="icon">
                  <SettingsIcon />
                </span>
                Erp Settings
              </a>
            </Link>
          </li>
          <li>
            <a href="/logout" onClick={(e) => logout(e)}>
              <span className="icon">
                <LogoutIcon />
              </span>
              Logout
            </a>
          </li>
        </ul>
      </div>
      <div className="share__dashboard__topnav">
        <div className="share__dashboard__topnav__header">
          <h1>Connect Tally ERP9</h1>
        </div>
        <div className="share__dashboard__topnav__item">
          <a href="/">
            <CircleNotificationsRoundedIcon className="notification_icon" />
          </a>
          <Avatar className="image__avatar" />
        </div>
      </div>
      <div className="share__dashboard__main__maindivision">
        <div className="share__dashboard__main__subdivision">
          <div className="share__table__organization">
            <div style={{ textAlign: "left" }}>
              <h3 className="instructions">Instructions</h3>
              <Divider />
              <p style={{ marginLeft: "22px" }}>
                Please download the desktop app to connect to Tally ERP{" "}
                <a href="https://dl.dropbox.com/scl/fi/72wrf3w7ivc5jbkdpt6wd/Sync-Application-Setup-0.1.0.exe?rlkey=ituk6tfn3jrl8sxf5a04wuzo3&st=66jynv2k&dl=0">
                  Download
                </a>
              </p>
              <ol
                style={{ textAlign: "left" }}
                className="download_instruction_ul"
              >
                <li>Download SYNC Desktop app and Install it.</li>
                <li>
                  Once the desktop app is installed, login using the same Sync
                  credentials.
                </li>
                <li>Keep the tally app open, when operating sync app</li>
                <li>Make sure tally is running as server on port 9000</li>
                <li>
                  If tally is running on some other port, make sure you enter
                  the same port or url details while sharing the stock
                  statement.
                </li>
                <li>
                  Once the tally is running as server, click on share stock
                  statement, select appropriate month ,year and organization and
                  share the stock statement.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TallyConnect;
