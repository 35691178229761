import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useGlobalContext } from "./context";
import {
  Avatar,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  FormControl,
  Checkbox,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import CircleNotificationsRoundedIcon from "@mui/icons-material/CircleNotificationsRounded";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import Alert from "@mui/material/Alert";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import LogoutIcon from "@mui/icons-material/Logout";
import InventoryIcon from "@mui/icons-material/Inventory";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import PaymentsIcon from "@mui/icons-material/Payments";
import BarChartIcon from "@mui/icons-material/BarChart";
import LoginIcon from "@mui/icons-material/Login";
import ApartmentIcon from "@mui/icons-material/Apartment";
import InboxIcon from "@mui/icons-material/Inbox";
import FlagIcon from "@mui/icons-material/Flag";
import sync from "./static/Capture.png";
import "./css/freshbookdashboard.css";
import axios from "./axiosBase";
import "./axiosInterceptors";
import { clearData, getRefreshToken } from "./handleToken";
import { CircularProgress } from "@mui/material";

function FreshbookDashboard() {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [totalPurchases, setTotalPurchases] = useState(0);
  const [totalSale, setTotalSale] = useState(0);
  const [totalPurchasesFy, setTotalPurchasesFy] = useState(0);
  const [totalSaleFy, setTotalSaleFy] = useState(0);
  const [payableTotal, setPayableTotal] = useState(0);
  const [payable90, setPayable90] = useState(0);
  const [payable0_30, setPayable0_30] = useState(0);
  const [payable31_60, setPayable31_60] = useState(0);
  const [payable61_90, setPayable61_90] = useState(0);
  const [recievableTotal, setRecievableTotal] = useState(0);
  const [recievable0_30, setRecievable0_30] = useState(0);
  const [recievable31_60, setRecievable31_60] = useState(0);
  const [recievable61_90, setRecievable61_90] = useState(0);
  const [recievable90, setRecievable90] = useState(0);
  const [organization, setOrganization] = useState([]);
  const [organization_id, setorganization_id] = useState();
  const [payExtraInfo, setpayExtraInfo] = useState([]);
  const [invExtraInfo, setInvExtraInfo] = useState([]);
  const [displayinventory, setDisplayinventory] = useState(false);
  const [displayRecievable, setDisplayRecievable] = useState(false);
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isShare, setIsShare] = useState(false);
  const [pan_no, setPan_no] = useState();
  const [isErrorDetails, setisErrorDetails] = useState(
    "An error occured while loading data, Reload page to update"
  );
  const history = useNavigate();
  const { setAuthenticate, authenticate, isAdmin } = useGlobalContext();
  if (authenticate === false) {
    history("/login");
  }
  if (isAdmin === true) {
    history("/admin/dashboard");
  }
  const date = new Date();
  const monthArray = [];
  for (let i = 0; i < date.getMonth(); i++) {
    monthArray.push(i);
  }
  const [details, setDetails] = useState({
    month: "",
    year: "",
    organization: "",
  });
  const getInfo = () => {
    axios
      .post("post-info", {
        date: new Date(),
        panNo: pan_no,
        totalPurchases: totalPurchases,
        totalSale: totalSale,
        totalPurchasesFy: totalPurchasesFy,
        totalSalesFy: totalSaleFy,
        payables: {
          payableTotal: payableTotal,
          payable90: payable90,
          payable0_30: payable0_30,
          payable31_60: payable31_60,
          payable61_90: payable61_90,
        },
        recievables: {
          recievableTotal: recievableTotal,
          recievable90: recievable90,
          recievable0_30: recievable0_30,
          recievable31_60: recievable31_60,
          recievable61_90: recievable61_90,
        },
        payableInfo: payExtraInfo,
        recievableinfo: invExtraInfo,
        month: details.month,
        year: details.year,
        day: date.getDate(),
      })
      .then((response) => response.data)
      .then((data) => {
        // console.log(data);
        if (data.success) {
          alert("Data upload successful");
          history("/dashboard");
        } else {
          setIsError(true);
          setisErrorDetails("Data for this month has been submitted");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const logout = (e) => {
    e.preventDefault();
    const token = getRefreshToken();
    axios.post("api/token/blacklist/", { refresh: token }).then((response) => {
      clearData();
      setAuthenticate(false);
      history("/login");
    });
  };
  const getOrganization = (cb) => {
    axios
      .get("get-freshbooksinfo/organizations")
      .then((response) => response.data)
      .then((data) => {
        console.log(data);
        setOrganization(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.message === "Network Error") {
          setLoading(false);
          setIsError(true);
          setisErrorDetails("Internet Connection error");
        } else {
          setIsError(true);
          setisErrorDetails(
            "An error occured while loading Organization data, Reload page to update"
          );
        }
      });
    axios
      .get("user-data")
      .then((response) => response.data)
      .then((data) => {
        // console.log(data);
        console.log(data.data.pan_no);
        setPan_no(data.data.pan_no);
      })
      .catch((err) => console.log(err));
  };
  const getData = () => {
    setLoading(true);
    axios
      .get("get-freshbooksinfo/recievables", {
        params: {
          organization_id: organization_id,
          year: details.year,
          month: details.month,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        console.log(data);
        setRecievableTotal(
          parseInt(data["accounts_aging"]["totals"]["total"]["amount"])
        );
        setRecievable0_30(
          parseInt(data["accounts_aging"]["totals"]["0-30"]["amount"])
        );
        setRecievable31_60(
          parseInt(data["accounts_aging"]["totals"]["31-60"]["amount"])
        );
        setRecievable61_90(
          parseInt(data["accounts_aging"]["totals"]["61-90"]["amount"])
        );
        setRecievable90(
          parseInt(data["accounts_aging"]["totals"]["91+"]["amount"])
        );
        return data["accounts_aging"]["accounts"];
      })
      .then((result) => {
        const payableArray = [];
        let id = 1;
        for (let i = 0; i < result.length; i++) {
          for (let index = 0; index < 4; index++) {
            if (index === 0) {
              if (result[i]["0-30"]["amount"] !== "0.00") {
                const data = {
                  id: id,
                  name: result[i]["organization"],
                  age: "0-30",
                  value: result[i]["0-30"]["amount"],
                };
                payableArray.push(data);
                id++;
              }
            }
            if (index === 1) {
              if (result[i]["31-60"]["amount"] !== "0.00") {
                const data = {
                  id: id,
                  name: result[i]["organization"],
                  age: "31-60",
                  value: result[i]["31-60"]["amount"],
                };
                payableArray.push(data);
                id++;
              }
            }
            if (index === 2) {
              if (result[i]["61-90"]["amount"] !== "0.00") {
                const data = {
                  id: id,
                  name: result[i]["organization"],
                  age: "61-90",
                  value: result[i]["61-90"]["amount"],
                };
                payableArray.push(data);
                id++;
              }
            }
            if (index === 3) {
              if (result[i]["91+"]["amount"] !== "0.00") {
                const data = {
                  id: id,
                  name: result[i]["organization"],
                  age: "91+",
                  value: result[i]["91+"]["amount"],
                };
                payableArray.push(data);
                id++;
              }
            }
          }
        }
        console.log(payableArray);
        setInvExtraInfo(payableArray);
      })
      .catch((err) => {
        console.log(err);
        // setIsError(true);
      });

    axios
      .get("get-freshbooksinfo/payables", {
        params: {
          organization_id: organization_id,
          year: details.year,
          month: details.month,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        console.log(data);
        setPayableTotal(
          parseInt(data["accounts_payable_aging"]["totals"]["total"]["amount"])
        );
        setPayable0_30(
          parseInt(data["accounts_payable_aging"]["totals"]["0-30"]["amount"])
        );
        setPayable31_60(
          parseInt(data["accounts_payable_aging"]["totals"]["31-60"]["amount"])
        );
        setPayable61_90(
          parseInt(data["accounts_payable_aging"]["totals"]["61-90"]["amount"])
        );
        setPayable90(
          parseInt(data["accounts_payable_aging"]["totals"]["91+"]["amount"])
        );
        return data["accounts_payable_aging"]["accounts"];
      })
      .then((result) => {
        const invoiceArray = [];
        let id = 1;
        for (let i = 0; i < result.length; i++) {
          for (let index = 0; index < 4; index++) {
            if (index === 0) {
              if (result[i]["0-30"]["amount"] !== "0.00") {
                const data = {
                  id: id,
                  name: result[i]["vendor_name"],
                  age: "0-30",
                  value: result[i]["0-30"]["amount"],
                };
                invoiceArray.push(data);
                id++;
              }
            }
            if (index === 1) {
              if (result[i]["31-60"]["amount"] !== "0.00") {
                const data = {
                  id: id,
                  name: result[i]["vendor_name"],
                  age: "31-60",
                  value: result[i]["31-60"]["amount"],
                };
                invoiceArray.push(data);
                id++;
              }
            }
            if (index === 2) {
              if (result[i]["61-90"]["amount"] !== "0.00") {
                const data = {
                  id: id,
                  name: result[i]["vendor_name"],
                  age: "61-90",
                  value: result[i]["61-90"]["amount"],
                };
                invoiceArray.push(data);
                id++;
              }
            }
            if (index === 3) {
              if (result[i]["91+"]["amount"] !== "0.00") {
                const data = {
                  id: id,
                  name: result[i]["vendor_name"],
                  age: "91+",
                  value: result[i]["91+"]["amount"],
                };
                invoiceArray.push(data);
                id++;
              }
            }
          }
        }
        console.log(invoiceArray);
        setpayExtraInfo(invoiceArray);
      })
      .catch((err) => {
        console.log(err);
        // setIsError(true);
      });

    axios
      .get("get-freshbooksinfo/salesandpurchases", {
        params: {
          organization_id: organization_id,
          year: details.year,
          month: details.month,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        console.log(data);
        setTotalSale(
          parseInt(data["result"]["profitloss"]["income"][0]["total"]["amount"])
        );
        let totalExpenses = 0;
        const expenses = data["result"]["profitloss"]["expenses"];
        for (let i = 0; i < expenses.length; i++) {
          if (expenses[i]["description"] === "Cost of Goods Sold") {
            totalExpenses += 0;
          } else {
            totalExpenses += parseInt(expenses[i]["total"]["amount"]);
          }
        }
        setTotalPurchases(totalExpenses);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get("get-freshbooksinfo/totalsalesandpurchases", {
        params: {
          organization_id: organization_id,
          year:
            parseInt(details.month) < 4
              ? parseInt(details.year) - 1
              : details.year,
          month: details.month,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        console.log(data);
        setTotalSaleFy(
          parseInt(data["result"]["profitloss"]["income"][0]["total"]["amount"])
        );
        let totalExpenses = 0;
        const expenses = data["result"]["profitloss"]["expenses"];
        for (let i = 0; i < expenses.length; i++) {
          if (expenses[i]["description"] === "Cost of Goods Sold") {
            totalExpenses += 0;
          } else {
            totalExpenses += parseInt(expenses[i]["total"]["amount"]);
          }
        }
        setTotalPurchasesFy(totalExpenses);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getOrganization();
  }, []);

  useEffect(() => {
    if (details.month && details.year && details.organization) {
      setIsError(false);
      setPayable90(0);
      setPayable0_30(0);
      setPayable31_60(0);
      setPayable61_90(0);
      setRecievable0_30(0);
      setRecievable31_60(0);
      setRecievable61_90(0);
      setRecievable90(0);
      setRecievableTotal(0);
      setTotalPurchases(0);
      setTotalPurchasesFy(0);
      setTotalSale(0);
      setTotalSaleFy(0);
      setInvExtraInfo([]);
      setpayExtraInfo([]);
      setPayableTotal(0);
      setRecievableTotal(0);
      getData();
    }
    // eslint-disable-next-line
  }, [details]);
  const formValue = (event) => {
    if (event.target.name === "organization") {
      setorganization_id(event.target.value);
    }
    if (event.target.name === "year" && details.month) {
      if (
        !parseInt(details.month) < 4 &&
        parseInt(details.month) > monthArray.slice(-1)[0] + 1
      ) {
        setDetails((oldValue) => {
          return {
            ...oldValue,
            [event.target.name]: event.target.value,
            month: "",
          };
        });
      } else {
        setDetails((oldValue) => {
          return { ...oldValue, [event.target.name]: event.target.value };
        });
      }
    } else {
      setDetails((oldValue) => {
        return { ...oldValue, [event.target.name]: event.target.value };
      });
    }
  };
  const handleCheck = (event) => {
    // console.log(event.target.checked);
    setIsShare(event.target.checked);
  };
  console.log(details);
  return (
    <div className="share__dashboard">
      <div className="share__dashboard__sidenav">
        <div className="share__dashboard__sidenav__img">
          <img src={sync} alt="" />
        </div>
        <ul>
          <li className="active">
            <Link to="/dashboard">
              {/* eslint-disable-next-line */}
              <a href="#">
                <span className="icon">
                  <GridViewRoundedIcon />
                </span>
                Dashboard
              </a>
            </Link>
          </li>
          <li>
            <Link to="/my-organization">
              {/* eslint-disable-next-line */}
              <a href="#">
                <span className="icon">
                  <HomeRoundedIcon />
                </span>
                My Organization
              </a>
            </Link>
          </li>
          <li>
            <Link to="/settings">
              {/* eslint-disable-next-line */}
              <a href="#">
                <span className="icon">
                  <LoginIcon />
                </span>
                Login Settings
              </a>
            </Link>
          </li>
          <li>
            <Link to="/erp-systems">
              {/* eslint-disable-next-line */}
              <a href="#">
                <span className="icon">
                  <SettingsIcon />
                </span>
                Erp Settings
              </a>
            </Link>
          </li>
          <li>
            <a href="/logout" onClick={(e) => logout(e)}>
              <span className="icon">
                <LogoutIcon />
              </span>
              Logout
            </a>
          </li>
        </ul>
      </div>
      <div className="share__dashboard__topnav">
        <div className="share__dashboard__topnav__header">
          <h1>Dashboard</h1>
        </div>
        <div className="share__dashboard__topnav__item">
          <a href="/">
            <CircleNotificationsRoundedIcon className="notification_icon" />
          </a>
          <Avatar className="image__avatar" />
        </div>
      </div>
      <div className="share__dashboard__main__maindivision">
        <div className="share__dashboard__main__subdivision_fresh">
          <div className="share__dashboard__main__head">
            <div className="share__dashboard__main__head__div">
              <Grid container spacing={1}>
                <Grid item>
                  <FormLabel className="form-label">Year</FormLabel>
                  <FormControl fullWidth>
                    <Select
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={details.year}
                      name="year"
                      size="small"
                      onChange={formValue}
                      className="form__textfield"
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                    >
                      <MenuItem value={date.getFullYear()}>
                        {date.getFullYear()}
                      </MenuItem>
                      <MenuItem value={date.getFullYear() - 1}>
                        {date.getFullYear() - 1}
                      </MenuItem>
                      <MenuItem value={date.getFullYear() - 2}>
                        {date.getFullYear() - 2}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormLabel className="form-label">Month</FormLabel>
                  <FormControl fullWidth>
                    {!details.year && (
                      <Select
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        size="small"
                        name="month"
                        sx={{
                          "& fieldset": { border: "none" },
                        }}
                        className="form__textfield"
                      >
                        <MenuItem disabled>Select Year</MenuItem>
                      </Select>
                    )}
                    {details.year === date.getFullYear() && (
                      <Select
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={details.month}
                        size="small"
                        name="month"
                        onChange={formValue}
                        className="form__textfield"
                        sx={{
                          "& fieldset": { border: "none" },
                        }}
                      >
                        {monthArray.map((index) => {
                          return (
                            <MenuItem
                              key={index}
                              value={index < 9 ? `${0}${index + 1}` : index + 1}
                            >
                              {monthNames[index]}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    )}
                    {details.year && details.year < date.getFullYear() && (
                      <Select
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={details.month}
                        size="small"
                        name="month"
                        onChange={formValue}
                        className="form__textfield"
                        sx={{
                          "& fieldset": { border: "none" },
                        }}
                      >
                        <MenuItem value="01">January</MenuItem>
                        <MenuItem value="02">February</MenuItem>
                        <MenuItem value="03">March</MenuItem>
                        <MenuItem value="04">April</MenuItem>
                        <MenuItem value="05">May</MenuItem>
                        <MenuItem value="06">June</MenuItem>
                        <MenuItem value="07">July</MenuItem>
                        <MenuItem value="08">August</MenuItem>
                        <MenuItem value="09">September</MenuItem>
                        <MenuItem value="10">October</MenuItem>
                        <MenuItem value="11">November</MenuItem>
                        <MenuItem value="12">December</MenuItem>
                      </Select>
                    )}
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormLabel className="form-label">Organization</FormLabel>
                  <FormControl fullWidth>
                    <Select
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={details.organization}
                      name="organization"
                      size="small"
                      onChange={formValue}
                      className="form__textfield"
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                    >
                      {organization.map((org) => {
                        return (
                          <MenuItem
                            key={org.business.account_id}
                            value={org.business.account_id}
                          >
                            {org.business.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                {loading && (
                  <>
                    <div className="progress">
                      <CircularProgress size={20} /> Fetching Organization/data
                    </div>
                  </>
                )}
              </Grid>
            </div>
            <div>
              <p>(Value in INR)</p>
            </div>
          </div>
          {isError && (
            <div className="alert">
              <Alert variant="filled" severity="error">
                {isErrorDetails}
              </Alert>
            </div>
          )}
          <div className="share__table">
            <table>
              <tr>
                <th>Particulars</th>
                <th>Total</th>
                <th>0-31 Days</th>
                <th>31-60 Days</th>
                <th>61-90 Days</th>
                <th>Greater than 90</th>
              </tr>
              <tr>
                <td className="share__table__data">
                  <InventoryIcon className="inventory__color" />
                  <span className="table__data__text__special">Inventory</span>
                </td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
              </tr>
              <tr>
                <td className="share__table__data">
                  <PaymentsIcon className="payable__color" />
                  <span className="share__table__data__text">Payable</span>
                  <AddCircleOutlineIcon
                    onClick={() => {
                      setDisplayinventory(!displayinventory);
                    }}
                    className="payable__add__icon"
                  />
                </td>
                <td>{parseInt(payableTotal).toLocaleString("hi")}</td>
                <td>{parseInt(payable0_30).toLocaleString("hi")}</td>
                <td>{parseInt(payable31_60).toLocaleString("hi")}</td>
                <td>{parseInt(payable61_90).toLocaleString("hi")}</td>
                <td>{parseInt(payable90).toLocaleString("hi")}</td>
              </tr>
              {displayinventory && (
                <>
                  <tr>
                    <th>Name</th>
                    <th>Age</th>
                    <th>Value</th>
                    <th></th>
                    <th></th>
                  </tr>
                  {payExtraInfo?.map((data) => {
                    return (
                      <tr key={data.id}>
                        <td>{data.name}</td>
                        <td>{data.age ? data.age : "Not Available"}</td>
                        <td>{parseInt(data.value).toLocaleString("hi")}</td>
                        <td></td>
                        <td></td>
                      </tr>
                    );
                  })}
                </>
              )}
              <tr>
                <td className="share__table__data">
                  <InboxIcon className="recievable__color" />
                  <span className="share__table__data__text">Recievable</span>
                  <AddCircleOutlineIcon
                    onClick={() => setDisplayRecievable(!displayRecievable)}
                  />
                </td>
                <td>{parseInt(recievableTotal)?.toLocaleString("hi")}</td>
                <td>{parseInt(recievable0_30)?.toLocaleString("hi")}</td>
                <td>{parseInt(recievable31_60)?.toLocaleString("hi")}</td>
                <td>{parseInt(recievable61_90)?.toLocaleString("hi")}</td>
                <td>{parseInt(recievable90)?.toLocaleString("hi")}</td>
              </tr>
              {displayRecievable && (
                <>
                  <tr>
                    <th>Name</th>
                    <th>Age</th>
                    <th>Value</th>
                    <th></th>
                    <th></th>
                  </tr>
                  {invExtraInfo?.map((data) => {
                    return (
                      <tr key={data.id}>
                        <td>{data.name}</td>
                        <td>{data.age ? data.age : "Not Available"}</td>
                        <td>{parseInt(data.value).toLocaleString("hi")}</td>
                        <td></td>
                        <td></td>
                      </tr>
                    );
                  })}
                </>
              )}
            </table>
            {/* <CircularProgress /> */}
            <div className="share__table__2">
              <table>
                <tr>
                  <th></th>
                  <th>Current Month</th>
                  <th>Current FY</th>
                </tr>
                <tr>
                  <td className="share__table__data">
                    <BarChartIcon className="sales__color" />
                    <span className="table__data__text__special">Sales</span>
                  </td>
                  <td>{parseInt(totalSale)?.toLocaleString("hi")}</td>
                  <td>{parseInt(totalSaleFy)?.toLocaleString("hi")}</td>
                </tr>
                <tr>
                  <td className="share__table__data">
                    <ApartmentIcon className="purchase__color" />
                    <span className="share__table__data__text">Purchases</span>
                  </td>
                  <td>{parseInt(totalPurchases)?.toLocaleString("hi")}</td>
                  <td>{parseInt(totalPurchasesFy)?.toLocaleString("hi")}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div className="declaration__div__info_fresh">
          <p>
            <FlagIcon /> Declaration
          </p>
          <div className="declaration__div__info__form">
            <Checkbox
              onClick={handleCheck}
              className="declaration__div__form__checkbox"
            />
            <p>
              We Certify that the Data represented in the report is correct and
              is in accordance with the entries in our records.
            </p>
          </div>
          <div className="declaration__div__form__button">
            <button disabled={!isShare} onClick={getInfo}>
              Share
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FreshbookDashboard;
