import React, { useEffect, useState } from "react";
import sync from ".././static/Capture.png";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import CircleNotificationsRoundedIcon from "@mui/icons-material/CircleNotificationsRounded";
import {
  Grid,
  Avatar,
  TextField,
  Button,
  FormLabel,
  FormControl,
} from "@mui/material";
import "./css/OrganizationData.css";
import { useGlobalContext } from "../context";
import { useNavigate, useParams, Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "./css/OrganizationData.css";
import "./css/StockStatement.css";
import axios from "../axiosBase";
import { clearData, getRefreshToken } from "../handleToken";
import "../axiosInterceptors";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function OrganizationData() {
  const { authenticate, setAuthenticate, setisAdmin, isAdmin } =
    useGlobalContext();
  const history = useNavigate();
  if (authenticate === false) {
    history("/admin/login");
  }
  if (authenticate === true && isAdmin === false) {
    clearData();
    history("/login");
  }
  let { pan } = useParams();
  console.log(pan);
  const [flagNo, setFlagNo] = useState(0);
  const [FlagObject, setFlagObject] = useState([]);
  const [log, setLog] = useState([]);
  // if (authenticate !== true) {
  //   history("/login");
  // }
  const [details, setDetails] = useState({
    email: "",
    organization: "",
    pan_no: "",
    address: "",
    account_no: "",
    contact_no: "",
    branch: "",
  });
  const getUser = () => {
    axios
      .get("user-data", {
        params: {
          pan_no: pan,
        },
      })
      .then((response) => response.data)
      .then((result) => {
        console.log(result);
        if (result.message === "success") {
          console.log(result);
          setDetails((prevData) => {
            return {
              ...prevData,
              organization: result.data.organization_name,
              email: result.data.email,
              pan_no: result.data.pan_no,
              contact_no: result.data.contact_no,
              branch: result.data.branch,
              address: result.data.address,
              account_no: result.data.account_no,
            };
          });
        }
      })
      .catch((err) => console.log(err));
  };
  const getUserLog = () => {
    axios
      .get("erp-log", {
        params: {
          pan_no: pan,
        },
      })
      .then((response) => response.data)
      .then((result) => {
        console.log(result, "from log");
        if (result.message === "success") {
          setLog(result.data);
        }
      })
      .catch((err) => console.log(err));
  };
  const getUserFlag = () => {
    axios
      .get("flag", {
        params: {
          pan_no: pan,
        },
      })
      .then((response) => response.data)
      .then((result) => console.log(result))
      .catch((err) => console.log(err));
  };
  const getFlag = () => {
    axios
      .get("flag", {
        params: {
          pan_no: pan,
        },
      })
      .then((response) => response.data)
      .then((result) => {
        console.log(result);
        setFlagNo(result.flag_no);
        setFlagObject(result.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getUser();
    getUserLog();
    getUserFlag();
    getFlag();
    // eslint-disable-next-line
  }, []);

  const [newFlag, setNewFlag] = useState("");
  // const [flags, setFlags] = useState(FlagObject);
  const setFlagHandler = (event) => {
    setNewFlag(event.target.value);
  };

  const [open, setOpen] = React.useState(false);
  const [openAdd, setOpenAdd] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleNewFlagsOpen = () => setOpenAdd(true);
  const handleNewFlagsclose = () => setOpenAdd(false);
  const logout = (e) => {
    e.preventDefault();
    const token = getRefreshToken();
    axios.post("api/token/blacklist/", { refresh: token }).then((response) => {
      clearData();
      setAuthenticate(false);
      setisAdmin(false);
      history("/admin/login");
    });
  };
  const submitHandler = (event) => {
    event.preventDefault();
    axios
      .post("flag", {
        pan_no: pan,
        remark: newFlag,
      })
      .then((response) => response.data)
      .then((result) => {
        console.log(result);
        getFlag();
      })
      .catch((err) => console.log(err));
    setNewFlag("");
    handleNewFlagsclose();
  };
  return (
    <div className="share__dashboard">
      <div className="share__dashboard__sidenav">
        <div className="share__dashboard__sidenav__img">
          <img src={sync} alt="" />
        </div>
        <ul>
          <li className="active">
            <Link to="/admin/dashboard">
              <a href="admin/dashboard">
                <span className="icon">
                  <GridViewRoundedIcon />
                </span>
                Dashboard
              </a>
            </Link>
          </li>
          <li>
            <Link to="/admin/settings">
              <a href="admin/settings">
                <span className="icon">
                  <SettingsIcon />
                </span>
                Settings
              </a>
            </Link>
          </li>
          <li>
            <a href="/" onClick={(e) => logout(e)}>
              <span className="icon">
                <LogoutIcon />
              </span>
              Logout
            </a>
          </li>
        </ul>
      </div>
      <div className="share__dashboard__topnav">
        <div className="share__dashboard__topnav__header">
          <h1>{details.organization}</h1>
        </div>
        <div className="share__dashboard__topnav__item">
          <a href="/">
            <CircleNotificationsRoundedIcon className="notification_icon" />
          </a>
          <Avatar className="image__avatar" />
        </div>
      </div>
      <div className="share__dashboard__main__maindivision">
        <div className="share__dashboard__main__subdivision_org">
          <div className="share__table__organization">
            <div>
              {/*<form action="">*/}
              <Grid container item spacing={2}>
                <Grid item xs={6}>
                  <FormLabel className="form-label">
                    Organization Name
                  </FormLabel>
                  <TextField
                    fullWidth
                    id="oulined-basic"
                    variant="outlined"
                    disabled
                    name="organization"
                    placeholder="sadhbhsa"
                    value={details.organization}
                    // onChange={formValue}
                    className="form__textfield"
                    sx={{
                      "& fieldset": { border: "none" },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormLabel className="form-label">Email ID</FormLabel>
                  <TextField
                    fullWidth
                    id="oulined-basic"
                    type="email"
                    variant="outlined"
                    name="email"
                    disabled
                    placeholder="Emaidshda@hhsd"
                    value={details.email}
                    // onChange={formValue}
                    className="form__textfield"
                    sx={{
                      "& fieldset": { border: "none" },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormLabel className="form-label">Pan Number</FormLabel>
                  <TextField
                    fullWidth
                    type="text"
                    id="oulined-basic"
                    variant="outlined"
                    placeholder="sajdnsjaajk"
                    name="pan_no"
                    disabled
                    value={details.pan_no}
                    // onChange={formValue}
                    className="form__textfield"
                    sx={{
                      "& fieldset": { border: "none" },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormLabel className="form-label">Account Number</FormLabel>
                  <TextField
                    fullWidth
                    id="oulined-basic"
                    variant="outlined"
                    // label="Account No"
                    name="account_no"
                    disabled
                    placeholder="dhbhdshvsd"
                    value={details.account_no}
                    // onChange={formValue}
                    sx={{
                      "& fieldset": { border: "none" },
                    }}
                    className="form__textfield"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormLabel className="form-label">Contact Number</FormLabel>
                  <TextField
                    fullWidth
                    id="oulined-basic"
                    variant="outlined"
                    placeholder="327328"
                    type="number"
                    disabled
                    name="contact_no"
                    value={details.contact_no}
                    sx={{
                      "& fieldset": { border: "none" },
                    }}
                    // onChange={formValue}
                    className="form__textfield"
                  />
                </Grid>
                <Grid xs={6} item>
                  <FormLabel className="form-label">Branch</FormLabel>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={details.branch}
                      name="branch"
                      disabled
                      placeholder="ABC"
                      className="form__textfield"
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormLabel className="form-label">Address</FormLabel>
                  <TextField
                    fullWidth
                    id="oulined-basic"
                    variant="outlined"
                    name="address"
                    value={details.address}
                    disabled
                    placeholder="XYZ"
                    // onChange={formValue}
                    className="form__textfield"
                    sx={{
                      "& fieldset": { border: "none" },
                    }}
                  />
                </Grid>
              </Grid>
              {/*</form>*/}
            </div>
            <div className="share__table100">
              <table>
                <tr>
                  <th>Erp Name</th>
                  <th>Active Date</th>
                  <th>End Date</th>
                </tr>
                {log.map((data) => {
                  return (
                    <tr>
                      <td>{data.erp_name}</td>
                      <td>{new Date(data.active_date).toDateString()}</td>
                      <td>
                        {data.end_date === null
                          ? "Current"
                          : new Date(data.end_date).toTimeString()}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
        <div>
          <div className="declaration_div">
            <div className="declaration__div__form">
              <p>Flags</p>
            </div>
            <div className="declaration__div__form1">
              <p>{flagNo}</p>
            </div>
            <div className="form__buttons">
              <div
                className="declaration__div__form__button2"
                style={{ float: "left" }}
              >
                <Button onClick={handleOpen} className="button__view">
                  View
                </Button>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      style={{ borderBottom: "1px solid gray" }}
                    >
                      Flags
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      <table className="share__table1">
                        {FlagObject.map((e) => {
                          return (
                            <tr
                              style={{
                                borderBottom: "1px solid #d3d3d3",
                                textAlign: "left",
                                display: "block",
                              }}
                            >
                              {/* flag_remark: "Dummy" flag_status: false
                              month_year: "2022-09-02" */}
                              <td style={{ display: "inline-block" }}>
                                {e.flag_remark},
                                {e.flag_status ? "false" : "true"},
                                {e.month_year}
                              </td>
                            </tr>
                          );
                        })}
                      </table>
                    </Typography>
                  </Box>
                </Modal>
              </div>
              <div
                className="declaration__div__form__button2"
                style={{ float: "right" }}
              >
                <Button onClick={handleNewFlagsOpen} className="button__view">
                  Add Flags
                </Button>
                <Modal
                  open={openAdd}
                  onClose={handleNewFlagsclose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      style={{ borderBottom: "1px solid gray" }}
                    >
                      Add Flag
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      <form action="" onSubmit={submitHandler}>
                        <Grid container item spacing={2}>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              id="outlined-multiline-flexible"
                              variant="outlined"
                              name="currpass"
                              placeholder="Add a New Flag"
                              multiline
                              maxRows={4}
                              value={newFlag}
                              onChange={setFlagHandler}
                              className="form__textfixeld"
                            />
                          </Grid>
                          <Grid item xs={6}></Grid>
                          <Grid item sm={3}>
                            <Button
                              // onClick={(e) => handleForm(e)}
                              type="submit"
                              className="button"
                              fullWidth
                              variant="contained"
                            >
                              Add
                            </Button>
                          </Grid>
                          <Grid item sm={2}>
                            <Button
                              // className="button"
                              fullWidth
                              variant="outlined"
                              onClick={handleNewFlagsclose}
                            >
                              Cancel
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    </Typography>
                  </Box>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrganizationData;
