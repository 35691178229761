import axios from "./axiosBase";
import {
  getAccessToken,
  getRefreshToken,
  setAccessToken,
} from "./handleToken";
// import mem from "mem";

axios.interceptors.request.use(
  function (config) {
    if (getAccessToken()) {
      if (getAccessToken()) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${getAccessToken()}`,
        };
      }
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// const getRequest = () => {

// }

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const originalConfig = error.config;
    // const { setAuthenticate } = useGlobalContext();
    // const user = getUser();
    // console.log(user);
    console.log(getRefreshToken());
    console.log(originalConfig.url);
    if (
      error.response.status === 401 &&
      originalConfig.url === "api/token/refresh/"
    ) {
      window.localStorage.clear();
      return (window.location.href = "/");
    }
    if (getRefreshToken()) {
      if (error.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        console.log("already herre");
        if (getRefreshToken()) {
          axios
            .post("api/token/refresh/", {
              refresh: getRefreshToken(),
            })
            .then((responseData) => {
              if (responseData.status !== 401) {
                if (responseData.data.access) {
                  console.log(responseData.data.access);
                  const newDate = new Date();
                  setAccessToken(
                    responseData.data.access,
                    newDate.toISOString()
                  );
                  originalConfig.headers = {
                    ...originalConfig.headers,
                    Authorization: `Bearer ${responseData.data.access}`,
                  };
                }
                axios.request(originalConfig);
                console.log("changed");
                return window.location.reload();
              }
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        }
      }
    }
  }
);
