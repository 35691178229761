import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import "./css/SyncRegister.css";
import { useGlobalContext } from "./context";
import Capture from "./static/Capture.png";
import {
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Select,
  MenuItem,
  FormLabel,
  FormControl,
  CircularProgress,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import Splash from "./Splash";
import axios from "./axiosBase";
import "./axiosInterceptors";
import "./css/login.css";

export default function RegisterView() {
  const { authenticate, setAuthenticate, setUsername } = useGlobalContext();
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [appLoading, setappLoading] = useState(false);
  const [isErrorDetails, setisErrorDetails] = useState();
  const [branches, setBranches] = useState([]);
  const [isShare, setisShare] = useState(false);
  const [loading, setLoading] = useState(true);
  const history = useNavigate();
  if (authenticate === true) {
    history("/dashboard");
  }
  useEffect(() => {
    if (authenticate === false) {
      setLoading(false);
    }
  }, [authenticate]);
  const [details, setDetails] = useState({
    email: "",
    organization: "",
    pan_no: "",
    address: "",
    account_no: "",
    contact_no: "",
    password1: "",
    password2: "",
    branch: "",
  });

  const formValue = (e) => {
    console.log(e.target.value, e.target.name);
    setDetails((prevData) => {
      return { ...prevData, [e.target.name]: [e.target.value] };
    });
  };
  const pattern = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
  );
  const handleForm = (event) => {
    event.preventDefault();
    if (pattern.test(details.password1[0]) === false) {
      setIsError(true);
      setisErrorDetails(
        "Password must contain at least an uppercase and a special character"
      );
    } else if (details.password1[0].length < 8) {
      setIsError(true);
      setisErrorDetails("Password must be atleast 8 characters");
    } else if (details.password1[0] !== details.password2[0]) {
      setIsError(true);
      setisErrorDetails("Password must match");
    } else {
      setIsError(false);
      setappLoading(true);
      axios
        .post("register-view", {
          email: details.email[0],
          organization_no: details.organization[0],
          pan_no: details.pan_no[0],
          address: details.address[0],
          contact_no: details.contact_no[0],
          password: details.password1[0],
          account_no: details.account_no[0],
          branch: details.branch[0],
        })
        .then((response) => response.data)
        .then((data) => {
          setappLoading(false);
          console.log(data);
          if (data.message === "success") {
            // setAuthenticate(true);
            // setAccessRefreshToken(data.data.access, data.data.refresh);
            setUsername(details.email[0]);
            // setAccessRefreshToken()
            setIsError(false);
            setIsSuccess(true);
            // history("/login");
          } else {
            setIsSuccess(false);
            setIsError(true);
            setisErrorDetails(data.data);
          }
        })
        .catch((err) => {
          setappLoading(false);
          console.log(err);
          setIsSuccess(false);
          setIsError(true);
        });
    }
  };
  const getBranches = () => {
    axios
      .get("branches")
      .then((response) => response.data)
      .then((data) => {
        console.log(data);
        setBranches(data.message);
      })
      .catch((err) => {
        if (err.message === "Network Error") {
          setLoading(false);
          setIsError(true);
          setisErrorDetails("Internet Connection error");
        } else {
          setLoading(false);
          setIsError(true);
          setisErrorDetails("Error in fetching data");
        }
      });
  };

  useEffect(() => {
    getBranches();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsError(false);
    }, 20000);
  }, [isError]);

  if (loading === true) {
    return <Splash />;
  }
  return (
    <div className="SyncBankerRegister_SyncBankerRegister">
      {/* <img className="Vector" src={ImgAsset.SyncBankerRegister_Vector} /> */}
      <div className="AddDocumentPopup">
        <div className="cardheader"></div>
        <div className="Frame420"></div>
        {isError && (
          <div className="alert-register">
            <Alert variant="filled" severity="error">
              {isErrorDetails ? (
                isErrorDetails
              ) : (
                <span>
                  An error occured, <br /> please ensure all fields are filled
                </span>
              )}
            </Alert>
          </div>
        )}
        {isSuccess && (
          <div className="alert-register">
            <Alert variant="filled" severity="success">
              Verification Link sent to email
            </Alert>
          </div>
        )}
        <span className="Register">Registration </span>
        <form action="" className="form__register">
          <Grid container item spacing={2}>
            <Grid item xs={6}>
              <FormLabel className="form-label">Organization Name</FormLabel>
              <TextField
                fullWidth
                id="oulined-basic"
                size="small"
                variant="outlined"
                name="organization"
                sx={{
                  "& fieldset": { border: "none" },
                }}
                placeholder="Enter your Organization name"
                value={details.organization}
                onChange={formValue}
                className="form__textfield"
              />
            </Grid>
            <Grid item xs={6}>
              <FormLabel className="form-label">Email ID</FormLabel>
              <TextField
                fullWidth
                id="oulined-basic"
                size="small"
                type="email"
                variant="outlined"
                sx={{
                  "& fieldset": { border: "none" },
                }}
                name="email"
                placeholder="Enter your Email-ID/Phone number"
                value={details.email}
                onChange={formValue}
                className="form__textfield"
              />
            </Grid>
            <Grid item xs={6}>
              <FormLabel className="form-label">Password</FormLabel>
              <TextField
                fullWidth
                id="oulined-basic"
                type="password"
                variant="outlined"
                sx={{
                  "& fieldset": { border: "none" },
                }}
                size="small"
                name="password1"
                placeholder="Must contain 8 characters"
                value={details.password1}
                onChange={formValue}
                className="form__textfield"
              />
            </Grid>
            <Grid item xs={6}>
              <FormLabel className="form-label">Re-enter Password</FormLabel>
              <TextField
                fullWidth
                id="oulined-basic"
                type="password"
                size="small"
                variant="outlined"
                sx={{
                  "& fieldset": { border: "none" },
                }}
                placeholder="Atleast 8 Characters"
                name="password2"
                value={details.password2}
                onChange={formValue}
                className="form__textfield"
              />
            </Grid>
            <Grid item xs={6}>
              <FormLabel className="form-label">Contact Number</FormLabel>
              <TextField
                fullWidth
                id="oulined-basic"
                variant="outlined"
                placeholder="Enter your Contact number"
                type="tel"
                size="small"
                sx={{
                  "& fieldset": { border: "none" },
                }}
                inputProps={{ maxLength: 10 }}
                name="contact_no"
                value={details.contact_no}
                onChange={formValue}
                className="form__textfield numbers_text"
              />
            </Grid>
            <Grid item xs={6}>
              <FormLabel className="form-label">Pan Number</FormLabel>
              <TextField
                fullWidth
                type="text"
                id="oulined-basic"
                variant="outlined"
                placeholder="Enter Your Pan number"
                name="pan_no"
                size="small"
                sx={{
                  "& fieldset": { border: "none" },
                }}
                value={details.pan_no}
                onChange={formValue}
                className="form__textfield"
              />
            </Grid>
            <Grid item xs={6}>
              <FormLabel className="form-label">Account Number</FormLabel>
              <TextField
                fullWidth
                id="oulined-basic"
                variant="outlined"
                size="small"
                name="account_no"
                value={details.account_no}
                onChange={formValue}
                sx={{
                  "& fieldset": { border: "none" },
                }}
                className="form__textfield"
              />
            </Grid>
            <Grid xs={6} item>
              <FormLabel className="form-label">Branch</FormLabel>
              <FormControl fullWidth>
                <Select
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={details.branch}
                  sx={{
                    "& fieldset": { border: "none" },
                  }}
                  size="small"
                  name="branch"
                  onChange={formValue}
                  className="form__textfield"
                >
                  {branches?.map((data) => {
                    return (
                      <MenuItem key={data.did} value={data.branch_name}>
                        {data.branch_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormLabel className="form-label">Address</FormLabel>
              <TextField
                fullWidth
                id="oulined-basic"
                variant="outlined"
                name="address"
                size="small"
                value={details.address}
                placeholder="Enter your address"
                onChange={formValue}
                sx={{
                  "& fieldset": { border: "none" },
                }}
                className="form__textfield"
              />
            </Grid>
            <div className="form__text__register_">
              <FormControlLabel
                className="form__text_checkbox"
                control={
                  <Checkbox
                    onClick={(e) => setisShare(e.target.checked)}
                    sx={{ "&.Mui-checked": { color: "#002956" } }}
                  />
                }
                label="I Agree with the"
              />
              <Link href="/Register" className="register__terms__link">
                Terms and Conditions
              </Link>
              <span className="and">and</span>
              <Link href="/Register" className="register__terms__privacy">
                Privacy Policy
              </Link>
            </div>
            <Grid item sm={12}>
              {appLoading === false ? (
                <Button
                  onClick={(e) => handleForm(e)}
                  className="button extra"
                  fullWidth
                  disabled={!isShare}
                  variant="contained"
                >
                  Register
                </Button>
              ) : (
                <Button
                  onClick={(e) => handleForm(e)}
                  className="button extra"
                  fullWidth
                  disabled={!isShare}
                  variant="contained"
                >
                  Register <CircularProgress size={20} color="inherit" />
                </Button>
              )}
            </Grid>
            <Grid item className="register__div login__space">
              <p className="register__p">
                Already have an account?
                <Link to="/login" className="register" color="#5048E5">
                  Login
                </Link>
              </p>
            </Grid>
          </Grid>
        </form>
      </div>
      <div className="AddDocumentPopup_1">
        <img className="Capture1" src={Capture} alt="Capture" />
      </div>
    </div>
  );
}
