import React, { useEffect, useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useGlobalContext } from "../context";
import {
  Grid,
  Avatar,
  TextField,
  Button,
  Select,
  MenuItem,
  Alert,
  FormLabel,
  FormControl,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import CircleNotificationsRoundedIcon from "@mui/icons-material/CircleNotificationsRounded";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import LogoutIcon from "@mui/icons-material/Logout";
import InventoryIcon from "@mui/icons-material/Inventory";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import PaymentsIcon from "@mui/icons-material/Payments";
import BarChartIcon from "@mui/icons-material/BarChart";
import ApartmentIcon from "@mui/icons-material/Apartment";
import InboxIcon from "@mui/icons-material/Inbox";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import sync from ".././static/Capture.png";
import "./css/StockStatement.css";
import axios from "../axiosBase";
import "../axiosInterceptors";
import { clearData, getRefreshToken } from "../handleToken";
import FreshbookStatement from "./FreshbookStockStatement";
import excelImage from "../static/excel.png";

// var jsonDataFromXml = new XMLParser().;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function StockStatement({ monthPassed, yearPassed }) {
  const date = new Date();
  const [isError, setIsError] = useState(false);
  const [totalPurchases, setTotalPurchases] = useState(0);
  const [erpName, setErpName] = useState("");
  const [totalSale, setTotalSale] = useState(0);
  const [totalPurchasesFy, setTotalPurchasesFy] = useState(0);
  const [totalSaleFy, setTotalSaleFy] = useState(0);
  const [payable90, setPayable90] = useState(0);
  const [payable90_180, setPayable90_180] = useState(0);
  const [payable180, setPayable180] = useState(0);
  const [prevPayable90, setPrevPayable90] = useState(0);
  const [prevPayable90_180, setPrevPayable90_180] = useState(0);
  const [prevPayable180, setPrevPayable180] = useState(0);
  const [prevPrevPayable90, setPrevPrevPayable90] = useState(0);
  const [prevPrevPayable90_180, setPrevPrevPayable90_180] = useState(0);
  const [prevPrevPayable180, setPrevPrevPayable180] = useState(0);
  const [recievable90, setRecievable90] = useState(0);
  const [recievable90_180, setRecievable90_180] = useState(0);
  const [recievable180, setRecievable180] = useState(0);
  const [prevRecievable90, setPrevRecievable90] = useState(0);
  const [prevRecievable90_180, setPrevRecievable90_180] = useState(0);
  const [prevRecievable180, setPrevRecievable180] = useState(0);
  const [prevPrevRecievable90, setPrevPrevRecievable90] = useState(0);
  const [prevPrevRecievable90_180, setPrevPrevRecievable90_180] = useState(0);
  const [prevPrevRecievable180, setPrevPrevRecievable180] = useState(0);
  const [inventory90, setInventory90] = useState(0);
  const [inventory90_180, setInventory90_180] = useState(0);
  const [inventory180, setInventory180] = useState(0);
  const [prevInventory90, setPrevInventory90] = useState(0);
  const [prevInventory90_180, setPrevInventory90_180] = useState(0);
  const [prevInventory180, setPrevInventory180] = useState(0);
  const [prevPrevInventory90, setPrevPrevInventory90] = useState(0);
  const [prevPrevInventory90_180, setPrevPrevInventory90_180] = useState(0);
  const [prevPrevInventory180, setPrevPrevInventory180] = useState(0);
  const [payExtraInfo, setpayExtraInfo] = useState([]);
  const [prevPayExtraInfo, setPrevPayExtraInfo] = useState([]);
  const [prevPrevPayExtraInfo, setPrevPrevPayExtraInfo] = useState([]);
  const [invExtraInfo, setInvExtraInfo] = useState([]);
  const [prevInvExtraInfo, setPrevInvExtraInfo] = useState([]);
  const [prevPrevInvExtraInfo, setPrevPrevInvExtraInfo] = useState([]);
  const [displayinventory, setDisplayinventory] = useState(false);
  const [displayRecievable, setDisplayRecievable] = useState(false);
  const { authenticate, setAuthenticate, setisAdmin, isAdmin } =
    useGlobalContext();
  const [organizationName, setOrganizationName] = useState("Organization_Name");
  const [prevTotalPayable, setprevTotalPayable] = useState(0);
  const [prevTotalRecievable, setprevTotalRecievable] = useState(0);
  const [prevTotalSales, setprevTotalSales] = useState(0);
  const [prevTotalPurchase, setprevTotalPurchase] = useState(0);
  const [prevTotalInventory, setprevTotalInventory] = useState(0);
  const [prevPrevTotalPayable, setprevPrevTotalPayable] = useState(0);
  const [prevPrevTotalRecievable, setprevPrevTotalRecievable] = useState(0);
  const [prevPrevTotalSales, setprevPrevTotalSales] = useState(0);
  const [prevPrevTotalPurchase, setprevPrevTotalPurchase] = useState(0);
  const [prevPrevTotalInventory, setprevPrevTotalInventory] = useState(0);
  const [prevRecieve90, setprevRecieve90] = useState(0);
  const [prevPrevRecieve90, setprevPrevRecieve90] = useState(0);

  let { pan } = useParams();
  console.log(pan);
  const [flagNo, setFlagNo] = useState(0);
  const [FlagObject, setFlagObject] = useState([]);
  const [details, setDetails] = useState({
    month: monthPassed ? monthPassed : "",
    year: yearPassed ? yearPassed : "",
  });
  const history = useNavigate();
  if (authenticate === false) {
    history("/admin/login");
  }
  if (authenticate === true && isAdmin === false) {
    clearData();
    history("/login");
  }
  const logout = (e) => {
    e.preventDefault();
    const token = getRefreshToken();
    axios.post("api/token/blacklist/", { refresh: token }).then((response) => {
      clearData();
      setAuthenticate(false);
      setisAdmin(false);
      history("/admin/login");
    });
  };
  const getStockData = async () => {
    axios
      .get("stock-statement", {
        params: { pan_no: pan, year: details.year, month: details.month },
      })
      .then((response) => response.data)
      .then((result) => {
        if (result.message === "error") {
          setIsError(true);
          return result.message;
        }
        console.log(result.data.data.erp_name);
        setIsError(false);
        return result.data.data.erp_name;
      })
      .then((result) => {
        if (result === "error") {
          return console.log("error");
        }
        if (result === "fresh-books") {
          setErpName(result);
        }
        getData();
        getPrevMonth();
        prevAfterPrevMonth();
      });
  };
  const getData = () => {
    axios
      .get("inventory", {
        params: {
          year: details.year,
          month: details.month,
          pan_no: pan,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        setInventory90(data["inventory"][0]["less_than_90"]);
        setInventory90_180(data["inventory"][0]["_90_to_180"]);
        setInventory180(data["inventory"][0]["greater_than_180"]);
      })
      .catch((err) => console.log(err));
    axios
      .get("recievable", {
        params: {
          year: details.year,
          month: details.month,
          pan_no: pan,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        console.log(data);
        setInvExtraInfo(data.recievable);
      })
      .catch((err) => console.log(err));
    axios
      .get("recievable-info", {
        params: {
          year: details.year,
          month: details.month,
          pan_no: pan,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        setRecievable180(data.recievable[0]["greater_than_180"]);
        setRecievable90(data.recievable[0]["less_than_90"]);
        setRecievable90_180(data.recievable[0]["_90_to_180"]);
      })
      .catch((err) => console.log(err));
    axios
      .get("payable", {
        params: {
          year: details.year,
          month: details.month,
          pan_no: pan,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        setPayable180(data.data[0]["greater_than_180"]);
        setPayable90(data.data[0]["less_than_90"]);
        setPayable90_180(data.data[0]["_90_to_180"]);
      })
      .catch((err) => console.log(err));
    axios
      .get("payable-info", {
        params: {
          year: details.year,
          month: details.month,
          pan_no: pan,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        console.log(data);
        setpayExtraInfo(data.data);
      })
      .catch((err) => console.log(err));
    axios
      .get("purchases", {
        params: {
          year: details.year,
          month: details.month,
          pan_no: pan,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        console.log(data);
        setTotalPurchases(data.data[0]["purchase_value"]);
        setTotalPurchasesFy(data.data[0]["purchases_cumulative_fy"]);
      })
      .catch((err) => console.log(err));
    axios
      .get("sales", {
        params: {
          year: details.year,
          month: details.month,
          pan_no: pan,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        console.log(data);
        setTotalSale(data.data[0]["sales_value"]);
        setTotalSaleFy(data.data[0]["sale_cummulative_fy"]);
      })
      .catch((err) => console.log(err));
  };

  const getPrevMonth = () => {
    console.log(details.month, "from prevMonth");
    axios
      .get("inventory", {
        params: {
          year:
            details.month === "01" ? parseInt(details.year) - 1 : details.year,
          month: details.month === "01" ? "12" : parseInt(details.month) - 1,
          pan_no: pan,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        if (data.message !== "error") {
          setprevTotalInventory(
            parseFloat(data["inventory"][0]["less_than_90"]) +
              parseFloat(data["inventory"][0]["_90_to_180"]) +
              parseFloat(data["inventory"][0]["greater_than_180"])
          );
          setPrevInventory90(parseFloat(data["inventory"][0]["less_than_90"]));
          setPrevInventory90_180(
            parseFloat(data["inventory"][0]["_90_to_180"])
          );
          setPrevInventory180(
            parseFloat(data["inventory"][0]["greater_than_180"])
          );
        }
      })
      .catch((err) => console.log(err));
    axios
      .get("recievable-info", {
        params: {
          year:
            details.month === "01" ? parseInt(details.year) - 1 : details.year,
          month: details.month === "01" ? "12" : parseInt(details.month) - 1,
          pan_no: pan,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        if (data.message !== "error") {
          setprevTotalRecievable(
            parseFloat(data.recievable[0]["less_than_90"]) +
              parseFloat(data.recievable[0]["_90_to_180"]) +
              parseFloat(data.recievable[0]["greater_than_180"])
          );
          setPrevRecievable90(parseFloat(data.recievable[0]["less_than_90"]));
          setPrevRecievable90_180(parseFloat(data.recievable[0]["_90_to_180"]));
          setPrevRecievable180(
            parseFloat(data.recievable[0]["greater_than_180"])
          );
        }
      })
      .catch((err) => console.log(err));
    axios
      .get("payable", {
        params: {
          year:
            details.month === "01" ? parseInt(details.year) - 1 : details.year,
          month: details.month === "01" ? "12" : parseInt(details.month) - 1,
          pan_no: pan,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        if (data.message !== "error") {
          setprevTotalPayable(
            parseFloat(data.data[0]["less_than_90"]) +
              parseFloat(data.data[0]["_90_to_180"]) +
              parseFloat(data.data[0]["greater_than_180"])
          );
          setPrevPayable90(parseFloat(data.data[0]["less_than_90"]));
          setPrevPayable90_180(parseFloat(data.data[0]["_90_to_180"]));
          setPrevPayable180(parseFloat(data.data[0]["greater_than_180"]));
        }
      })
      .catch((err) => console.log(err));
    axios
      .get("payable-info", {
        params: {
          year:
            details.month === "01" ? parseInt(details.year) - 1 : details.year,
          month: details.month === "01" ? "12" : parseInt(details.month) - 1,
          pan_no: pan,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        setPrevPayExtraInfo(data.data);
      })
      .catch((err) => console.log(err));
    axios
      .get("recievable", {
        params: {
          year:
            details.month === "01" ? parseInt(details.year) - 1 : details.year,
          month: details.month === "01" ? "12" : parseInt(details.month) - 1,
          pan_no: pan,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        console.log(data);
        setPrevInvExtraInfo(data.recievable);
      })
      .catch((err) => console.log(err));
    axios
      .get("purchases", {
        params: {
          year:
            details.month === "01" ? parseInt(details.year) - 1 : details.year,
          month: details.month === "01" ? "12" : parseInt(details.month) - 1,
          pan_no: pan,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        console.log(data);
        if (data.message !== "error") {
          setprevTotalPurchase(data.data[0]["purchase_value"]);
        }
      })
      .catch((err) => console.log(err));
    axios
      .get("sales", {
        params: {
          year:
            details.month === "01" ? parseInt(details.year) - 1 : details.year,
          month: details.month === "01" ? "12" : parseInt(details.month) - 1,
          pan_no: pan,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        if (data.message !== "error") {
          setprevTotalSales(data.data[0]["sales_value"]);
        }
      })
      .catch((err) => console.log(err));
  };

  const prevAfterPrevMonth = () => {
    if (details.month === "01") {
      axios
        .get("inventory", {
          params: {
            year: parseInt(details.year) - 1,
            month: "11",
            pan_no: pan,
          },
        })
        .then((response) => response.data)
        .then((data) => {
          if (data.message !== "error") {
            setprevPrevTotalInventory(
              parseFloat(data["inventory"][0]["less_than_90"]) +
                parseFloat(data["inventory"][0]["_90_to_180"]) +
                parseFloat(data["inventory"][0]["greater_than_180"])
            );
            setPrevPrevInventory90(
              parseFloat(data["inventory"][0]["less_than_90"])
            );
            setPrevPrevInventory90_180(
              parseFloat(data["inventory"][0]["_90_to_180"])
            );
            setPrevPrevInventory180(
              parseFloat(data["inventory"][0]["greater_than_180"])
            );
          }
        })
        .catch((err) => console.log(err));
      axios
        .get("recievable-info", {
          params: {
            year: parseInt(details.year) - 1,
            month: "11",
            pan_no: pan,
          },
        })
        .then((response) => response.data)
        .then((data) => {
          if (data.message !== "error") {
            setprevPrevTotalRecievable(
              parseFloat(data.recievable[0]["less_than_90"]) +
                parseFloat(data.recievable[0]["_90_to_180"]) +
                parseFloat(data.recievable[0]["greater_than_180"])
            );
          }
          setPrevPrevRecievable90(
            parseFloat(parseFloat(data.recievable[0]["less_than_90"]))
          );
          setPrevPrevRecievable90_180(
            parseFloat(parseFloat(data.recievable[0]["_90_to_180"]))
          );
          setPrevPrevRecievable180(
            parseFloat(parseFloat(data.recievable[0]["greater_than_180"]))
          );
          // setprevPrevRecieve90(parseFloat(data.recievable[0]["less_than_90"]));
        })
        .catch((err) => console.log(err));
      axios
        .get("payable", {
          params: {
            year: parseInt(details.year) - 1,
            month: "11",
            pan_no: pan,
          },
        })
        .then((response) => response.data)
        .then((data) => {
          if (data.message !== "error") {
            setprevPrevTotalPayable(
              parseFloat(data.data[0]["less_than_90"]) +
                parseFloat(data.data[0]["_90_to_180"]) +
                parseFloat(data.data[0]["greater_than_180"])
            );
            setPrevPrevPayable90();
          }
        })
        .catch((err) => console.log(err));
      axios
        .get("purchases", {
          params: {
            year: parseInt(details.year) - 1,
            month: "11",
            pan_no: pan,
          },
        })
        .then((response) => response.data)
        .then((data) => {
          if (data.message !== "error") {
            setprevPrevTotalPurchase(data.data[0]["purchase_value"]);
          }
        })
        .catch((err) => console.log(err));
      axios
        .get("sales", {
          params: {
            year: parseInt(details.year) - 1,
            month: "11",
            pan_no: pan,
          },
        })
        .then((response) => response.data)
        .then((data) => {
          if (data.message !== "error") {
            setprevPrevTotalSales(data.data[0]["sales_value"]);
          }
        })
        .catch((err) => console.log(err));
      axios
        .get("recievable", {
          params: {
            year: parseInt(details.year) - 1,
            month: "11",
            pan_no: pan,
          },
        })
        .then((response) => response.data)
        .then((data) => {
          console.log(data);
          setPrevPrevInvExtraInfo(data.recievable);
        })
        .catch((err) => console.log(err));
      axios
        .get("payable-info", {
          params: {
            year: parseInt(details.year) - 1,
            month: "11",
            pan_no: pan,
          },
        })
        .then((response) => response.data)
        .then((data) => {
          setPrevPrevPayExtraInfo(data.data);
        })
        .catch((err) => console.log(err));
    } else {
      axios
        .get("inventory", {
          params: {
            year:
              details.month === "02"
                ? parseInt(details.year) - 1
                : details.year,
            month: details.month === "02" ? "12" : parseInt(details.month) - 2,
            pan_no: pan,
          },
        })
        .then((response) => response.data)
        .then((data) => {
          if (data.message !== "error") {
            setprevPrevTotalInventory(
              parseFloat(data["inventory"][0]["less_than_90"]) +
                parseFloat(data["inventory"][0]["_90_to_180"]) +
                parseFloat(data["inventory"][0]["greater_than_180"])
            );
            setPrevPrevInventory90(
              parseFloat(data["inventory"][0]["less_than_90"])
            );
            setPrevPrevInventory90_180(
              parseFloat(data["inventory"][0]["_90_to_180"])
            );
            setPrevPrevInventory180(
              parseFloat(data["inventory"][0]["greater_than_180"])
            );
          }
        })
        .catch((err) => console.log(err));
      axios
        .get("recievable-info", {
          params: {
            year:
              details.month === "02"
                ? parseInt(details.year) - 1
                : details.year,
            month: details.month === "02" ? "12" : parseInt(details.month) - 2,
            pan_no: pan,
          },
        })
        .then((response) => response.data)
        .then((data) => {
          if (data.message !== "error") {
            setprevPrevTotalRecievable(
              parseFloat(data.recievable[0]["less_than_90"]) +
                parseFloat(data.recievable[0]["_90_to_180"]) +
                parseFloat(data.recievable[0]["greater_than_180"])
            );
          }
          setPrevPrevRecievable90(
            parseFloat(data.recievable[0]["less_than_90"])
          );
          setPrevPrevRecievable90_180(
            parseFloat(data.recievable[0]["_90_to_180"])
          );
          setPrevPrevRecievable180(
            parseFloat(data.recievable[0]["greater_than_180"])
          );
        })
        .catch((err) => console.log(err));
      axios
        .get("payable", {
          params: {
            year:
              details.month === "02"
                ? parseInt(details.year) - 1
                : details.year,
            month: details.month === "02" ? "12" : parseInt(details.month) - 2,
            pan_no: pan,
          },
        })
        .then((response) => response.data)
        .then((data) => {
          if (data.message !== "error") {
            setprevPrevTotalPayable(
              parseFloat(data.data[0]["less_than_90"]) +
                parseFloat(data.data[0]["_90_to_180"]) +
                parseFloat(data.data[0]["greater_than_180"])
            );
            setPrevPrevPayable90(parseFloat(data.data[0]["less_than_90"]));
            setPrevPrevPayable90_180(parseFloat(data.data[0]["_90_to_180"]));
            setPrevPrevPayable180(parseFloat(data.data[0]["greater_than_180"]));
          }
        })
        .catch((err) => console.log(err));
    }
    axios
      .get("purchases", {
        params: {
          year:
            details.month === "02" ? parseInt(details.year) - 1 : details.year,
          month: details.month === "02" ? "12" : parseInt(details.month) - 2,
          pan_no: pan,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        if (data.message !== "error") {
          setprevPrevTotalPurchase(data.data[0]["purchase_value"]);
        }
      })
      .catch((err) => console.log(err));
    axios
      .get("sales", {
        params: {
          year:
            details.month === "02" ? parseInt(details.year) - 1 : details.year,
          month: details.month === "02" ? "12" : parseInt(details.month) - 2,
          pan_no: pan,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        if (data.message !== "error") {
          setprevPrevTotalSales(data.data[0]["sales_value"]);
        }
      })
      .catch((err) => console.log(err));
    axios
      .get("recievable", {
        params: {
          year:
            details.month === "02" ? parseInt(details.year) - 1 : details.year,
          month: details.month === "02" ? "12" : parseInt(details.month) - 2,
          pan_no: pan,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        console.log(data);
        setPrevPrevInvExtraInfo(data.recievable);
      })
      .catch((err) => console.log(err));
    axios
      .get("payable-info", {
        params: {
          year:
            details.month === "02" ? parseInt(details.year) - 1 : details.year,
          month: details.month === "02" ? "12" : parseInt(details.month) - 2,
          pan_no: pan,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        setPrevPrevPayExtraInfo(data.data);
      })
      .catch((err) => console.log(err));
  };
  const formValue = (event) => {
    setDetails((oldValue) => {
      return { ...oldValue, [event.target.name]: event.target.value };
    });
  };
  useEffect(() => {
    if (details.month && details.year) {
      setPayable180(0);
      setPayable90(0);
      setInventory180(0);
      setPayable90_180(0);
      setInventory90(0);
      setInventory90_180(0);
      setRecievable180(0);
      setRecievable90(0);
      setRecievable90_180(0);
      setTotalPurchases(0);
      setTotalPurchasesFy(0);
      setTotalSale(0);
      setTotalSaleFy(0);
      setInvExtraInfo([]);
      setpayExtraInfo([]);
      setPrevInvExtraInfo([]);
      setPrevPayExtraInfo([]);
      setPrevPrevInvExtraInfo([]);
      setPrevPrevPayExtraInfo([]);
      setPrevInventory180(0);
      setPrevInventory90(0);
      setPrevInventory90_180(0);
      setPrevRecievable180(0);
      setPrevRecievable90(0);
      setPrevRecievable90_180(0);
      setPrevPayable180(0);
      setPrevPayable90(0);
      setPrevPayable90_180(0);
      setPrevPrevInventory180(0);
      setPrevPrevInventory90(0);
      setPrevPrevInventory90_180(0);
      setPrevPrevRecievable180(0);
      setPrevPrevRecievable90(0);
      setPrevPrevRecievable90_180(0);
      setPrevPrevPayable180(0);
      setPrevPrevPayable90(0);
      setPrevPrevPayable90_180(0);
      getStockData();
    }
    // getData();
    // getPrevMonth();
    // prevAfterPrevMonth();
    // eslint-disable-next-line
  }, [details]);
  const [newFlag, setNewFlag] = useState("");
  const getFlag = () => {
    axios
      .get("flag", {
        params: {
          pan_no: pan,
        },
      })
      .then((response) => response.data)
      .then((result) => {
        console.log(result);
        setFlagNo(result.flag_no);
        setFlagObject(result.data);
      })
      .catch((err) => console.log(err));
  };
  const getUser = () => {
    axios
      .get("user-data", {
        params: {
          pan_no: pan,
        },
      })
      .then((response) => response.data)
      .then((result) => {
        console.log(result);
        if (result.message === "success") {
          console.log(result);
          setOrganizationName(result.data.organization_name);
          setDetails((prevData) => {
            return {
              ...prevData,
              organization: result.data.organization_name,
              email: result.data.email,
              pan_no: result.data.pan_no,
              contact_no: result.data.contact_no,
              branch: result.data.branch,
              address: result.data.address,
              account_no: result.data.account_no,
            };
          });
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getUser();
    getFlag();
    // getErpDetails();
    // eslint-disable-next-line
  }, []);

  const setFlagHandler = (event) => {
    setNewFlag(event.target.value);
  };
  const [open, setOpen] = React.useState(false);
  const [openAdd, setOpenAdd] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleNewFlagsOpen = () => setOpenAdd(true);
  const handleNewFlagsclose = () => setOpenAdd(false);
  const submitHandler = (event) => {
    event.preventDefault();
    axios
      .post("flag", {
        pan_no: pan,
        remark: newFlag,
      })
      .then((response) => response.data)
      .then((result) => {
        console.log(result);
        getFlag();
      })
      .catch((err) => console.log(err));
    setNewFlag("");
    handleNewFlagsclose();
  };
  const resolveFlag = (Remark, Status) => {
    axios
      .put("flag", {
        pan_no: pan,
        remark: Remark,
        status: Status,
      })
      .then((response) => response.data)
      .then((result) => {
        console.log(result);
        if (result.message === "success") {
          getFlag();
          handleClose();
        }
      })
      .catch((err) => console.log(err));
  };

  const [check, setCheck] = useState(false);

  const handleToggleStatement = () => {
    setCheck(!check);
  };
  // function fileToBlob(filePath) {
  //   return new Promise((resolve, reject) => {
  //     fs.readFile(filePath, (err, data) => {
  //       if (err) {
  //         return reject(err);
  //       }
  //       const blob = new Blob([data], { type: "application/octet-stream" }); // Set the correct MIME type
  //       resolve(blob);
  //     });
  //   });
  // }
  const exportToExcel = async () => {
    const response = await axios.post("/collect-excel-export-data", {
      organizationName: organizationName,
      branch: details.branch,
      currentRecievable_less_than_90: recievable90,
      currentRecievable_90_180: recievable90_180,
      currentRecievable_180_365: recievable180,
      currentPayable_less_than_90: payable90,
      currentPayable_90_180: payable90_180,
      currentPayable_180_365: payable180,
      currentInventory_less_than_90: inventory90,
      currentInventory_90_180: inventory90_180,
      currentInventory_180_365: inventory180,
      previousRecievable_less_than_90: prevRecievable90,
      previousRecievable_90_180: prevRecievable90_180,
      previousRecievable_180_365: prevRecievable180,
      previousPayable_less_than_90: prevPayable90,
      previousPayable_90_180: prevPayable90_180,
      previousPayable_180_365: prevPayable180,
      previousInventory_less_than_90: prevInventory90,
      previousInventory_90_180: prevInventory90_180,
      previousInventory_180_365: prevInventory180,
      previousPreviousRecievable_less_than_90: prevPrevRecievable90,
      previousPreviousRecievable_90_180: prevPrevRecievable90_180,
      previousPreviousRecievable_180_365: prevPrevRecievable180,
      previousPreviousPayable_less_than_90: prevPrevPayable90,
      previousPreviousPayable_90_180: prevPrevPayable90_180,
      previousPreviousPayable_180_365: prevPrevPayable180,
      previousPreviousInventory_less_than_90: prevPrevInventory90,
      previousPreviousInventory_90_180: prevPrevInventory90_180,
      previousPreviousInventory_180_365: prevPrevInventory180,
      payableArray: payExtraInfo,
      prevPayableArray: prevPayExtraInfo,
      prevPrevPayableArray: prevPrevPayExtraInfo,
      recievableArray: invExtraInfo,
      prevRecievableArray: prevInvExtraInfo,
      prevPrevRecievableArray: prevPrevInvExtraInfo,
    });
    console.log(response);
    if (response?.data?.message === "success") {
      console.log(payExtraInfo);
      console.log("success");
      window.open(
        "https://syncapi-d5gshqbmfyepg7hu.centralindia-01.azurewebsites.net/download-excel-format"
      );
    }
  };

  console.log(erpName);
  if (erpName === "fresh-books") {
    return (
      <FreshbookStatement
        monthPassed={details.month}
        yearPassed={details.year}
      />
    );
  }
  return (
    <div className="stock__dashboard">
      <div className="stock__dashboard__sidenav">
        <div className="stock__dashboard__sidenav__img_main">
          <img src={sync} alt="" />
        </div>
        <ul>
          <li className="active">
            <Link to="/admin/dashboard">
              <a href="admin/dashboard">
                <span className="icon">
                  <GridViewRoundedIcon />
                </span>
                Dashboard
              </a>
            </Link>
          </li>
          <li>
            <Link to="/admin/settings">
              {/*eslint-disable-next-line */}
              <a href="#">
                <span className="icon">
                  <SettingsIcon />
                </span>
                Settings
              </a>
            </Link>
          </li>
          <li>
            {/*eslint-disable-next-line */}
            <a href="/admin/logout" onClick={(e) => logout(e)}>
              <span className="icon">
                <LogoutIcon />
              </span>
              Logout
            </a>
          </li>
        </ul>
      </div>
      <div className="share__dashboard__topnav">
        <div className="share__dashboard__topnav__header">
          <h1>{organizationName}</h1>
        </div>
        <div className="share__dashboard__topnav__item">
          <a href="/">
            <CircleNotificationsRoundedIcon className="notification_icon" />
          </a>
          <Avatar className="image__avatar" />
        </div>
      </div>
      <div className="share__dashboard__main__maindivision1">
        <div className="share__dashboard__main__subdivision01">
          {/* <div className="share__dashboard__main__subdivision1"> */}
          <div className="btn__settings">
            <Button
              className="login__button"
              style={{ color: "#272E4E", fontWeight: "600", fontSize: "12px" }}
              onClick={handleToggleStatement}
            >
              {check ? "Hide Statement" : "Show Statement"}
            </Button>
          </div>
          {check && (
            <div style={{ marginBottom: "100px" }}>
              {isError && (
                <div className="alert">
                  <Alert variant="filled" severity="error">
                    Stock Statement for this month has not been submitted
                  </Alert>
                </div>
              )}
              <div className="share__table" style={{ marginTop: "20px" }}>
                <table>
                  <tr>
                    <th>Particulars</th>
                    <th>Current Month</th>
                    <th>Previous Month</th>
                    <th>2nd Previous Month</th>
                  </tr>
                  <tr>
                    <td className="share__table__data">
                      <InventoryIcon className="inventory__color" />
                      <span className="table__data__text__special">
                        Inventory
                      </span>
                    </td>
                    <td>
                      {(
                        inventory90 +
                        inventory90_180 +
                        inventory180
                      )?.toLocaleString()}
                    </td>
                    <td>{prevTotalInventory?.toLocaleString()}</td>
                    <td>{prevPrevTotalInventory?.toLocaleString()}</td>
                  </tr>
                  <tr>
                    <td className="share__table__data">
                      <PaymentsIcon className="payable__color" />
                      <span className="stock__table__data__text">Payable</span>
                    </td>
                    <td>
                      {(
                        payable180 +
                        payable90 +
                        payable90_180
                      )?.toLocaleString()}
                    </td>
                    <td>{prevTotalPayable?.toLocaleString()}</td>
                    <td>{prevPrevTotalPayable?.toLocaleString()}</td>
                  </tr>
                  <tr>
                    <td className="share__table__data">
                      <InboxIcon className="recievable__color" />
                      <span className="stock__table__data__text">
                        Recievable
                      </span>
                    </td>
                    <td>
                      {(
                        recievable180 +
                        recievable90 +
                        recievable90_180
                      )?.toLocaleString()}
                    </td>
                    <td>{prevTotalRecievable?.toLocaleString()}</td>
                    <td>{prevPrevTotalRecievable?.toLocaleString()}</td>
                  </tr>
                  <tr>
                    <td className="share__table__data">
                      <BarChartIcon className="sales__color" />
                      <span className="table__data__text__special">Sales</span>
                    </td>
                    <td>{totalSale?.toLocaleString()}</td>
                    <td>{prevTotalSales?.toLocaleString()}</td>
                    <td>{prevPrevTotalSales?.toLocaleString()}</td>
                  </tr>
                  <tr>
                    <td className="share__table__data">
                      <ApartmentIcon className="purchase__color" />
                      <span className="stock__table__data__text">
                        Purchases
                      </span>
                    </td>
                    <td>{totalPurchases?.toLocaleString()}</td>
                    <td>{prevTotalPurchase?.toLocaleString()}</td>
                    <td>{prevPrevTotalPurchase?.toLocaleString()}</td>
                  </tr>
                  <tr>
                    <td className="share__table__data">Drawing Power</td>
                    <td>
                      {(
                        (inventory90 +
                          inventory90_180 +
                          inventory180 -
                          (payable180 + payable90 + payable90_180) +
                          recievable90) *
                        0.75
                      ).toFixed()}
                    </td>
                    <td>
                      {(
                        (prevTotalInventory -
                          prevTotalPayable +
                          prevRecieve90) *
                        0.75
                      ).toFixed()}
                    </td>
                    <td>
                      {(
                        (prevPrevTotalInventory -
                          prevPrevTotalPayable +
                          prevPrevRecieve90) *
                        0.75
                      ).toFixed()}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          )}
          <div className="share__dashboard__main__head1">
            <div className="share__dashboard__main__head1__div">
              <Grid container spacing={1}>
                <Grid item>
                  <FormLabel className="form-label">Month</FormLabel>
                  <FormControl fullWidth>
                    <Select
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={details.month}
                      size="small"
                      name="month"
                      onChange={formValue}
                      className="form__textfield"
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                    >
                      <MenuItem value="01">January</MenuItem>
                      <MenuItem value="02">February</MenuItem>
                      <MenuItem value="03">March</MenuItem>
                      <MenuItem value="04">April</MenuItem>
                      <MenuItem value="05">May</MenuItem>
                      <MenuItem value="06">June</MenuItem>
                      <MenuItem value="07">July</MenuItem>
                      <MenuItem value="08">August</MenuItem>
                      <MenuItem value="09">September</MenuItem>
                      <MenuItem value="10">October</MenuItem>
                      <MenuItem value="11">November</MenuItem>
                      <MenuItem value="12">December</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormLabel className="form-label">Year</FormLabel>
                  <FormControl fullWidth>
                    <Select
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={details.year}
                      name="year"
                      size="small"
                      onChange={formValue}
                      className="form__textfield"
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                    >
                      <MenuItem value={date.getFullYear()}>
                        {date.getFullYear()}
                      </MenuItem>
                      <MenuItem value={date.getFullYear() - 1}>
                        {date.getFullYear() - 1}
                      </MenuItem>
                      <MenuItem value={date.getFullYear() - 2}>
                        {date.getFullYear() - 2}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </div>
            <div>
              <p>(Value in INR)</p>
              <div className="export_button" onClick={exportToExcel}>
                <img
                  src={excelImage}
                  alt="excel-image"
                  className="export_button_img"
                />
                <p>Export</p>
              </div>
            </div>
          </div>
          {isError && (
            <div className="alert">
              <Alert variant="filled" severity="error">
                Stock Statement for this month has not been submitted
              </Alert>
            </div>
          )}
          {}
          <div className="share__table">
            <table>
              <tr>
                <th>Particulars</th>
                <th>Total</th>
                <th>0-90 Days</th>
                <th>90-180 Days</th>
                <th>Greater than 180</th>
              </tr>
              <tr>
                <td className="share__table__data">
                  <InventoryIcon className="inventory__color" />
                  <span className="table__data__text__special">Inventory</span>
                </td>
                <td>
                  {(
                    inventory90 +
                    inventory90_180 +
                    inventory180
                  )?.toLocaleString()}
                </td>
                <td>{inventory90?.toLocaleString()}</td>
                <td>{inventory90_180?.toLocaleString()}</td>
                <td>{inventory180?.toLocaleString()}</td>
              </tr>
              <tr>
                <td className="share__table__data">
                  <PaymentsIcon className="payable__color" />
                  <span className="share__table__data__text">Payable</span>
                  <AddCircleOutlineIcon
                    onClick={() => {
                      setDisplayinventory(!displayinventory);
                    }}
                    className="payable__add__icon"
                  />
                </td>
                <td>
                  {(payable180 + payable90 + payable90_180)?.toLocaleString()}
                </td>
                <td>{payable90?.toLocaleString()}</td>
                <td>{payable90_180?.toLocaleString()}</td>
                <td>{payable180?.toLocaleString()}</td>
              </tr>
              {displayinventory && (
                <>
                  <tr>
                    <th>Name</th>
                    <th>Age</th>
                    <th>Value</th>
                    <th></th>
                    <th></th>
                  </tr>
                  {payExtraInfo?.map((data) => {
                    return (
                      <tr>
                        <td>{data.creditor_name}</td>
                        <td>{data.age ? data.age : "Not Available"}</td>
                        <td>{data.value}</td>
                        <td></td>
                        <td></td>
                      </tr>
                    );
                  })}
                </>
              )}
              <tr>
                <td className="share__table__data">
                  <InboxIcon className="recievable__color" />
                  <span className="share__table__data__text">Recievable</span>
                  <AddCircleOutlineIcon
                    onClick={() => setDisplayRecievable(!displayRecievable)}
                  />
                </td>
                <td>
                  {(
                    recievable180 +
                    recievable90 +
                    recievable90_180
                  )?.toLocaleString()}
                </td>
                <td>{recievable90?.toLocaleString()}</td>
                <td>{recievable90_180?.toLocaleString()}</td>
                <td>{recievable180?.toLocaleString()}</td>
              </tr>
              {displayRecievable && (
                <>
                  <tr>
                    <th>Name</th>
                    <th>Age</th>
                    <th>Value</th>
                    <th></th>
                    <th></th>
                  </tr>
                  {invExtraInfo?.map((data) => {
                    return (
                      <tr>
                        <td>{data.debtor_name}</td>
                        <td>{data.age ? data.age : "Not Available"}</td>
                        <td>{data.value}</td>
                        <td></td>
                        <td></td>
                      </tr>
                    );
                  })}
                </>
              )}
            </table>
            {/* <CircularProgress /> */}
            <div className="main__2">
              <div className="share__table__2">
                <table>
                  <tr>
                    <th></th>
                    <th>Current Month</th>
                    <th>Current FY</th>
                  </tr>
                  <tr>
                    <td className="share__table__data">
                      <BarChartIcon className="sales__color" />
                      <span className="table__data__text__special">Sales</span>
                    </td>
                    <td>{totalSale?.toLocaleString()}</td>
                    <td>{totalSaleFy?.toLocaleString()}</td>
                  </tr>
                  <tr>
                    <td className="share__table__data">
                      <ApartmentIcon className="purchase__color" />
                      <span className="share__table__data__text">
                        Purchases
                      </span>
                    </td>
                    <td>{totalPurchases?.toLocaleString()}</td>
                    <td>{totalPurchasesFy?.toLocaleString()}</td>
                  </tr>
                </table>
              </div>
              <div className="drawing">
                <h3>Drawing Power</h3>
                <p>
                  {(
                    (inventory90 +
                      inventory90_180 +
                      inventory180 -
                      (payable180 + payable90 + payable90_180) +
                      recievable90) *
                    0.75
                  ).toFixed()}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="declaration_div">
            <p style={{ textAlign: "center", marginLeft: "44px" }}>
              {organizationName}
            </p>
            <div className="declaration__div__form__button1">
              <Link to={`/admin/organization/${pan}`} className="ha__div">
                <a href="/admin/organization" className="ha">
                  Overview
                </a>
              </Link>
            </div>
            <div className="declaration__div__form">
              <p>Flags</p>
            </div>
            <div className="declaration__div__form1">
              <p>{flagNo}</p>
            </div>
            <div className="form__buttons">
              <div
                className="declaration__div__form__button2"
                style={{ float: "left" }}
              >
                <Button onClick={handleOpen} className="button__view">
                  View
                </Button>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      style={{ borderBottom: "1px solid gray" }}
                    >
                      Flags
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      <table className="share__table1">
                        {FlagObject.map((e) => {
                          return (
                            <tr
                              style={{
                                borderBottom: "1px solid #d3d3d3",
                                textAlign: "left",
                                display: "block",
                              }}
                            >
                              {/* flag_remark: "Dummy" flag_status: false
                              month_year: "2022-09-02" */}
                              <td style={{ display: "inline-block" }}>
                                {e.flag_remark},
                                {e.flag_status ? "false" : "true"},
                                {e.month_year}
                                <Button
                                  className="resolve__button"
                                  onClick={() =>
                                    resolveFlag(e.flag_remark, e.flag_status)
                                  }
                                >
                                  Resolve
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                      </table>
                    </Typography>
                  </Box>
                </Modal>
              </div>
              <div
                className="declaration__div__form__button2"
                style={{ float: "right" }}
              >
                <Button onClick={handleNewFlagsOpen} className="button__view">
                  Add Flags
                </Button>
                <Modal
                  open={openAdd}
                  onClose={handleNewFlagsclose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      style={{ borderBottom: "1px solid gray" }}
                    >
                      Add Flag
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      <form action="" onSubmit={submitHandler}>
                        <Grid container item spacing={2}>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              id="outlined-multiline-flexible"
                              variant="outlined"
                              name="currpass"
                              placeholder="Add a New Flag"
                              multiline
                              maxRows={4}
                              value={newFlag}
                              onChange={setFlagHandler}
                              className="form__textfixeld"
                            />
                          </Grid>
                          <Grid item xs={6}></Grid>
                          <Grid item sm={3}>
                            <Button
                              // onClick={(e) => handleForm(e)}
                              type="submit"
                              className="button"
                              fullWidth
                              variant="contained"
                            >
                              Add
                            </Button>
                          </Grid>
                          <Grid item sm={2}>
                            <Button
                              // className="button"
                              fullWidth
                              variant="outlined"
                              onClick={handleNewFlagsclose}
                            >
                              Cancel
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    </Typography>
                  </Box>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StockStatement;
